import { Button } from "antd";
import { RightAlignedDiv } from "components/divs";
import { useScreen } from "hooks/useScreen";

export const ShowMore: React.FC<{
  onClick?: React.MouseEventHandler<HTMLElement>;
  text: string;
  visible: boolean;
}> = ({ onClick, text, visible }) => {
  const { xxl } = useScreen();
  return (
    <RightAlignedDiv>
      <Button
        style={{ visibility: visible ? "visible" : "hidden" }}
        onClick={onClick}
      >
        {xxl ? text : "View"}
      </Button>
    </RightAlignedDiv>
  );
};
