import { Page } from "components/App/Page";
import { Settings } from "components/Assessment/pages/Settings";
import { Route } from "react-router";
import { AppPaths } from "shared/routes/constants";

import { NavigateWithEnvironment } from "../NavigateWithEnvironment";
import EnvironmentConfigurationPage from "./pages/EnvironmentConfiguration";
import EnvironmentMonitorPage from "./pages/EnvironmentMonitors";
import EnvironmentStatusPage from "./pages/EnvironmentStatus";
import { SettingsPaths } from "./paths";

export const SettingsRoutes = (
  <Route path={AppPaths.Settings} element={<Settings />}>
    <Route
      index
      element={
        <NavigateWithEnvironment to={SettingsPaths.EnvironmentStatus} replace />
      }
    />
    <Route
      path={SettingsPaths.EnvironmentStatus}
      element={
        <Page title={"Status"}>
          <EnvironmentStatusPage />
        </Page>
      }
    />
    <Route
      path={SettingsPaths.EnvironmentConfiguration}
      element={
        <Page title={"Configuration"}>
          <EnvironmentConfigurationPage />
        </Page>
      }
    />
    <Route
      path={SettingsPaths.Monitors}
      element={
        <Page title={"Monitors"}>
          <EnvironmentMonitorPage />
        </Page>
      }
    />
  </Route>
);
