import { IssuesCloseOutlined } from "@ant-design/icons";
import { ZeroStatePage } from "components/Dashboard/components/common/ZeroStatePage";
import { EnvironmentContext } from "components/Environment/contexts/EnvironmentContext";
import { useContext } from "react";

import { FindingsSelect } from "../components/FindingsSelect";
import { MonitorList } from "../components/MonitorList";

export const Findings: React.FC = () => {
  const { hasEnvironments } = useContext(EnvironmentContext);
  return hasEnvironments ? (
    <div style={{ maxWidth: 800 }}>
      <div style={{ marginBottom: "8px" }}>
        <FindingsSelect includeAll includeExport />
      </div>
      <MonitorList actionTypes={[]} filterFindings />
    </div>
  ) : (
    <ZeroStatePage
      title="Manage the posture of your cloud identities"
      description="Posture helps you identify and remediate risks in your cloud environments."
      icon={<IssuesCloseOutlined />}
      docLink="https://docs.p0.dev/what-is-p0/iam-assessment"
    />
  );
};
