import { To } from "react-router";

export const ENV_SEARCH_PARAM = "environment";

const addEnvSearchParam = (
  search: string,
  envId?: string | null | undefined
) => {
  const searchParams = new URLSearchParams(search);
  if (envId) searchParams.set(ENV_SEARCH_PARAM, envId);

  return searchParams.toString();
};

export const addEnvironmentToPath = (
  to: To,
  envId?: string | null | undefined,
  tenantId?: string | undefined
) => {
  const tenantPrefix = tenantId ? `/o/${tenantId}/` : "";

  if (typeof to === "string") {
    const [base, search] = to.split("?");
    const newSearch = addEnvSearchParam(search ?? "", envId);
    return `${tenantPrefix}${base}?${newSearch}`;
  } else {
    const newSearch = addEnvSearchParam(to.search ?? "", envId);
    return {
      pathname: `${tenantPrefix}${to.pathname}`,
      hash: to.hash,
      search: newSearch,
    };
  }
};
