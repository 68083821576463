import { SplunkInstaller } from "./types";

export const splunkInstaller: SplunkInstaller = {
  "audit-log": {
    items: {
      endpoint: {
        labeler: () => "Splunk HEC endpoint",
      },
      token: {
        labeler: () => "HEC token",
      },
      index: {},
    },
  },
};
