import { NotFound } from "components/App/pages/NotFound";
import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router";

import { UserAuthz, UserRole } from "./util";

/** Prevents users from accidentally viewing forbidden pages if they manually navigated to them
 *  @param redirectNoAuthz: Path to navigate to if the user does not have the required authz (default: o/tenant or /)
 *  @param redirectAuthz: Path to navigate to if the user has the required authz (default: no redirect)
 */
export const AuthzGuard: React.FC<
  React.PropsWithChildren<{
    requirement: UserRole[];
    redirectNoAuthz?: string;
    redirectAuthz?: string;
  }>
> = ({ requirement, redirectNoAuthz, redirectAuthz }) => {
  const userAuthz = useContext(UserAuthz);
  // Don't immediately redirect if we are still loading authz
  return userAuthz.size === 0 ||
    !!requirement.find((req) => userAuthz.has(req)) ? (
    redirectAuthz ? (
      <Navigate replace to={redirectAuthz} />
    ) : (
      <Outlet />
    )
  ) : redirectNoAuthz ? (
    <Navigate replace to={redirectNoAuthz} />
  ) : (
    <NotFound />
  );
};
