import {
  DatabaseOutlined,
  KeyOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Skeleton, Typography } from "antd";
import {
  countNodes,
  inventoryLinks,
} from "components/Assessment/components/Inventory";
import { nodeDataFromShow } from "components/Assessment/components/node";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { SelectedEnvironmentContext } from "components/Assessment/contexts/SelectedEnvironmentContext";
import { useNavigateWithEnv } from "components/Assessment/hooks/useNavigateWithEnv";
import {
  NodeDataRecord,
  NodeDataType,
  NodeDataWithGraph,
} from "components/Dashboard/types";
import {
  mockInventoryData,
  mockNodeCount,
} from "components/Dashboard/zeroStateData/inventory-zero-state";
import { VerticalDiv } from "components/divs";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useScreen } from "hooks/useScreen";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Formatter } from "recharts/types/component/DefaultLegendContent";
import { DirectedGraph } from "shared/graph/types";
import { AppPaths } from "shared/routes/constants";
import { AssessmentNodes, TargetNodeType } from "shared/types/assessment/data";
import { colors } from "styles/variables";
import { staticError } from "utils/console";

import { Pane } from "../Pane";
import { FilterStatistic } from "../common/FilterStatistic";

const graphColors = [
  colors.neutral["60"],
  colors.graphColors.darkGreen,
  colors.themeColors.darkBlue,
  colors.graphColors.purple,
  colors.graphColors.pink,
  colors.themeColors.veryLightBlue,
  colors.themeColors.lightYellow,
  colors.themeColors.darkBlue,
  colors.themeColors.veryLightBlue,
];

const identityTypeIcons: Record<TargetNodeType, JSX.Element> = {
  identity: <UserOutlined />,
  grant: <SolutionOutlined />,
  credential: <KeyOutlined rotate={315} />,
  resource: <DatabaseOutlined />,
};

const TargetNodeTypeDisplayOrder: TargetNodeType[] = [
  "identity",
  "grant",
  "credential",
];

const targetNodeTypesDisplayText = (type: TargetNodeType) =>
  capitalize(pluralize(type));

export type CountEntry = {
  name: string;
  value: number;
  fill: string | undefined;
};
const InventoryPieChart: React.FC<
  PropsWithChildren<{
    data: CountEntry[];
  }>
> = ({ children, data }) => {
  const { xxl, xl, lg, md } = useScreen();

  const formatter: Formatter = useCallback(
    (value: number, entry) => (
      <Typography.Text
        style={{
          display: "inline",
          fontSize: xxl ? "1em" : "1em",
        }}
      >{`${value} (${entry.payload?.value})`}</Typography.Text>
    ),
    [xxl]
  );

  return (
    <ResponsiveContainer width="100%">
      <PieChart>
        <Pie
          dataKey="value"
          data={data}
          innerRadius={xxl ? 30 : xl ? 20 : lg ? 30 : md ? 20 : 10}
          activeIndex={0}
          outerRadius={xxl ? 80 : xl ? 60 : lg ? 80 : md ? 60 : 40}
          fill="#82ca9d"
          isAnimationActive={false}
          width={100}
        >
          {children}
        </Pie>
        <Legend
          align={xxl ? "right" : xl ? "center" : lg ? "right" : "center"}
          verticalAlign={
            xxl ? "middle" : xl ? "bottom" : lg ? "middle" : "bottom"
          }
          layout={
            xxl
              ? "vertical"
              : xl
              ? "horizontal"
              : lg
              ? "vertical"
              : "horizontal"
          }
          wrapperStyle={{
            width: xxl ? "40%" : "unset",
          }}
          formatter={formatter}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

const MockGraph: React.FC<object> = () => (
  <InventoryPieChart data={mockInventoryData as CountEntry[]} />
);

export const Graph: React.FC<{
  type: TargetNodeType;
  graph: DirectedGraph<AssessmentNodes> | undefined;
  nodeCount: number;
}> = ({ type, graph, nodeCount }) => {
  const { assessment } = useContext(SelectedEnvironmentContext);

  const allLinks = useMemo(
    () => (assessment.doc ? inventoryLinks(assessment?.doc?.data) : undefined),
    [assessment]
  );

  const getData = useCallback(async () => {
    let categorizedCount = 0;
    // TODO: Remove resource check when we have resources categorized ENG-3573
    if (type !== "resource" && allLinks?.[type] !== undefined && graph) {
      const data = await Promise.all(
        Object.values(allLinks[type]).map(async (link, index) => {
          const count = (await countNodes(link, graph)).nodes.length;
          categorizedCount += count;

          return {
            name: link.label,
            value: count,
            fill: graphColors[index % (graphColors.length - 1)],
          } as CountEntry;
        })
      );

      // Todo: clean up this logic ENG-3613
      // Node count is a count of all the nodes in the graph
      // Categorized count is a count of all the nodes in the graph that
      // have a category plus all of the nodes in the category itself
      // (i.e All Credentials + Keys + Federated Login + Short-lived login)
      // categorizedCount = (all nodes in the graph) + (all categorized nodes in the graph)
      if (categorizedCount - nodeCount < nodeCount) {
        data.push({
          name: "Uncategorized",
          value: nodeCount - (categorizedCount - nodeCount),
          fill: graphColors[data.length % graphColors.length],
        });
      }

      return data.filter((entry) => entry.value > 0);
    }

    return [];
  }, [allLinks, graph, nodeCount, type]);

  const [counts, setCounts] = useState<CountEntry[]>([]);
  useGuardedEffect(
    (cancellation) => async () => {
      if (
        // TODO: Remove resource check when we have resources categorized ENG-3573
        type !== "resource" &&
        allLinks?.[type] !== undefined &&
        graph &&
        graph?.nodes
      ) {
        const data = await getData();
        cancellation.guard(setCounts)(data);
      }
    },
    [graph, allLinks, type, getData],
    staticError
  );

  return (
    <InventoryPieChart
      // Todo: clean up this logic ENG-3613
      data={counts?.filter(
        (entry: CountEntry) => entry.name !== targetNodeTypesDisplayText(type)
      )}
    ></InventoryPieChart>
  );
};

const ItemSelector: React.FC<{
  handleClick: (value: TargetNodeType) => void;
  icon: JSX.Element;
  selected: boolean;
  type: TargetNodeType;
}> = ({ type, handleClick, selected, icon }) => {
  const onClick = useCallback(() => {
    handleClick(type);
  }, [handleClick, type]);
  return (
    <FilterStatistic
      category={targetNodeTypesDisplayText(type)}
      color={colors.neutral["20"]}
      icon={icon}
      selected={selected}
      textColor={colors.neutral["00"]}
      onClick={onClick}
      showEye={false}
    />
  );
};

const targetNodeTooltips: Record<TargetNodeType, string> = {
  identity: "An identity is an entity that can take action on your system.",
  grant:
    "Grants are assignments of privileges on a set of resources to an identity.",
  credential:
    "Credentials are the material that an actor uses to assume an identity.",
  resource:
    "A resource is any physical or digital asset that is valuable to an organization.",
};
export const ItemChart: React.FC<{ hasEnvironments: boolean }> = ({
  hasEnvironments,
}) => {
  const { graph, step } = useContext(ScopeContext);

  const isLoading = useMemo(
    () => hasEnvironments && step !== "done",
    [step, hasEnvironments]
  );

  const inventoryData: NodeDataRecord = useMemo(() => {
    const getData = (v: NodeDataType): NodeDataWithGraph => {
      const inputGraph = {
        nodes: graph?.nodes.filter(v.predicate) ?? [],
      };
      return { ...v, graph: inputGraph, from: v.predicate };
    };

    return {
      grant: getData(nodeDataFromShow["grant"]),
      credential: getData(nodeDataFromShow["credential"]),
      identity: getData(nodeDataFromShow["identity"]),
      resource: getData(nodeDataFromShow["resource"]),
    };
  }, [graph?.nodes]);

  const [inventoryType, setInventoryType] =
    useState<TargetNodeType>("identity");
  useEffect(() => {
    const foundType = TargetNodeTypeDisplayOrder.find(
      (t) => (inventoryData?.[t]?.graph?.nodes?.length ?? 0) > 0
    );
    setInventoryType(foundType ?? "identity");
  }, [inventoryData]);

  const inventoryDataForType = useMemo(
    () => inventoryData[inventoryType],
    [inventoryData, inventoryType]
  );

  const nodeCount = useMemo(() => {
    return hasEnvironments
      ? inventoryDataForType?.graph?.nodes?.length ?? 0
      : mockNodeCount;
  }, [inventoryDataForType?.graph?.nodes?.length, hasEnvironments]);

  const navigate = useNavigateWithEnv();
  const handleViewFindings = useCallback(() => {
    navigate(`../${AppPaths.Inventory}`);
  }, [navigate]);

  return (
    <Pane
      title={`${nodeCount} Total ${targetNodeTypesDisplayText(inventoryType)}`}
      style={{ width: "100%", minWidth: "10em" }}
      showMore={{ text: "View in Inventory", onClick: handleViewFindings }}
      subtitle={targetNodeTooltips[inventoryType]}
      isLoading={isLoading}
      subtitleType="text"
    >
      <div style={{ display: "flex", height: "100%", width: "100%" }}>
        <VerticalDiv
          style={{ width: "30%", gap: "16px", justifyContent: "center" }}
        >
          {TargetNodeTypeDisplayOrder.map((t) =>
            isLoading ? (
              <Skeleton.Input active style={{ marginTop: "1em" }} key={t} />
            ) : (
              <ItemSelector
                key={t}
                type={t}
                handleClick={setInventoryType}
                selected={inventoryType === t}
                icon={identityTypeIcons[t]}
              />
            )
          )}
        </VerticalDiv>
        <div style={{ width: "70%" }}>
          {!isLoading ? (
            hasEnvironments ? (
              <Graph
                type={inventoryType}
                graph={inventoryDataForType.graph}
                nodeCount={nodeCount}
              />
            ) : (
              <MockGraph />
            )
          ) : (
            <Skeleton
              active
              avatar={{
                size: 150,
              }}
              style={{ padding: "3em" }}
            />
          )}
        </div>
      </div>
    </Pane>
  );
};
