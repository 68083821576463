import { Tenant } from "components/Login";
import { orderBy } from "firebase/firestore";
import { EVIDENCE_COLLECTION } from "firestore/constants";
import { useFirestoreCollection } from "providers/FirestoreProvider";
import { useContext, useEffect, useState } from "react";
import { Evidence } from "shared/types/evidence";

export const useActiveEvidence = () => {
  const [loading, setLoading] = useState(true);
  const tenantId = useContext(Tenant);
  const { docs: evidence } = useFirestoreCollection<Evidence>(
    `o/${tenantId}/${EVIDENCE_COLLECTION}`,
    {
      live: true,
      queryConstraints: [orderBy("startsAt", "desc")],
    }
  );

  useEffect(() => {
    if (evidence !== undefined) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [evidence]);

  return {
    loading,
    list:
      evidence?.filter((e) => !e.data.revokedAt && e.data.source === "allow") ??
      [],
  };
};
