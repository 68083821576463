import { AllFlagsLDClient } from "launchdarkly-react-client-sdk";
import { compact } from "lodash";
import { visibleComponentKeys } from "shared/install/options";
import { OktaComponents } from "shared/integrations/directories/okta/components";
import { AwsComponents } from "shared/integrations/resources/aws/components";
import { GcloudComponents } from "shared/integrations/resources/gcloud/components";
import { PostgresComponents } from "shared/integrations/resources/postgres/components";
import { SnowflakeComponents } from "shared/integrations/resources/snowflake/components";
import { SshComponents } from "shared/integrations/resources/ssh/components";

import { AwsIconUrl } from "./Aws/Aws";
import { EntraIdIconUrl } from "./AzureAD/AzureAd";
import { GcloudIconUrl } from "./Gcloud/Gcloud";
import { GithubIconUrl } from "./Github/Github";
import { GoogleWorkspaceIcon as GoogleWorkspaceIconUrl } from "./GoogleWorkspace/GoogleWorkspace";
import { JiraIconUrl } from "./Jira/Jira";
import { K8sIconUrl } from "./Kubernetes/K8s";
import { OktaIconUrl } from "./Okta/Okta";
import { PagerdutyIconUrl } from "./Pagerduty/Pagerduty";
import { PostgresIconUrl } from "./Postgres/Pg";
import { SlackLogo } from "./Slack/Slack";
import { SnowflakeIconUrl } from "./Snowflake/Snowflake";
import { SplunkIconUrl } from "./Splunk/Splunk";
import { SshIconUrl } from "./Ssh/Ssh";
import { TeamsLogo } from "./Teams/Teams";
import { IntegrationData } from "./types";

export const trackers: IntegrationData[] = [
  {
    key: "jira",
    label: "Jira",
    icon: JiraIconUrl,
    docs: {
      install: "",
    },
  },
];

export const siems: IntegrationData[] = [
  {
    key: "splunk",
    label: "Splunk",
    icon: SplunkIconUrl,
    docs: {
      install: "",
    },
    availableComponents: ["audit-log"],
  },
];

export const approvers: IntegrationData[] = [
  {
    key: "pagerduty",
    label: "Pagerduty",
    icon: PagerdutyIconUrl,
    docs: {
      install:
        "https://docs.p0.dev/integrations/approval-integrations/pagerduty",
    },
    isPro: true,
  },
];

export const notifiers: (
  flags: AllFlagsLDClient["flags"]
) => IntegrationData[] = (flags) =>
  compact([
    {
      key: "slack",
      label: "Slack",
      icon: <SlackLogo />,
      docs: {
        install: "https://docs.p0.dev/integrations/slack",
      },
    },
    flags.msTeamsNotifier && {
      key: "ms-teams",
      label: "Microsoft Teams",
      icon: <TeamsLogo />,
      docs: {
        install: "https://docs.p0.dev/integrations/teams",
      },
    },
  ]);

export const directories: IntegrationData[] = [
  {
    key: "workspace",
    label: "Google Workspace",
    icon: GoogleWorkspaceIconUrl,
    docs: {
      install:
        "https://docs.p0.dev/integrations/directory-integrations/google-workspace",
    },
    isPro: true,
  },
  {
    key: "azure-ad",
    label: "Entra ID",
    icon: EntraIdIconUrl,
    docs: {
      install:
        "https://docs.p0.dev/integrations/directory-integrations/azure-ad",
    },
    isPro: true,
  },
  {
    key: "okta",
    label: "Okta",
    icon: OktaIconUrl,
    availableComponents: visibleComponentKeys(OktaComponents),
    docs: {
      install: "https://docs.p0.dev/integrations/directory-integrations/okta",
    },
    isPro: true,
  },
];

export const resourceIntegrations: (flags: any) => IntegrationData[] = (
  flags
) => {
  const baseResources = [
    {
      key: "aws",
      label: "AWS",
      icon: AwsIconUrl,
      availableComponents: visibleComponentKeys(AwsComponents),
      docs: {
        install: "https://docs.p0.dev/integrations/iam-integrations/aws",
        request:
          "https://docs.p0.dev/integrations/iam-integrations/aws/requesting-access",
      },
    },
    {
      key: "gcloud",
      label: "Google Cloud",
      icon: GcloudIconUrl,
      availableComponents: visibleComponentKeys(GcloudComponents, flags),
      docs: {
        install:
          "https://docs.p0.dev/integrations/iam-integrations/google-cloud",
        request:
          "https://docs.p0.dev/integrations/iam-integrations/google-cloud/requesting-access",
      },
    },
    flags.githubJitIntegration && {
      key: "github",
      label: "GitHub",
      icon: GithubIconUrl,
      availableComponents: ["access-management"],
      docs: {
        install: "https://docs.p0.dev/integrations/iam-integrations/github",
        request:
          "https://docs.p0.dev/integrations/iam-integrations/github/requesting-access",
      },
    },
    {
      key: "k8s",
      label: "Kubernetes",
      icon: K8sIconUrl,
      availableComponents: ["iam-write"],
      docs: {
        install: "https://docs.p0.dev/integrations/iam-integrations/k8s",
        request:
          "https://docs.p0.dev/integrations/iam-integrations/k8s/requesting-access",
      },
    },
    {
      key: "snowflake",
      label: "Snowflake",
      icon: SnowflakeIconUrl,
      availableComponents: visibleComponentKeys(SnowflakeComponents),
      docs: {
        install: "https://docs.p0.dev/integrations/iam-integrations/snowflake",
        request:
          "https://docs.p0.dev/integrations/iam-integrations/snowflake/requesting-access",
      },
    },
    {
      key: "pg",
      label: "PostgreSQL",
      icon: PostgresIconUrl,
      availableComponents: visibleComponentKeys(PostgresComponents),
      docs: {
        install: "https://docs.p0.dev/integrations/iam-integrations/postgresql",
      },
    },
    {
      key: "ssh",
      label: "SSH",
      icon: SshIconUrl,
      availableComponents: visibleComponentKeys(SshComponents),
      docs: {
        install: "https://docs.p0.dev/integrations/iam-integrations/ssh",
      },
    },
  ];

  return compact(baseResources);
};
