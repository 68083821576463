import { OrderedListOutlined, StarOutlined } from "@ant-design/icons";
import { ZeroStatePage } from "components/Dashboard/components/common/ZeroStatePage";
import { EnvironmentContext } from "components/Environment/contexts/EnvironmentContext";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { AuthzButton } from "components/common/AuthzButton";
import { VerticalSpacedDiv } from "components/divs";
import { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import { AppPaths } from "shared/routes/constants";

import { AssessmentGraph } from "../components/AssessmentGraph";
import { Inventory } from "../components/Inventory";
import { NewMonitorModal } from "../components/MonitorAdmin";
import { ScopeSelect } from "../components/ScopeSelect";
import { DetailsColumn } from "../components/cells/DetailsColumn";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedEnvironmentContext } from "../contexts/SelectedEnvironmentContext";
import { useControls } from "../hooks/useControls";
import { QueryResult } from "./QueryResult";

export const Explore: React.FC = () => {
  const { orgSlug, nodeType, nodeKey } = useParams();
  const { last } = useContext(SelectedEnvironmentContext);
  const { hasEnvironments } = useContext(EnvironmentContext);
  const assessmentId = last.doc?.data.assessmentId;
  const { controls, setControls } = useControls();
  const [modalOpen, setModalOpen] = useState(false);
  const { graph, scopeKey, step, runMetaGraph } = useContext(ScopeContext);

  const action = useMemo(() => {
    if (!assessmentId || !orgSlug || !scopeKey) return undefined;
    return [
      DetailsColumn((data) => {
        const base = `/o/${orgSlug}/${AppPaths.Inventory}`;
        const end = `/${encodeURIComponent(data.type)}/${encodeURIComponent(
          data.key
        )}?where=${encodeURIComponent(
          controls.where
        )}&show=${encodeURIComponent(controls.show)}&scope=${scopeKey}`;
        return {
          disabled: data.key === nodeKey,
          key: data.key,
          to: `${base}${end}`,
        };
      }),
    ];
  }, [assessmentId, controls, nodeKey, orgSlug, scopeKey]);

  const createDisabled = useMemo(() => {
    return !graph || controls.where === "";
  }, [graph, controls.where]);

  const closeModal = useCallback(() => setModalOpen(false), [setModalOpen]);
  const openModal = useCallback(() => setModalOpen(true), [setModalOpen]);

  return hasEnvironments ? (
    <>
      <VerticalSpacedDiv style={{ gap: "18px", width: "fit-content" }}>
        <Inventory />
        <AssessmentGraph
          graph={graph ?? { nodes: [] }}
          scopeKey={scopeKey}
          controls={controls}
          onControls={setControls}
          extraColumns={action}
          scopeNode={<ScopeSelect includeAll={runMetaGraph} />}
          searchExtra={
            <GraphTooltip title="Create a monitor from this search query">
              <AuthzButton
                disabled={createDisabled}
                icon={<StarOutlined />}
                roles={["owner", "iamOwner"]}
                onClick={openModal}
              />
            </GraphTooltip>
          }
          step={step}
        />
      </VerticalSpacedDiv>
      {nodeKey && nodeType && <QueryResult />}
      <NewMonitorModal
        open={modalOpen}
        footer={false}
        closeModal={closeModal}
        searchTerm={controls.where}
        show={controls.show}
      />
    </>
  ) : (
    <ZeroStatePage
      title="Discover assets in your cloud environments"
      description="Cloud inventory helps you discover and manage assets across your cloud environments."
      icon={<OrderedListOutlined />}
      docLink="https://docs.p0.dev/what-is-p0/iam-assessment"
    />
  );
};
