import { last } from "lodash";
import { Element, InstallSpec, ItemComponent } from "../../../install/types";
import { GCLOUD_SECURITY_PERIMETER_FEATURE_FLAG } from "./constants";
import { gcloudProjectValidator } from "./validator";

const iamAssessmentDescription =
  "Allows P0 to analyze your IAM configuration for security issues.";

const GCLOUD_ORGANIZATION_PATTERN = /^[0-9]+$/;

const HTTPS_URL_PATTERN = /^https:\/\/[a-zA-Z0-9-.]+$/;

const gcloudOrganizationValidator = async (_id: string, field: string) => {
  if (!field.match(GCLOUD_ORGANIZATION_PATTERN))
    return "Google Cloud organization identifiers must consist of numerals only";
};

const gcloudSecurityPerimeterUrl = async (_id: string, field: string) => {
  if (last(field) === "/") return "url must not end with a slash";
  if (!field.match(HTTPS_URL_PATTERN)) return "url must use https://";
};

export const GcloudComponents = InstallSpec({
  "access-logs": ItemComponent({
    label: "Access logging",
    description:
      "Enhance IAM assessments with continuous usage data from your projects.",
    type: "dynamic",
    schema: {
      "pre-20240729-pubsub-ingest": Element({
        type: "hidden",
        label: "Legacy Pub/Sub ingest table name",
      }),
    },
  }),
  "iam-assessment": ItemComponent({
    label: "IAM assessment",
    description: iamAssessmentDescription,
    type: "string",
    schema: {},
    validator: gcloudProjectValidator,
  }),
  "iam-write": ItemComponent({
    label: "IAM management",
    description:
      "Allows P0 to grant and revoke Google Cloud access for your users.",
    type: "string",
    schema: {},
    validator: gcloudProjectValidator,
  }),
  "org-wide-policy": ItemComponent({
    label: "Organization policies",
    description:
      "Allows P0 to analyze your organization and folder IAM policies for security issues.",
    type: "singleton",
    schema: {},
  }),
  "sharing-restriction": ItemComponent({
    label: "Domain-restricted sharing",
    description: "Prevent privilege escalation via P0's integration.",
    type: "dynamic",
    schema: {},
  }),
  "org-access-logs": ItemComponent({
    label: "Organization access logging",
    description:
      "Enhance IAM assessments with continuous usage data from your entire organization.",
    type: "singleton",
    schema: {
      "pre-20240729-pubsub-ingest": Element({
        type: "hidden",
        label: "Legacy Pub/Sub ingest table name",
      }),
    },
  }),
  "org-iam-assessment": ItemComponent({
    label: "Organization IAM assessment",
    description: `${iamAssessmentDescription} Installed for every project in your organization.`,
    type: "singleton",
    schema: {},
  }),
  "key-rotation": ItemComponent({
    type: "singleton",
    label: "Key Rotation",
    description: "Allows P0 rotate your service account keys",
    hidden: true,
    schema: {
      runUrl: Element({
        label:
          "URL of Cloud Run service that exposes functions for deletion, disabling, and rotation",
        type: "string",
      }),
    },
  }),
  root: ItemComponent({
    label: "Google Cloud organization",
    type: "singleton",
    schema: {
      organizationId: Element({
        label: "Organization ID",
        type: "string",
        validator: gcloudOrganizationValidator,
      }),
      serviceAccountEmail: Element({
        label: "Service account",
        type: "hidden",
      }),
      impersonateViaEmail: Element({
        label: "Intermediate service account",
        description:
          "If present, service account to impersonate prior to impersonating serviceAccountEmail",
        type: "hidden",
      }),
    },
  }),
  "iam-write-security-perimeter": ItemComponent({
    label: "IAM management security perimeter",
    description:
      "Provides a secure mechanism for P0 to make changes to your projects' IAM configurations.",
    type: "string",
    maxItems: 1,
    validator: gcloudProjectValidator,
    schema: {
      allowedDomains: Element({
        label: "Allowed domains",
        description:
          "Only users with these email domains may be granted access to your projects",
        type: "generated",
      }),
      imageDigest: Element({
        label: "Image digest",
        description:
          "The digest of the Docker image to use for the security perimeter",
        type: "generated",
      }),
      cloudRunUrl: Element({
        label: "Security perimeter URL",
        description:
          "The URL of the Cloud Run service that enforces the security perimeter",
        type: "string",
        validator: gcloudSecurityPerimeterUrl,
      }),
    },
    featureFlag: GCLOUD_SECURITY_PERIMETER_FEATURE_FLAG,
  }),
});
