import { Tenant } from "components/Login";
import { QueryConstraint, orderBy, where } from "firebase/firestore";
import { PERMISSION_REQUESTS_COLLECTION } from "firestore/constants";
import { useFirestoreCollection } from "providers/FirestoreProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { PermissionRequest } from "shared/types/permission";
import { RequestStatus } from "shared/types/request-status";

export const useRequests = (
  statuses: readonly RequestStatus[],
  lookbackDays?: number
) => {
  const [loading, setLoading] = useState(true);
  const tenantId = useContext(Tenant);

  const queryConstraints: QueryConstraint[] = useMemo(() => {
    const constraints: QueryConstraint[] = [
      where("status", "in", statuses),
      orderBy("requestedTimestamp", "desc"),
    ];

    if (lookbackDays !== undefined) {
      const currentDate = new Date();
      const pastDate = new Date(
        currentDate.setDate(currentDate.getDate() - lookbackDays)
      );
      constraints.push(where("requestedTimestamp", ">=", pastDate.getTime()));
    }

    return constraints;
  }, [statuses, lookbackDays]);

  const { docs: requests } = useFirestoreCollection<PermissionRequest>(
    `o/${tenantId}/${PERMISSION_REQUESTS_COLLECTION}`,
    {
      live: true,
      queryConstraints,
    }
  );

  useEffect(() => {
    if (requests !== undefined) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [requests]);

  return {
    loading,
    requests: requests ?? [],
    hasRequests: !loading && !!requests?.length,
  };
};
