import { MenuOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Alert, Drawer, Segmented, Spin, Typography } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { DiscoverVisualization } from "components/GraphTable/DiscoverVisualization";
import { Heading } from "components/Heading";
import { SpaceBetweenDiv } from "components/divs";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useLocalStorage } from "hooks/useLocalStorage";
import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router";
import { assessmentParse } from "shared/assessment/issues/presets";
import { aggregate } from "shared/graph/aggregate";
import { DiscoverMatch, discoverPaths } from "shared/graph/discover";
import { NodePredicate } from "shared/graph/search";
import {
  DEFAULT_GRAPH_SEARCH_SETTINGS,
  GraphSearchSettings,
} from "shared/graph/settings";
import { ConnectedNode, DirectedGraph, Reducers } from "shared/graph/types";
import {
  AnyAggregates,
  AssessmentNodes,
  TargetNodeTypes,
  isTargetNodeType,
} from "shared/types/assessment/data";
import { isa } from "shared/types/is";
import { staticError } from "utils/console";

import { NodeDisplay } from "../components/node/NodeDisplay";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedEnvironmentContext } from "../contexts/SelectedEnvironmentContext";
import { useNavigateWithEnv } from "../hooks/useNavigateWithEnv";
import { NodeLocaleVisualization } from "./AssessmentNodeLocale";
import { NodeFlowRenderer } from "./GraphVisualization";
import { nodeDataFromShow } from "./node";
import { NodePropertiesDescriber } from "./node/NodeProperties";
import { NodeText, NodeTitler } from "./node/NodeText";

const PROPERTIES_ONLY_WIDTH = "600px";

const NODE_DISPLAY_OPTIONS = [
  { value: "table", label: <MenuOutlined /> },
  { value: "graph", label: <ShareAltOutlined /> },
];

export const ResultDetailContainer: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => (
  // 108px subtraction here is necessary to correct for padding / headings
  <div style={{ minHeight: "calc(100% - 108px)", position: "relative" }}>
    {children}
  </div>
);

export const ResultDetailDrawer: React.FC<
  React.PropsWithChildren<{ isWide?: boolean; title: ReactNode }>
> = ({ children, isWide, title }) => {
  const location = useLocation();
  const navigate = useNavigateWithEnv();

  const closeDrawer = useCallback(
    () => navigate({ ...location, pathname: "../.." }, { relative: "path" }),
    [location, navigate]
  );

  return (
    <Drawer
      bodyStyle={{ padding: "12px" }}
      getContainer={false}
      mask={false}
      onClose={closeDrawer}
      open
      style={{ position: "absolute" }}
      title={<div style={{ display: "flex", gap: "0.3em" }}>{title}</div>}
      width={
        isWide
          ? "max(50%, min(80%, 1000px))"
          : `max(40%, min(80%, ${PROPERTIES_ONLY_WIDTH}))`
      }
    >
      {children}
    </Drawer>
  );
};

export type ResultDetailProps = {
  header?: ReactNode;
  mode: "finding" | "query result";
  node: { key: string; type: string };
  terms: string[];
  title?: ReactNode;
};

export const ResultDetail: React.FC<ResultDetailProps> = ({
  node: { key, type },
  ...props
}) => {
  const nodeData = useMemo(
    () => (isa(TargetNodeTypes, type) ? nodeDataFromShow[type] : undefined),
    [type]
  );
  if (!nodeData)
    return (
      <Alert message="Unexpected node type" description={type} type="error" />
    );

  const { predicate, reducers } = nodeData;
  return (
    <InnerResultDetail
      from={predicate}
      node={{ key, type }}
      reducers={reducers as Reducers<AssessmentNodes, AnyAggregates>}
      {...props}
    />
  );
};

const InnerResultDetail = <A extends AnyAggregates>({
  from,
  header,
  mode,
  node: { key, type },
  reducers,
  terms,
  title,
}: ResultDetailProps & {
  from: NodePredicate<AssessmentNodes>;
  reducers: Reducers<AssessmentNodes, A>;
}): ReactElement => {
  const { last } = useContext(SelectedEnvironmentContext);
  const { loading } = last;
  const { graph, provider, step } = useContext(ScopeContext);
  const [results, setResults] =
    useState<DiscoverMatch<DirectedGraph<AssessmentNodes>>[]>();
  const [settings, _] = useLocalStorage<GraphSearchSettings>(
    "graph-search-settings"
  );
  const [displayMode, setDisplayMode] = useState<"graph" | "table">("table");

  const node = useMemo(() => {
    const node = graph?.nodes.find((n) => n.type === type && n.key === key);
    if (!node) return undefined;
    return aggregate({ nodes: [node] }, reducers).nodes[0];
  }, [graph, key, type, reducers]);

  const nodeRenderer = useMemo(
    () =>
      NodeText(node, provider, {
        detailed: false,
        hideThis: true,
      }),
    [provider, node]
  ) as NodeFlowRenderer<AssessmentNodes>;

  useGuardedEffect(
    (cancellation) => async () => {
      if (!node) return undefined;
      // Only ever one node
      const graph = {
        nodes: [node as ConnectedNode<AssessmentNodes, keyof AssessmentNodes>],
      };
      const [discovered] = await discoverPaths({
        graph,
        from,
        search: assessmentParse(terms.join(" ")),
      });
      const results = discovered?.matches;
      if (!results) return cancellation.guard(setResults)([]);
      setResults([{ node: node, matches: results }]);
    },
    [node, from, terms],
    staticError
  );

  const stopOn =
    mode === "finding"
      ? []
      : (settings ?? DEFAULT_GRAPH_SEARCH_SETTINGS).stopOn;

  // Only show paths if they include at least one edge
  const showPaths = useMemo(
    () =>
      results?.some(({ matches }) =>
        matches.some(({ paths }) => paths.some((p) => p.length > 1))
      ),
    [results]
  );

  const nodeTitle = useMemo(
    () =>
      title ??
      (node &&
        NodeText(node, provider, { noHover: true, hideThis: true })[
          node.type
        ]?.(node as any)),
    [node, provider, title]
  );

  return (
    <ResultDetailDrawer
      title={nodeTitle}
      isWide={showPaths || displayMode === "graph"}
    >
      {header}
      {loading || step !== "done" ? (
        <Spin />
      ) : !graph || !node || !isTargetNodeType(node) ? (
        <Heading title="Not Found" />
      ) : (
        <div style={{ width: "100%" }}>
          {showPaths && !!results && (
            <>
              <Typography.Title level={4} style={{ marginTop: "0.5em" }}>
                {mode === "query result" ? "Query Path" : "Attack Path"}
              </Typography.Title>
              <DiscoverVisualization
                matches={results}
                renderer={NodeText(node, provider)}
                settings={{
                  maxResults: 1,
                  maxPaths: 100,
                  stopOn,
                }}
                titler={NodeTitler(provider)}
                describer={NodePropertiesDescriber(provider)}
                viewStyle={{ height: "500px", width: "100%" }}
              />
            </>
          )}

          <div style={{ maxWidth: PROPERTIES_ONLY_WIDTH }}>
            <SpaceBetweenDiv>
              <Typography.Title level={4} style={{ marginTop: "0.5em" }}>
                Details
              </Typography.Title>

              <Segmented
                options={NODE_DISPLAY_OPTIONS}
                value={displayMode ?? "table"}
                onChange={
                  setDisplayMode as Dispatch<SetStateAction<SegmentedValue>>
                }
              />
            </SpaceBetweenDiv>
            {displayMode === "graph" ? (
              nodeRenderer && (
                <NodeLocaleVisualization
                  current={node}
                  provider={provider}
                  renderer={nodeRenderer}
                  describer={NodePropertiesDescriber(provider)}
                />
              )
            ) : (
              // Default to table view
              <div style={{ maxWidth: PROPERTIES_ONLY_WIDTH }}>
                <NodeDisplay node={node} />
              </div>
            )}
          </div>
        </div>
      )}
    </ResultDetailDrawer>
  );
};
