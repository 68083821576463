import Link from "antd/lib/typography/Link";
import { PermissionIntegrationLogo } from "components/Integrations/IntegrationLogo";

export const KeyLink: React.FC<{
  projectId: string;
  serviceAccountId: string;
  keyId: string;
}> = ({ projectId, serviceAccountId, keyId }) => {
  return (
    <Link
      href={`https://console.cloud.google.com/iam-admin/serviceaccounts/details/${serviceAccountId}/keys?project=${projectId}`}
      target="_blank"
      rel="noopener"
    >
      {keyId}&nbsp;{PermissionIntegrationLogo["gcloud"]}
    </Link>
  );
};
