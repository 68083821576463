import { Spin } from "antd";
import { useAuthFetch } from "components/Login/hook";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useSearchParams } from "react-router-dom";
import { InstallRequest } from "shared/integrations/resources/github/types";

export const GithubInstaller: React.FC<{
  onError: (error: string | undefined) => void;
}> = ({ onError }) => {
  const [params, setSearchParams] = useSearchParams();
  const authFetch = useAuthFetch(onError);

  useGuardedEffect(
    (cancellation) => async () => {
      const installationId = getInstallationIdOrError(params);
      const request: InstallRequest = { installationId };

      if (cancellation.isCancelled) return;

      await authFetch(`integrations/github`, {
        method: "POST",
        json: request,
      });

      cancellation.guard(setSearchParams)({});
    },
    [authFetch, params, setSearchParams],
    onError
  );

  return <Spin />;
};

const getInstallationIdOrError = (params: URLSearchParams) => {
  const installationId = params.get("installation_id");

  if (!installationId) {
    throw new Error("Missing installation_id parameter");
  }

  return installationId;
};
