import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { VerticalDiv } from "components/divs";
import styled from "styled-components";
import { colors } from "styles/variables";

const FilterStatisticDiv = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 4px;
  height: 45px;
  align-items: center;
  gap: 4px;
  &.selected {
    background-color: #e6f7ff;
    -webkit-box-shadow: inset 0px 0px 2px ${colors.neutral["30"]};
    -moz-box-shadow: inset 0px 0px 2px ${colors.neutral["30"]};
    box-shadow: inset 0px 0px 2px ${colors.neutral["30"]};
  }
  &:hover,
  &:focus {
    background-color: ${colors.neutral["20"]};
    -webkit-box-shadow: 0 2px 4px ${colors.neutral["10"]};
    -moz-box-shadow: 0 2px 4px ${colors.neutral["10"]};
    box-shadow: 0 2px 4px ${colors.neutral["10"]};
  }
`;

type BaseProps = {
  category: string;
  color: string;
  showEye: boolean;
  textColor: string;
  selected?: boolean;
  onClick?: () => void;
};

type PropsWithIcon = {
  count?: never;
  icon: JSX.Element;
} & BaseProps;

type PropsWithCount = {
  count: number;
  icon?: never;
} & BaseProps;

export const FilterStatistic: React.FC<PropsWithCount | PropsWithIcon> = ({
  category,
  color,
  count,
  onClick,
  selected,
  icon,
  showEye: showEye,
}) => {
  return (
    <FilterStatisticDiv
      onClick={onClick}
      className={selected ? "selected" : ""}
      style={{ maxWidth: "15em" }}
    >
      <div style={{ display: "flex", width: "100%", height: "100%" }}>
        <div style={{ display: "flex", width: "90%", height: "100%" }}>
          <Tag color={color} style={{ height: "100%" }} />
          <VerticalDiv>
            <div style={{ fontSize: "22px", lineHeight: "22px" }}>
              {count?.toLocaleString()} {icon}
            </div>
            <div style={{ fontSize: "12px" }}>{category}</div>
          </VerticalDiv>
        </div>
        {showEye && (
          <div
            style={{
              width: "10%",
              height: "100%",
              color: colors.neutral["30"],
              fontSize: "22px",
              marginRight: "6px",
            }}
          >
            {selected ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </div>
        )}
      </div>
    </FilterStatisticDiv>
  );
};
