import { useUser } from "components/Login/hook";
import { Link, LinkProps } from "react-router-dom";

import { addEnvironmentToPath } from "../environment";
import { useSelectedEnvironment } from "../hooks/useSelectedEnvironment";

// This component is used to add the environment query parameter to all links in the CAGA work.
//To use properly, leave all the environment query parameter handling to this component.
export const LinkWithEnvironment = ({
  children,
  to,
  withTenant,
  ...props
}: LinkProps & { withTenant?: boolean }) => {
  const { orgSlug } = useUser();
  const [selectedEnvironment] = useSelectedEnvironment();
  const newTo = addEnvironmentToPath(
    to,
    selectedEnvironment,
    withTenant ? orgSlug : undefined
  );
  return (
    <Link to={newTo} {...props}>
      {children}
    </Link>
  );
};
