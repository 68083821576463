import { EnvironmentContext } from "components/Environment/contexts/EnvironmentContext";
import { Tenant } from "components/Login";
import { where } from "firebase/firestore";
import { useFirestoreCollection } from "providers/FirestoreProvider";
import { useContext } from "react";
import { toAssessmentPath } from "shared/assessment/helper";
import { Finding } from "shared/types/assessment/finding";

/**
 * Return all open findings, and any findings resolved or ignored since sinceTimestamp
 */
export const useDashboardFindings = (sinceTimestamp: number) => {
  const tenantId = useContext(Tenant);
  const { selected } = useContext(EnvironmentContext);
  const assessmentId = selected?.assessmentId;
  const findingPath = assessmentId
    ? `${toAssessmentPath(tenantId, assessmentId)}/findings`
    : undefined;
  const { docs: openFindings, loading: openLoading } =
    useFirestoreCollection<Finding>(findingPath, {
      queryConstraints: [where("state", "==", "open")],
    });

  const { docs: resolvedFindings, loading: resolvedLoading } =
    useFirestoreCollection<Finding>(findingPath, {
      queryConstraints: [
        where("state", "==", "resolved"),
        where("trigger.at", ">", sinceTimestamp),
      ],
    });

  const { docs: ignoredFindings, loading: ignoredLoading } =
    useFirestoreCollection<Finding>(findingPath, {
      queryConstraints: [
        where("state", "==", "ignored"),
        where("ignore.at", ">", sinceTimestamp),
      ],
    });

  const findings = [
    ...(ignoredFindings ?? []),
    ...(resolvedFindings ?? []),
    ...(openFindings ?? []),
  ];

  return {
    loading: ignoredLoading || resolvedLoading || openLoading,
    findings,
  };
};
