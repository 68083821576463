import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  FieldTimeOutlined,
  LikeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Empty, List, Skeleton, Statistic } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { mean, partition, sortBy } from "lodash";
import pluralize from "pluralize";
import React, { useCallback, useMemo } from "react";
import { DAYS, HOURS, MINUTES, SECONDS } from "shared/time";
import { ApprovalDetails, PermissionRequest } from "shared/types/permission";
import { colors } from "styles/variables";

import {
  DurationFormat,
  GrantToRequestorRecord,
  JitAggregateData,
  ProcessedRequestData,
} from "../../types";

/** Returns true if the change (delta) for the given metric is considered "risky." */
const isRisky = (key: keyof JitAggregateData, delta: number): boolean => {
  const riskRules: Record<keyof JitAggregateData, (n: number) => boolean> = {
    totalRequests: (n) => n < 0,
    meanTimeToApprove: (n) => n > 0,
    totalApprovedRequests: (n) => n < 0,
    uniqueUsers: (n) => n < 0,
    meanDurationOfAccess: (n) => n > 0,
    instantApprovals: (n) => n < 0,
    accessOverThreshold: (n) => n < 0,
    medianDurationOfAccess: () => false, // not used
  };

  return riskRules[key] ? riskRules[key](delta) : false;
};

/** Calculates the percentage change between current and previous values. */
const calcPercentageChange = (curr: number, prev: number): string | null => {
  if (prev === 0 || curr === 0 || Number.isNaN(curr) || Number.isNaN(prev)) {
    return null;
  }
  const diff = curr - prev;
  if (diff === 0) return null;
  const pct = (diff / prev) * 100;
  const sign = diff >= 0 ? "+" : "-";
  return `${sign}${formatLargePercentage(Math.abs(pct))}`;
};

/** Formats a duration (in milliseconds) to a human-readable format. */
const formatDuration = (milliseconds = 0): DurationFormat => {
  if (milliseconds <= 0) {
    return { simple: "less than a second", exact: "0h 0m 0s", value: 0 };
  }

  const units = [
    { label: "day", ms: DAYS },
    { label: "hour", ms: HOURS },
    { label: "minute", ms: MINUTES },
    { label: "second", ms: SECONDS },
  ];

  const largestUnit = units.find(({ ms }) => milliseconds >= ms);
  const count = largestUnit ? Math.floor(milliseconds / largestUnit.ms) : 0;
  const simple = largestUnit
    ? `~${count} ${pluralize(largestUnit.label, count)}`
    : "< 1 second";

  const hours = Math.floor(milliseconds / HOURS);
  const minutes = Math.floor((milliseconds % HOURS) / MINUTES);
  const seconds = Math.floor((milliseconds % MINUTES) / SECONDS);

  return {
    simple,
    exact: `${hours}h ${minutes}m ${seconds}s`,
    value: milliseconds,
  };
};

const LONG_REQUEST_THRESHOLD = 30 * 24 * 60 * 60 * 1000;
const INSTANT_APPROVAL_SOURCES: Omit<
  ApprovalDetails["approvalSource"],
  "slack"
>[] = ["persistent", "pagerduty", "evidence"];

/** Process raw requests into an array of ProcessedRequestData. */
const processRequestData = (
  requests: { data: PermissionRequest }[]
): ProcessedRequestData[] =>
  requests
    .filter(({ data: { status } }) => !status.includes("ERRORED"))
    .map(({ data }) => ({
      durationOfRequest: data.revokedTimestamp
        ? data.revokedTimestamp - data.requestedTimestamp
        : 0,
      timeToApprove: data.approvalDetails?.approvedTimestamp
        ? data.approvalDetails.approvedTimestamp - data.requestedTimestamp
        : 0,
      approved: !!data.approvalDetails?.approvedTimestamp,
      isInstantApproval:
        !!data.approvalDetails &&
        INSTANT_APPROVAL_SOURCES.includes(data.approvalDetails.approvalSource),
    }));

/** Splits the requests into two groups: "previous" and "current" based on lookbackDays. */
const processRequestDataForComparison = (
  allRequests: { data: PermissionRequest }[],
  lookbackDays: number
) => {
  const now = Date.now();
  const msPerDay = 24 * 60 * 60 * 1000;
  const totalLookbackMs = lookbackDays * msPerDay;
  const previousStart = now - 2 * totalLookbackMs;
  const previousEnd = now - totalLookbackMs;

  const [previousRequests, currentRequests] = partition(
    allRequests,
    ({ data }) => {
      const t = data.requestedTimestamp;
      return t >= previousStart && t < previousEnd;
    }
  );

  return {
    previous: processRequestData(previousRequests),
    current: processRequestData(currentRequests),
  };
};

/** Aggregate processed request data into summary statistics. */
const aggregateRequests = (
  requestData: ProcessedRequestData[],
  grantToRequestorMap: Record<string, GrantToRequestorRecord>
): JitAggregateData => {
  const initial = {
    totalRequests: 0,
    totalApprovedRequests: 0,
    approvalTimes: [] as number[],
    durations: [] as number[],
    instantApprovals: 0,
  };

  const result = requestData.reduce(
    (
      acc,
      { approved, timeToApprove, durationOfRequest, isInstantApproval }
    ) => ({
      totalRequests: acc.totalRequests + 1,
      totalApprovedRequests: acc.totalApprovedRequests + (approved ? 1 : 0),
      approvalTimes:
        approved && !isInstantApproval
          ? [...acc.approvalTimes, timeToApprove]
          : acc.approvalTimes,
      durations:
        durationOfRequest > 0
          ? [...acc.durations, durationOfRequest]
          : acc.durations,
      instantApprovals: isInstantApproval
        ? acc.instantApprovals + 1
        : acc.instantApprovals,
    }),
    initial
  );

  const accessOverThreshold = result.durations.filter(
    (d) => d > LONG_REQUEST_THRESHOLD
  ).length;
  const meanTimeToApprove = formatDuration(mean(result.approvalTimes));
  const meanDurationOfAccess = formatDuration(mean(result.durations));
  const sortedDurations = sortBy(result.durations);
  const medianDurationOfAccess = formatDuration(
    sortedDurations[Math.floor(sortedDurations.length / 2)] || 0
  );

  const uniqueUsers = new Set(
    Object.values(grantToRequestorMap).flatMap(({ countsByRequestor }) =>
      Object.keys(countsByRequestor)
    )
  ).size;

  return {
    totalRequests: result.totalRequests,
    totalApprovedRequests: result.totalApprovedRequests,
    meanTimeToApprove,
    meanDurationOfAccess,
    medianDurationOfAccess,
    uniqueUsers,
    instantApprovals: result.instantApprovals,
    accessOverThreshold,
  };
};

const formatLargePercentage = (num: number) => {
  const decimals = 2;
  const suffixes = ["", "K", "M", "B"];
  let value = num;
  let suffixIndex = 0;

  while (value >= 1000 && suffixIndex < suffixes.length - 1) {
    value /= 1000;
    suffixIndex++;
  }

  const formattedValue =
    Number((value * Math.pow(10, decimals)).toFixed(0)) /
    Math.pow(10, decimals);
  return `${formattedValue}${suffixes[suffixIndex]}%`;
};

// Renders a statistic item with its tooltip suffix if needed
const StatisticItem: React.FC<StatisticEntry> = ({
  title,
  render,
  icon,
  formattedChange,
  change,
  isRiskier,
  lookbackDays,
  unit,
  prev,
}) => {
  let itemSuffix = null;
  if (formattedChange) {
    const isPositiveChange = formattedChange.startsWith("+");
    const suffixColor = isRiskier
      ? colors.tagColors.red.text
      : colors.success["60"];
    const ArrowIcon = isPositiveChange ? ArrowUpOutlined : ArrowDownOutlined;
    const ArrowEmoji = isPositiveChange ? "↑" : "↓";
    const formattedTooltipText = `${ArrowEmoji} ${change}  ${
      unit ? `${pluralize(unit, parseInt(change))} ` : ""
    }vs. prev ${lookbackDays} days (${prev})`;
    itemSuffix = (
      <GraphTooltip title={formattedTooltipText}>
        <ArrowIcon style={{ color: suffixColor, fontSize: "20px" }} />
        <span style={{ color: suffixColor, fontSize: "12px" }}>
          {formattedChange}
        </span>
      </GraphTooltip>
    );
  }

  return (
    <Statistic
      style={{ marginBottom: ".5em" }}
      title={title}
      valueRender={render}
      prefix={icon}
      suffix={itemSuffix}
    />
  );
};

interface StatisticEntry {
  icon: React.ReactNode;
  key: string;
  title: string;
  render: () => React.ReactNode;
  formattedChange: string | null;
  change: string;
  isRiskier: boolean;
  lookbackDays: number;
  unit: string | null;
  prev: string;
}

interface JitSummaryStatsProps {
  data: { data: PermissionRequest }[];
  requestorMap: Record<string, GrantToRequestorRecord>;
  isLoading: boolean;
  lookbackDays: number;
}

/**
 * Build statistic entries from aggregated data.
 * Calculates delta and percentage change between current and previous values.
 */
const buildRequestData = (
  curr: JitAggregateData,
  prev: JitAggregateData,
  lookbackDays: number
): StatisticEntry[] => [
  {
    icon: (
      <FieldTimeOutlined
        style={{ paddingRight: "8px", color: colors.neutral["40"] }}
      />
    ),
    key: "meanTimeToApprove",
    title: "Mean time to approve",
    render: () => (
      <GraphTooltip title={curr.meanTimeToApprove.exact}>
        <span>{curr.meanTimeToApprove.simple}</span>
      </GraphTooltip>
    ),
    formattedChange: calcPercentageChange(
      curr.meanTimeToApprove.value,
      prev.meanTimeToApprove.value
    ),
    change: formatDuration(
      Math.abs(curr.meanTimeToApprove.value - prev.meanTimeToApprove.value)
    ).simple,
    isRiskier: isRisky(
      "meanTimeToApprove",
      curr.meanTimeToApprove.value - prev.meanTimeToApprove.value
    ),
    lookbackDays,
    unit: null,
    prev: prev.meanTimeToApprove.simple,
  },
  {
    icon: (
      <CheckCircleOutlined
        style={{ paddingRight: "8px", color: colors.neutral["40"] }}
      />
    ),
    key: "requestsPerDay",
    title: "Requests per day",
    render: () => (
      <span>
        {(curr.totalRequests / lookbackDays).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}
      </span>
    ),
    formattedChange: calcPercentageChange(
      curr.totalRequests,
      prev.totalRequests
    ),
    change: `${Math.abs(
      curr.totalRequests - prev.totalRequests
    ).toLocaleString()}`,
    isRiskier: isRisky(
      "totalRequests",
      curr.totalRequests - prev.totalRequests
    ),
    lookbackDays,
    unit: "request",
    prev: prev.totalRequests.toLocaleString(),
  },
  {
    icon: (
      <LikeOutlined
        style={{ paddingRight: "8px", color: colors.neutral["40"] }}
      />
    ),
    key: "percentageApproved",
    title: "Percentage approved",
    render: () => {
      if (curr.totalRequests === 0) {
        return <span>0%</span>;
      }
      const pctApproved =
        (curr.totalApprovedRequests / curr.totalRequests) * 100;
      const decimals = pctApproved > 5 ? 0 : 1;
      return (
        <span>
          {pctApproved.toLocaleString(undefined, {
            maximumFractionDigits: decimals,
          })}
          %
        </span>
      );
    },
    formattedChange: calcPercentageChange(
      curr.totalApprovedRequests,
      prev.totalApprovedRequests
    ),
    change: Math.abs(
      curr.totalApprovedRequests - prev.totalApprovedRequests
    ).toLocaleString(undefined, {
      maximumFractionDigits: 1,
    }),
    isRiskier: isRisky(
      "totalApprovedRequests",
      curr.totalApprovedRequests - prev.totalApprovedRequests
    ),
    lookbackDays,
    unit: "approved requests",
    prev: prev.totalApprovedRequests.toLocaleString(),
  },
  {
    icon: (
      <ClockCircleOutlined
        style={{ paddingRight: "8px", color: colors.neutral["40"] }}
      />
    ),
    key: "instantApprovals",
    title: "Percentage automatically approved",
    render: () => {
      if (curr.totalRequests === 0) {
        return <span>0%</span>;
      }
      const pctInstantApprovals =
        (curr.instantApprovals / curr.totalRequests) * 100;
      const decimals = pctInstantApprovals > 5 ? 0 : 1;
      return (
        <span>
          {pctInstantApprovals.toLocaleString(undefined, {
            maximumFractionDigits: decimals,
          })}
          %
        </span>
      );
    },
    formattedChange: calcPercentageChange(
      curr.instantApprovals,
      prev.instantApprovals
    ),
    change: Math.abs(
      curr.instantApprovals - prev.instantApprovals
    ).toLocaleString(undefined, { maximumFractionDigits: 1 }),
    isRiskier: isRisky(
      "instantApprovals",
      curr.instantApprovals - prev.instantApprovals
    ),
    lookbackDays,
    unit: "automatic approval",
    prev: prev.instantApprovals.toLocaleString(),
  },
  {
    icon: (
      <ClockCircleOutlined
        style={{ paddingRight: "8px", color: colors.neutral["40"] }}
      />
    ),
    key: "meanDurationOfAccess",
    title: "Mean duration of access",
    render: () => (
      <GraphTooltip title={curr.medianDurationOfAccess.exact}>
        <span>{curr.medianDurationOfAccess.simple}</span>
      </GraphTooltip>
    ),
    formattedChange: calcPercentageChange(
      curr.medianDurationOfAccess.value,
      prev.medianDurationOfAccess.value
    ),
    change: formatDuration(
      Math.abs(
        curr.medianDurationOfAccess.value - prev.medianDurationOfAccess.value
      )
    ).simple,
    isRiskier: isRisky(
      "meanDurationOfAccess",
      curr.medianDurationOfAccess.value - prev.medianDurationOfAccess.value
    ),
    lookbackDays,
    unit: null,
    prev: prev.meanDurationOfAccess.simple,
  },
  {
    icon: (
      <ClockCircleOutlined
        style={{ paddingRight: "8px", color: colors.neutral["40"] }}
      />
    ),
    key: "accessOverThreshold",
    title: "Access greater than 30 days",
    render: () => <span>{curr.accessOverThreshold}</span>,
    formattedChange: calcPercentageChange(
      curr.accessOverThreshold,
      prev.accessOverThreshold
    ),
    change: Math.abs(
      curr.accessOverThreshold - prev.accessOverThreshold
    ).toLocaleString(),
    isRiskier: isRisky(
      "accessOverThreshold",
      curr.accessOverThreshold - prev.accessOverThreshold
    ),
    lookbackDays,
    unit: "request",
    prev: prev.accessOverThreshold.toLocaleString(),
  },
  {
    icon: (
      <UserOutlined
        style={{ paddingRight: "8px", color: colors.neutral["40"] }}
      />
    ),
    key: "uniqueUsers",
    title: "Unique users",
    render: () => <span>{curr.uniqueUsers}</span>,
    formattedChange: calcPercentageChange(curr.uniqueUsers, prev.uniqueUsers),
    change: Math.abs(curr.uniqueUsers - prev.uniqueUsers).toLocaleString(),
    isRiskier: isRisky("uniqueUsers", curr.uniqueUsers - prev.uniqueUsers),
    lookbackDays,
    unit: "users",
    prev: prev.uniqueUsers.toLocaleString(),
  },
];

export const JitSummaryStats: React.FC<JitSummaryStatsProps> = ({
  data,
  isLoading,
  requestorMap,
  lookbackDays,
}) => {
  const { current, previous } = useMemo(
    () => processRequestDataForComparison(data, lookbackDays),
    [data, lookbackDays]
  );

  const currentAggregatedRequests = useMemo(
    () => aggregateRequests(current, requestorMap),
    [current, requestorMap]
  );

  const previousAggregatedRequests = useMemo(
    () => aggregateRequests(previous, requestorMap),
    [previous, requestorMap]
  );

  const renderItem = useCallback(
    (entry: StatisticEntry) => <StatisticItem {...entry} />,
    []
  );

  if (isLoading) return <Skeleton active />;

  if (currentAggregatedRequests.totalRequests === 0) {
    return (
      <Empty
        description={`No requests found for the past ${lookbackDays} days.`}
      />
    );
  }

  const requestData = buildRequestData(
    currentAggregatedRequests,
    previousAggregatedRequests,
    lookbackDays
  );

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "start",
      }}
    >
      <List
        itemLayout="horizontal"
        dataSource={requestData.slice(0, 4)}
        renderItem={renderItem}
      />
      <List
        itemLayout="horizontal"
        dataSource={requestData.slice(4)}
        renderItem={renderItem}
      />
    </div>
  );
};
