import { ApiFilled } from "@ant-design/icons";
import { Alert, Button, Spin, Typography } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { Link, NavLink, Navigate, Outlet, Route } from "react-router-dom";
import { AppPaths } from "shared/routes/constants";

import { useScreen } from "../../hooks/useScreen";
import { getNavLinkClassName } from "../../utils/activeNavLinkClassName";
import { useUser } from "../Login/hook";
import { IntegrationsSandbox } from "./IntegrationsSandbox";

export const sandboxRoutes = () => (
  <Route element={<SandboxRedirect />}>
    <Route
      path={AppPaths.IntegrationsSandbox}
      element={<IntegrationsSandbox />}
    />
  </Route>
);

export const addSandboxMenu = (items: ItemType[], isOwner: boolean) => {
  items.push({
    key: AppPaths.IntegrationsSandbox,
    icon: <ApiFilled />,
    label: (
      <NavLink
        to={AppPaths.IntegrationsSandbox}
        className={getNavLinkClassName}
      >
        Integrations
      </NavLink>
    ),
  });

  if (isOwner) {
    items.push({
      key: "divider",
      type: "divider",
      className: "nav-divider",
    });
  }
};

export const SandboxAlert: React.FC = () => {
  const { md: isTabletOrLarger } = useScreen();

  return (
    <Alert
      message="Want to give p0 a try with your own data?"
      description={
        <>
          {isTabletOrLarger && (
            <Typography.Paragraph>
              Sign up for a free account. No credit card required. Get started
              in fifteen minutes or less.
            </Typography.Paragraph>
          )}
          <Link to="/create-account">
            <Button type="primary">Create your account</Button>
          </Link>
        </>
      }
      style={{ marginBottom: 24 }}
    />
  );
};

const SandboxRedirect: React.FC = () => {
  const { tenant } = useUser();
  return tenant.state === "found" ? (
    tenant.isSandbox ? (
      <Outlet />
    ) : (
      <Navigate to={AppPaths.Dashboard} />
    )
  ) : (
    <Spin />
  );
};
