import { Descriptions, List } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { sortBy } from "lodash";
import { useContext } from "react";
import { grantNodeToPermissionSet } from "shared/assessment/render";
import { ALL_SCOPE_SENTINEL } from "shared/types/assessment";
import { GrantNode } from "shared/types/assessment/data";
import styled from "styled-components";

import { ConditionDisplay } from "../../cells/Condition";
import {
  IdentityCell,
  IdentityExternalLink,
  IdentityLink,
} from "../../cells/Identity";
import { NodeLink } from "../../cells/NodeLink";
import {
  RiskGroupedPermissionList,
  RiskList,
} from "../../cells/PermissionAggregate";
import { Resource } from "../../cells/Resource";
import { NodeDescriptions } from "../NodeDescriptions";
import { providerTitleElements } from "./shared";

const { Item } = Descriptions;

const DisplayList = styled(List)`
  max-height: 280px;
  padding-right: 10px;
  overflow-y: auto;
`;

export const GrantNodeDisplay: React.FC<{
  node: GrantNode;
}> = ({ node }) => {
  const { scopeKey } = useContext(ScopeContext);

  return (
    <>
      <NodeDescriptions>
        <Item label="Principal">
          <IdentityExternalLink
            data={{ ...node.data, type: node.data.principalType }}
            id={node.data.principal}
          />
        </Item>
        <Item
          label={
            providerTitleElements[node.data.provider ?? ALL_SCOPE_SENTINEL][
              "permissionSet"
            ]
          }
        >
          {grantNodeToPermissionSet(node)}
        </Item>
        <Item label="Resources">
          <DisplayList>
            {node.data.resources.map((r, ix) => (
              <List.Item key={ix}>
                <NodeLink node={{ key: r, type: "resource" }} />
                &nbsp;
                <Resource resource={node} index={ix} />
              </List.Item>
            ))}
          </DisplayList>
        </Item>
        {node.data.condition && typeof node.data.condition === "object" && (
          <Item label="Condition">
            <ConditionDisplay condition={node.data.condition} />
          </Item>
        )}

        <Item label="Risks">
          {scopeKey === ALL_SCOPE_SENTINEL ? (
            <RiskList risks={node.aggregates.risks} />
          ) : (
            <RiskGroupedPermissionList
              permissions={node.aggregates.permissions}
              provider={node.data.provider}
              showControl
            />
          )}
        </Item>

        <Item label="Accessible by">
          <DisplayList>
            {sortBy(node.aggregates.principals, ["principalType", "id"]).map(
              (p, ix) => (
                <List.Item key={ix}>
                  <IdentityLink data={p} type="short" show="grant" />{" "}
                  <IdentityCell identity={p} />
                </List.Item>
              )
            )}
          </DisplayList>
        </Item>
      </NodeDescriptions>
    </>
  );
};
