import { List } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { SpaceBetweenDiv } from "components/divs";
import { capitalize, every, groupBy } from "lodash";
import pluralize from "pluralize";
import { useCallback, useMemo } from "react";
import {
  AssessmentScopeIntegration,
  GroupAssessmentScope,
} from "shared/types/assessment";
import { AssessmentScope, ItemAssessmentScope } from "shared/types/assessment";

import { targetLogo } from "./TargetLogo";

export const scopeLabel: Record<
  AssessmentScope["integration"],
  Partial<Record<AssessmentScope["type"], string>>
> = {
  aws: {
    project: "account",
  },
  gcloud: {
    group: "folder",
    project: "project",
  },
  k8s: {
    project: "cluster",
  },
  workspace: {
    project: "organization",
  },
};

const isItemScopes = (
  scopes: AssessmentScope[]
): scopes is ItemAssessmentScope[] =>
  every(scopes, (s) => s.type === "project");

const isGroupScopes = (
  scopes: AssessmentScope[]
): scopes is GroupAssessmentScope[] => every(scopes, (s) => s.type === "group");

const renderScopes = (ids: string[]) =>
  ids.length > 5 ? (
    <GraphTooltip title={ids.join(", ")}>
      {`${ids.at(0)} and ${ids.length - 1} more`}
    </GraphTooltip>
  ) : (
    ids.join(", ")
  );

export const TargetsList: React.FC<{
  targets: AssessmentScope[];
  actionItem?: React.ReactElement;
}> = ({ targets, actionItem }) => {
  const getTargetLogo = useCallback(
    (integration: AssessmentScopeIntegration) => targetLogo(integration),
    []
  );

  const grouped = useMemo(
    () => Object.entries(groupBy(targets, "integration")),
    [targets]
  ) as [AssessmentScopeIntegration, AssessmentScope[]][];

  const renderTargetItem = useCallback(
    ([integration, items]: [AssessmentScopeIntegration, AssessmentScope[]]) => (
      <List.Item>
        <SpaceBetweenDiv style={{ width: "100%" }}>
          <List.Item.Meta
            avatar={getTargetLogo(integration)}
            title={pluralize(
              capitalize(
                scopeLabel[integration]?.[items[0].type] ?? items[0].type
              ),
              items.length
            )}
            description={
              isItemScopes(items)
                ? renderScopes(items.map((i) => i.id))
                : isGroupScopes(items)
                ? renderScopes(items.map((i) => i.label ?? i.value))
                : "(entire organization)"
            }
          />
          {actionItem && <div>{actionItem}</div>}
        </SpaceBetweenDiv>
      </List.Item>
    ),
    [actionItem, getTargetLogo]
  );

  return <List dataSource={grouped} renderItem={renderTargetItem} />;
};
