import { noop } from "lodash";
import { createContext, useState } from "react";
import { AssessmentScopeIntegration } from "shared/types/assessment";

import { TargetInput } from "../components/TargetSelect";

export const EnvironmentCreationContext = createContext<{
  currentIntegration: AssessmentScopeIntegration | undefined;
  formInputs: TargetInput;
  setFormInputs: React.Dispatch<React.SetStateAction<TargetInput>>;
  setCurrentIntegration: React.Dispatch<
    React.SetStateAction<AssessmentScopeIntegration | undefined>
  >;
}>({
  currentIntegration: undefined,
  formInputs: { targets: { scopes: [], subtractiveScopes: [] } },
  setFormInputs: noop,
  setCurrentIntegration: noop,
});

export const EnvironmentCreationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [currentIntegration, setCurrentIntegration] =
    useState<AssessmentScopeIntegration>();
  const [formInputs, setFormInputs] = useState<TargetInput>({
    targets: { scopes: [], subtractiveScopes: [] },
  });

  return (
    <EnvironmentCreationContext.Provider
      value={{
        currentIntegration,
        formInputs,
        setFormInputs,
        setCurrentIntegration,
      }}
    >
      {children}
    </EnvironmentCreationContext.Provider>
  );
};
