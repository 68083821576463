import { colors } from "styles/variables";

import { CountEntry } from "../components/Inventory/ItemChart";

export const mockNodeCount = 76;

export const mockInventoryData: CountEntry[] = [
  {
    name: "Users",
    value: 13,
    fill: colors.graphColors.darkGreen,
  },
  {
    name: "Groups",
    value: 2,
    fill: colors.graphColors.blue,
  },
  {
    name: "Service Accounts",
    value: 61,
    fill: colors.graphColors.purple,
  },
];
