import { Skeleton, Space, Tag, Typography } from "antd";
import { priorityColor } from "components/Assessment/components/monitor/MonitorSeverity";
import { VerticalDiv } from "components/divs";
import {
  eachWeekOfInterval,
  endOfWeek,
  format,
  isAfter,
  parse,
  startOfDay,
  startOfWeek,
  subDays,
} from "date-fns";
import { map, mapValues, pick, reverse } from "lodash";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
} from "recharts";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { priorityLabels } from "shared/assessment/constants";
import {
  MonitorPriority,
  monitorPriorities,
} from "shared/types/assessment/monitor";
import { widetype } from "shared/util/collections";
import { colors } from "styles/variables";

import { MonitorPriorityCounts } from "../types";

const DATE_FORMAT = "MM/dd";
const YEAR_FORMAT = "MM/dd/yyyy";
const LOOKBACK_DAYS = 90;

type MonitorPriorityData = {
  name: string;
  date?: Date;
} & Record<MonitorPriority, number>;

const getWeeklyData = (
  monitors: MonitorPriorityCounts
): MonitorPriorityData[] => {
  const daily = getDailyData(monitors);
  return bucketWeekly(daily);
};

const getDailyData = (
  monitors: MonitorPriorityCounts
): MonitorPriorityData[] => {
  if (!monitors || !Object.keys(monitors).length) {
    return [];
  }

  const data: MonitorPriorityData[] = [];
  const priorities: Record<MonitorPriority, { [k: number]: number }> = {
    CRITICAL: monitors["CRITICAL"]?.byTimestamp || {},
    HIGH: monitors["HIGH"]?.byTimestamp || {},
    MEDIUM: monitors["MEDIUM"]?.byTimestamp || {},
    LOW: monitors["LOW"]?.byTimestamp || {},
  };

  // Generate daily counts
  for (let i = LOOKBACK_DAYS; i >= 1; i--) {
    const date = startOfDay(subDays(new Date(), LOOKBACK_DAYS - i)).getTime();
    const formattedDate = format(date, DATE_FORMAT);
    const dailyCounts = mapValues(
      priorities,
      (timestamps) => timestamps[date] || 0
    );

    if (Object.values(dailyCounts).some((count) => count > 0)) {
      data.unshift({
        name: formattedDate,
        ...dailyCounts,
        date: new Date(date),
      });
    }
  }

  // Set final totals
  // TODO: Figure out why this is necessary
  const lastEntry = data.pop();
  if (lastEntry) {
    data.push({
      name: lastEntry.name,
      CRITICAL: monitors["CRITICAL"]?.totalCount || 0,
      HIGH: monitors["HIGH"]?.totalCount || 0,
      MEDIUM: monitors["MEDIUM"]?.totalCount || 0,
      LOW: monitors["LOW"]?.totalCount || 0,
    });
  }

  return data;
};

const bucketWeekly = (
  dailyData: MonitorPriorityData[]
): MonitorPriorityData[] => {
  if (!dailyData.length) return [];

  const currentYear = new Date().getFullYear();

  // Add dates and sort
  const withDates = dailyData
    .map((d) => {
      let parsedDate = parse(
        `${d.name}/${currentYear}`,
        YEAR_FORMAT,
        new Date()
      );
      if (isAfter(parsedDate, new Date())) {
        parsedDate = new Date(
          parsedDate.setFullYear(parsedDate.getFullYear() - 1)
        );
      }
      return { ...d, date: parsedDate };
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  // Get week boundaries
  const weeks = eachWeekOfInterval({
    start: startOfWeek(withDates[0].date),
    end: endOfWeek(withDates[withDates.length - 1].date),
  });

  const weeklyData: MonitorPriorityData[] = [];
  let prevWeekEntry: MonitorPriorityData | null = null;
  let i = 0;

  // Process each week
  for (const weekStart of weeks) {
    const weekEnd = endOfWeek(weekStart);
    const currentWeekEntries: typeof withDates = [];

    while (
      i < withDates.length &&
      withDates[i].date.getTime() <= weekEnd.getTime()
    ) {
      if (withDates[i].date.getTime() >= weekStart.getTime()) {
        currentWeekEntries.push(withDates[i]);
      }
      i++;
    }

    if (currentWeekEntries.length > 0) {
      const lastDayThisWeek = currentWeekEntries[currentWeekEntries.length - 1];
      prevWeekEntry = {
        name: format(weekStart, DATE_FORMAT),
        CRITICAL: lastDayThisWeek.CRITICAL,
        HIGH: lastDayThisWeek.HIGH,
        MEDIUM: lastDayThisWeek.MEDIUM,
        LOW: lastDayThisWeek.LOW,
      };
      weeklyData.push(prevWeekEntry);
    } else if (prevWeekEntry) {
      weeklyData.push({
        ...prevWeekEntry,
        name: format(weekStart, DATE_FORMAT),
      });
    } else {
      weeklyData.push({
        name: format(weekStart, DATE_FORMAT),
        CRITICAL: 0,
        HIGH: 0,
        MEDIUM: 0,
        LOW: 0,
      });
    }
  }

  return weeklyData;
};

export const FindingGraph: React.FC<{
  monitors: MonitorPriorityCounts;
  selectedSeverities: Record<MonitorPriority, boolean>;
  isLoading: boolean;
}> = ({ monitors, selectedSeverities, isLoading }) => {
  const weeklyData = getWeeklyData(monitors);

  const trueSeverities = widetype
    .entries(selectedSeverities)
    .reduce((acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    }, [] as MonitorPriority[]);

  const data = weeklyData.map((entry: any) =>
    pick(entry, ["name", ...trueSeverities])
  );

  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const data = monitorPriorities.reduce(
        (acc, curr) => {
          if (payload.some((d) => d.dataKey === curr)) {
            acc.push(payload.find((d) => d.dataKey === curr));
          }
          return acc;
        },
        [] as (Payload<ValueType, NameType> | undefined)[]
      );

      return (
        <VerticalDiv
          style={{
            backgroundColor: colors.neutral["00"],
            padding: "10px",
            gap: "4px",
          }}
        >
          {data.map(
            (p) =>
              p && (
                <Space size="small" key={p.name}>
                  <Tag
                    style={{ height: "1em" }}
                    color={
                      p?.dataKey
                        ? priorityColor[p.dataKey as MonitorPriority].color
                        : ""
                    }
                  />
                  <Typography.Text key={p.name}>
                    {`${p.name}: ${p.value}`}
                  </Typography.Text>
                </Space>
              )
          )}
        </VerticalDiv>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%">
      {isLoading ? (
        <Skeleton active />
      ) : (
        <BarChart
          data={data}
          barSize={100}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis dataKey="name" />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "white" }}
            isAnimationActive={false}
          />
          {reverse(
            map(trueSeverities, (severity) => (
              <Bar
                key={severity}
                dataKey={severity}
                name={priorityLabels[severity]}
                label={priorityLabels[severity]}
                fill={priorityColor[severity].color}
                stackId="a"
              />
            ))
          )}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};
