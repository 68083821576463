import { Segmented, Typography } from "antd";
import { AssessmentSettingsSection } from "components/Assessment/styles";
import { useCallback, useMemo, useState } from "react";

import { MonitorList } from "../../MonitorList";

const { Title } = Typography;

const ITEM_TITLE_LEVEL = 5;

const EditMonitorsSection: React.FC = () => {
  const [showArchived, setShowArchived] = useState(false);
  const actionTypes = useMemo(() => {
    return showArchived ? ["edit", "unarchive"] : ["edit", "archive"];
  }, [showArchived]);

  const handleArchiveToggle = useCallback((value: number | string) => {
    setShowArchived(value === "archived");
  }, []);

  return (
    <AssessmentSettingsSection>
      <Title level={ITEM_TITLE_LEVEL}>Monitors</Title>
      <Segmented
        options={[
          { label: "Active", value: "active" },
          { label: "Archived", value: "archived" },
        ]}
        onChange={handleArchiveToggle}
      />
      <MonitorList
        actionTypes={actionTypes}
        showFindings={false}
        customOnly
        showArchived={showArchived}
      />
    </AssessmentSettingsSection>
  );
};

const EnvironmentMonitorPage: React.FC = () => (
  <AssessmentSettingsSection>
    <EditMonitorsSection />
  </AssessmentSettingsSection>
);

export default EnvironmentMonitorPage;
