import stringify from "json-stable-stringify";
import { findIndex } from "lodash";

import { Cancellation } from "../types/async";
import { mapWith } from "../util/collections";
import { eventYieldingFor } from "../util/sleep";
import { Bitset } from "./bitset";
import { DigramBitset, digrams } from "./digram";
import { DslMapping } from "./dsl";
import { dfs } from "./graph";
import { GraphSearchSettings } from "./settings";
import {
  ConnectedNode,
  DirectedGraph,
  Direction,
  Directions,
  EmptyBoost,
  GraphOf,
  Node,
  Path,
} from "./types";

export type NodePredicate<G extends object> = (
  node: Node<G, keyof G>
) => boolean;

export type PathTerm<G extends object> = {
  /** The predicate function matching a path end node */
  predicate: NodePredicate<G>;
  /** The keyword for this node match
   *
   * The keyword is only the text component of the match, and
   * excludes all control characters.
   *
   * Used for search boost only; if this is incorrect then
   * boosted searches may falsely return negative matches.
   */
  keyword: string;
  /** If included in term, the key type for this node match
   *
   * The key type is the value of any type constraint in the term.
   *
   * Used for search boost only; if this is incorrect then
   * boosted searches may falsely return negative matches.
   */
  keytype: string | undefined;
  /** Indicates if failing to match this term before it is
   * reached should abort the search, works only when it is the
   * first or last in a via path
   */
  abortOnTypeMismatch: boolean;
};

export type NodeSearch<G extends object> = {
  /** The search term for this node match
   *
   * E.g. `keyword` or `type:keyword`, but not `via:->keyword`
   */
  term: string;
  /** Values of each of the nodes in the via path */
  pathTerms: PathTerm<G>[];

  /** Whether to invert this match
   *
   * If true, search will return paths that do not match
   * search.
   */
  invert?: boolean;
  /** If true, will stop on a missed type match */
  abortOnTypeMismatch?: boolean;
  /** Query parse warnings */
  warnings?: string[];
};

const DEFAULT_MAX_PATHS = 20;

/** Node search that matches all nodes */
export const TRUE_SEARCH: NodeSearch<any> = {
  term: "",
  pathTerms: [
    {
      predicate: () => true,
      keyword: "",
      keytype: undefined,
      abortOnTypeMismatch: false,
    },
  ],
};

/** Adds type search boost to a graph */
export const addTypeBoost = <G extends object>(graph: DirectedGraph<G>) => {
  for (const direction of Directions) {
    dfs(graph, direction, {
      init: (node) => {
        const memo = new Set<symbol>();
        memo.add(Symbol.for(node.type as string));
        return memo;
      },
      combine: (left, right) => {
        const memo = new Set(left.values());
        for (const symbol of right.values()) {
          memo.add(symbol);
        }
        return memo;
      },
      finalize: (node, memo) => {
        // Do not override keyword boost
        node.boost = node.boost ?? EmptyBoost();
        node.boost[direction].types = memo;
      },
    });
  }
};

/** Adds digram search boost to a graph */
export const addDigrams = <G extends object>(
  graph: DirectedGraph<G>,
  mapping: DslMapping<G>
) => {
  for (const direction of Directions) {
    dfs(graph, direction, {
      init: (node) => {
        const mapped = mapping[node.type];
        const keyTerms = mapped?.keys?.(node) ?? [node.key];
        const nodeAttributeTerms = Object.values(node.data ?? {}).map(String);
        const mappedAttributeTerms = mapped?.attributes
          ? mapWith(mapped.attributes, function* ([_, a]) {
              for (const v of a(node)) yield v;
            })
          : [];
        const terms = [
          ...keyTerms,
          ...nodeAttributeTerms,
          ...mappedAttributeTerms,
        ];
        return DigramBitset(terms);
      },
      combine: Bitset.merge,
      finalize: (node, memo) => {
        // Do not override keytype boost
        node.boost = node.boost ?? EmptyBoost();
        node.boost[direction].digrams = memo;
      },
    });
  }
};

/** Returns subgraph matching a reachability criterion
 *
 * Returns all nodes that match the `from` predicate, that
 * are connected to at least one node that matches the `to` predicate.
 *
 * May optionally search with an intermediate constraint, requiring
 * paths to traverse through matching nodes.
 *
 * In graph-database query languages this performs
 *
 *   SELECT (from)->(via)->(to)
 *   WHERE ...
 *
 * Where each node is subject to its respective predicate. Unlike a graph
 * database, this only returns the `from` nodes, rather than the matching
 * paths. (This is done as a performance optimization).
 *
 * If the `invert` parameter is passed, will instead only return `from` nodes
 * where the `to` nodes are unreachable.
 */
// Works by first filtering graph to nodes matching left predicate. Then
// performs a DFS for each of those starting nodes. DFS results are cached
// on a per-vertex basis, allowing for lower algorithmic complexity.
export const reachable = async <D extends DirectedGraph<any>>(
  graph: D,
  from: NodePredicate<GraphOf<D>>,
  query: NodeSearch<GraphOf<D>>
): Promise<D> =>
  ({
    nodes: (await paths(graph, from, query, { findFirst: true })).map(
      ({ node }) => node
    ),
  } as D);

type Match<D extends DirectedGraph<any>> = {
  node: ConnectedNode<GraphOf<D>, keyof GraphOf<D>>;
  paths: Path<GraphOf<D>>[];
};

type InnerResult<D extends DirectedGraph<any>> = [boolean, Path<GraphOf<D>>];

/** Returns all nodes matching a reachability criterion, together with
 *  all matching paths.
 *
 * Paths are found on a directed graph subject to two predicates, "target"
 * and "to", that define path starts and ends. Paths are directed from
 * start to end, as follows:
 *
 *   "target" -> ... -> ... -> "to"
 *
 * By default, will also return all "to" nodes that reach the "target" node:
 *
 *   "to" -> ... -> ... -> "target"
 *
 * In addition, one or more "via" predicates can be attached; in this case paths
 * must traverse through nodes matching these predicates:
 *
 *   "target" -> ... -> "via" -> ... -> "to"
 *
 * If "invert" is true, the returned paths are all paths descending from
 * "from"-matching nodes that have no path reaching the "to"
 * predicate.
 *
 * If "findFirst" is true, will only return the first matching (or not matching)
 * path, for each "from" node.
 */
export const paths = async <D extends DirectedGraph<any>>(
  /** The graph */
  graph: D,
  /** The predicate matching all path starting nodes */
  target: NodePredicate<GraphOf<D>>,
  /** The predicate matching all (or not matching all if "invert") path ending nodes */
  query: NodeSearch<GraphOf<D>>,
  /** Path-search options */
  options?: Partial<GraphSearchSettings> & {
    /** Cancellation object; will abort search if canceled */
    cancellation?: Cancellation;
    /** If true, only returns the first matching (or not matching if "invert") path */
    findFirst?: boolean;
    /** Valid directions to search for query matches from the targets */
    directions?: readonly Direction[];
    /** Maximum number of milliseconds to block the process before yielding */
    maxOccupancyMs?: number;
  }
): Promise<Match<D>[]> => {
  const { cancellation, maxOccupancyMs } = options ?? {};
  const directions = options?.directions ?? Directions;
  const fromNodes = graph.nodes.filter(target);
  // Mapping from a node key to all known descendant paths of this node
  const known: Record<string, InnerResult<D>[]> = {};
  // Record via matches in a bitset; note max 31 via items
  const constraintReq = 2 ** (query.pathTerms.length - 1) - 1;

  // Visit time is O(n)
  function* traverse(
    root: ConnectedNode<GraphOf<D>, keyof GraphOf<D>>,
    direction: Direction
  ): Generator<InnerResult<D>> {
    const pathTerms =
      direction === "parents"
        ? [...query.pathTerms].reverse()
        : query.pathTerms;

    const back = pathTerms[pathTerms.length - 1];

    const predicate = back.predicate;
    const keyword = back.keyword;
    const keytype = back.keytype;

    const keywordDigrams = keyword ? digrams(keyword) : undefined;
    const keytypeSymbol = keytype ? Symbol.for(keytype) : undefined;

    const via = pathTerms.slice(0, -1).map((v) => v.predicate);

    const pathKeytypes = pathTerms.slice(0, -1).map((v) => v.keytype);
    const pathAborts = pathTerms.slice(0, -1).map((v) => v.abortOnTypeMismatch);

    // Prevent cycles by aborting if we re-visit a node.
    const visited = new Set<string>();

    // This is the DFS visitation stack. DFS proceeds until the stack is empty.
    //
    // Each stack element contains a complete path from the root node to the currently
    // visited node. This is used for two purposes:
    // 1. To return the entire matching path when a match (or lack thereof) is discovered.
    // 2. To build the `known` matching cache. When a match (or lack thereof) is discovered,
    //    we will update the cache for every node in the path.
    const stack = [[{ node: root, met: 0 }]];

    // Creates a key used to avoid recalculation of paths. If a path is known to match, or not match,
    // a query, track it in the "known" data structure, keyed by the node after which the path is known.
    const nodeKey = (node: typeof root, met: number) =>
      stringify({
        key: node.key,
        type: node.type,
        met,
        direction,
      });

    // Updates the "known" data structure, marking paths as either known or unknown.
    const updateKnown = (head: (typeof stack)[number], isMatch: boolean) => {
      for (let ix = 0; ix < head.length; ix++) {
        const key = nodeKey(head[ix].node, head[ix].met);
        known[key] ||= [];
        known[key].push([
          isMatch,
          // +1 to avoid double-counting nodes
          head.slice(ix + 1, head.length).map((h) => h.node),
        ]);
      }
    };

    // Manually tail-call optimize DFS to avoid OOM
    for (let head = stack.pop(); true; head = stack.pop()) {
      if (!head) return;
      const current = head.at(-1);
      if (!current) return;

      const met = current.met;
      const path = head.map((h) => h.node);

      const key = nodeKey(current.node, met);
      const boost = current.node.boost?.[direction];

      // Performance optimization: if we've already proved that this graph path
      // either does or does not match, return result. This reduces visit time
      // from O(n^2) to O(n). Note that the key must include the "via" constraint
      // value, otherwise previous evaluations may pollute this value.
      if (known[key]) {
        for (const [isMatch, tail] of known[key]) {
          yield [isMatch, [...path, ...tail]];
        }
        continue;
      }

      // Avoid cycles: we've already returned paths for this node
      if (visited.has(key)) {
        continue;
      }
      visited.add(key);

      // This needs to happen before the query predicate
      // check. Otherwise, we may return paths that do not account for the
      // stopOn condition
      if (options?.stopOn?.includes(current.node.type as string)) {
        continue;
      }

      const firstUnmetConstraint = findIndex(
        [...Array(via.length).keys()],
        (i) => (met & (1 << i)) === 0
      );

      const nextKeytype =
        firstUnmetConstraint == -1
          ? keytype
          : pathKeytypes[firstUnmetConstraint];

      const nextAbort =
        firstUnmetConstraint == -1
          ? back.abortOnTypeMismatch
          : pathAborts[firstUnmetConstraint];

      // Check if we've met the next constraint if there is one
      const nextConstraintMet =
        firstUnmetConstraint == -1
          ? false
          : via[firstUnmetConstraint](current.node);

      // If so update our met for the next node
      const nextConstraintEval = nextConstraintMet
        ? met | (1 << firstUnmetConstraint)
        : met;

      const allConstraintsMet =
        firstUnmetConstraint == -1 || nextConstraintEval == constraintReq;

      // If we have met all the constraints now we can check if the predicate matches
      if (allConstraintsMet && predicate(current.node)) {
        updateKnown(head, true);
        yield [true, path];
        continue;
      }

      if (
        // If this is a leaf node, this path does not match
        current.node[direction].length === 0 ||
        // Or if type matches, we abort on type keyword mismatches
        (!!nextKeytype &&
          nextAbort &&
          current.node.type === nextKeytype &&
          !nextConstraintMet) ||
        // Or if type query does not appear in any children
        (keytypeSymbol && boost?.types && !boost.types.has(keytypeSymbol)) ||
        // Or if keyword does not appear in any children
        (keywordDigrams &&
          boost?.digrams &&
          !boost.digrams.hasAll(keywordDigrams))
      ) {
        // At this point we can't assert that parents are inverse matches, so only mark the
        // current node as not matching
        updateKnown(head.slice(-1), false);
        yield [false, path];
        continue;
      }

      // Otherwise DFS
      for (const node of current.node[direction]) {
        stack.push([...head, { node, met: nextConstraintEval }]);
      }
    }
  }

  const out: Match<D>[] = [];
  const maxPaths = options?.findFirst
    ? 1
    : options?.maxPaths ?? DEFAULT_MAX_PATHS;
  await eventYieldingFor(
    fromNodes,
    (node) => {
      const paths: Path<GraphOf<D>>[] = [];
      let hasMatch = false;
      for (const direction of directions) {
        for (const [isMatch, path] of traverse(node, direction)) {
          hasMatch ||= isMatch;
          if (isMatch === !query.invert) {
            paths.push(direction === "children" ? path : path.reverse());
          }
          if (!query.invert && paths.length >= maxPaths) break;
        }
      }
      if (hasMatch !== !!query.invert && paths.length > 0) {
        out.push({ node, paths });
      }
    },
    { cancellation, maxOccupancyMs }
  );
  return out;
};
