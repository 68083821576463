import { addEnvironmentToPath } from "components/Assessment/environment";
import { useNavigateWithEnv } from "components/Assessment/hooks/useNavigateWithEnv";
import { useLocalStorage } from "hooks/useLocalStorage";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { Environment, EnvironmentDocsContext } from "./EnvironmentDocsContext";

export const ENVIRONMENT_LOCAL_STORAGE_KEY = "selected-environment";
export type StoredEnvironment = { id: string };

type EnvironmentContext = {
  all: Map<string, Environment>;
  hasEnvironments: boolean;
  selected: Environment | undefined;
};

export const EnvironmentContext = createContext<EnvironmentContext>({
  all: new Map(),
  hasEnvironments: false,
  selected: undefined,
});

EnvironmentContext.displayName = "EnvironmentContext";

export const EnvironmentProvider: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const { all, hasEnvironments, isLoading } = useContext(
    EnvironmentDocsContext
  );

  const [searchParams] = useSearchParams();

  const navigate = useNavigateWithEnv();
  const location = useLocation();
  const [storedEnv, _] = useLocalStorage<StoredEnvironment>(
    ENVIRONMENT_LOCAL_STORAGE_KEY
  );
  useEffect(() => {
    const env = searchParams.get("environment");
    if ((!env || !all.has(env)) && all.size > 0) {
      const defaultEnvId =
        storedEnv && all.has(storedEnv.id)
          ? storedEnv.id
          : all.keys().next().value;
      if (defaultEnvId) {
        navigate(addEnvironmentToPath(location.pathname, defaultEnvId), {
          replace: true,
        });
      }
    }
  }, [all, searchParams, navigate, location, storedEnv]);

  const selected = useMemo(() => {
    const envId = searchParams.get("environment");
    return envId ? all.get(envId) : undefined;
  }, [all, searchParams]);

  const render = !isLoading && (!hasEnvironments || !!selected);
  return (
    <EnvironmentContext.Provider value={{ all, hasEnvironments, selected }}>
      {render ? children : null}
    </EnvironmentContext.Provider>
  );
};
