import { DeleteOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Input,
  List,
  Select,
  Typography,
  message,
} from "antd";
import { groupBy } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import {
  Control,
  Controller,
  ControllerProps,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { ASSESSMENT_TARGET_REGEX } from "shared/assessment/constants";
import {
  AssessmentScheduleUnits,
  AssessmentScope,
  AssessmentScopeIntegration,
  IamAssessment,
} from "shared/types/assessment";
import styled from "styled-components";

import { useAuthFetch } from "../../Login/hook";
import { EnvironmentCreationContext } from "../contexts/EnvironmentCreationContext";
import { useSelectedEnvironment } from "../hooks/useSelectedEnvironment";
import NewAssessmentIntegrationSelect from "./NewAssessmentIntegrationSelect";
import { TargetInput, TargetSelect } from "./TargetSelect";
import { TargetsList } from "./Targets";

export type FrequencyInputs = {
  frequencyUnit: AssessmentScheduleUnits;
  frequencyAmount: number;
};

type CreateFormInputs = FrequencyInputs &
  TargetInput & {
    name: string;
    runImmediately: boolean;
  };

const frequencyUnitOptions: Record<AssessmentScheduleUnits, string> = {
  days: "Days",
  weeks: "Weeks",
  months: "Months",
};

const FormInputsList = () => {
  const { formInputs, setFormInputs } = useContext(EnvironmentCreationContext);
  const { scopes, subtractiveScopes } = formInputs.targets;
  const integrationScopes = groupBy(scopes, "integration");
  const removeIntegration = useCallback(
    (integration: AssessmentScopeIntegration) => () => {
      const filterScopes = (scopes: AssessmentScope[]) => {
        return scopes.filter((scope) => scope.integration !== integration);
      };

      const resetScopes = filterScopes(scopes);
      const resetSubtractiveScopes = filterScopes(subtractiveScopes);
      setFormInputs({
        targets: {
          scopes: resetScopes,
          subtractiveScopes: resetSubtractiveScopes,
        },
      });
    },
    [scopes, setFormInputs, subtractiveScopes]
  );

  const renderListItem = useCallback(
    (item: AssessmentScopeIntegration) => {
      const currScopes = integrationScopes[item];
      return (
        <TargetsList
          targets={currScopes}
          actionItem={
            <Button type="link" onClick={removeIntegration(item)}>
              <DeleteOutlined />
            </Button>
          }
        />
      );
    },
    [integrationScopes, removeIntegration]
  );
  const usedIntegrations = Object.keys(
    integrationScopes
  ) as AssessmentScopeIntegration[];
  return (
    <>
      <Typography.Title level={5}> Assessed targets</Typography.Title>

      <List dataSource={usedIntegrations} renderItem={renderListItem} />
    </>
  );
};

export const NewEnvironmentForm: React.FC<{
  setFormOpen: (open: boolean) => void;
  installedIntegrations: AssessmentScopeIntegration[];
}> = ({ setFormOpen, installedIntegrations }) => {
  const authFetch = useAuthFetch();
  const [submitting, setSubmitting] = useState(false);
  const { currentIntegration, formInputs } = useContext(
    EnvironmentCreationContext
  );

  const [_, setSelectedEnvironment] = useSelectedEnvironment();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CreateFormInputs>({
    defaultValues: {
      name: "",
      frequencyUnit: "days",
      frequencyAmount: 1,
      targets: { scopes: [], subtractiveScopes: [] },
      runImmediately: true,
    },
  });

  const availableIntegrations = useMemo(
    () =>
      installedIntegrations.filter(
        (integration) =>
          !formInputs.targets.scopes.some(
            (scope) => scope.integration === integration
          )
      ),
    [formInputs.targets, installedIntegrations]
  );

  const runImmediately = watch("runImmediately");

  const onSubmitCreateForm: SubmitHandler<CreateFormInputs> = useCallback(
    async (data) => {
      setSubmitting(true);
      const environment: IamAssessment = {
        name: data.name,
        frequency: {
          unit: data.frequencyUnit,
          amount: data.frequencyAmount,
        },
        targets: formInputs.targets.scopes,
        subtractiveTargets: formInputs.targets.subtractiveScopes,
      };
      const response = await authFetch("assessment", {
        method: "POST",
        json: {
          assessment: environment,
          runImmediately: data.runImmediately,
        },
      });
      if (response?.ok) {
        setFormOpen(false);
        const body = await response.json();
        setSelectedEnvironment(body.assessmentId);
        message.info(`Redirecting to ${data.name}`);
      }
      setSubmitting(false);
    },
    [
      formInputs.targets.scopes,
      formInputs.targets.subtractiveScopes,
      authFetch,
      setFormOpen,
      setSelectedEnvironment,
    ]
  );

  const onSubmit = useMemo(
    () => handleSubmit(onSubmitCreateForm),
    [handleSubmit, onSubmitCreateForm]
  );

  const renderAssessmentName = useCallback<
    ControllerProps<CreateFormInputs, "name">["render"]
  >(
    ({ field }) => (
      <>
        {" "}
        <Input
          placeholder="Environment Name"
          {...field}
          aria-invalid={!!errors.name}
        />
        {errors.name && (
          <span style={{ color: "red" }} role="alert">
            Name is required and can contain alphanumeric characters,
            whitespace, -, _, and .
          </span>
        )}{" "}
      </>
    ),
    [errors]
  );

  const renderFrequencyAmount = useCallback<
    ControllerProps<CreateFormInputs, "frequencyAmount">["render"]
  >(({ field: { ref: _ref, ...field } }) => <FrequencyAmount {...field} />, []);

  const renderFrequencyUnit = useCallback<
    ControllerProps<CreateFormInputs, "frequencyUnit">["render"]
  >(({ field: { ref: _ref, ...field } }) => <FrequencyUnit {...field} />, []);

  const renderRunImmediately = useCallback<
    ControllerProps<CreateFormInputs, "runImmediately">["render"]
  >(
    ({ field: { onChange, value, ...field } }) => (
      <Checkbox
        {...field}
        checked={value}
        // Already inside useCallback
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e) => onChange(e.target.checked)}
        style={{ width: "100%", marginBottom: "20px" }}
      >
        Start scheduling assessments immediately
      </Checkbox>
    ),
    []
  );

  return (
    <form onSubmit={onSubmit}>
      <Controller
        name="name"
        rules={{
          required: true,
          pattern: ASSESSMENT_TARGET_REGEX,
          minLength: 1,
          maxLength: 100,
        }}
        control={control}
        render={renderAssessmentName}
      />
      <FrequencyInputGroup>
        <Controller
          name="frequencyAmount"
          control={control}
          render={renderFrequencyAmount}
        />
        <Controller
          name="frequencyUnit"
          control={control}
          render={renderFrequencyUnit}
        />
      </FrequencyInputGroup>
      {availableIntegrations.length > 0 && (
        <NewAssessmentIntegrationSelect
          availableIntegrations={availableIntegrations}
        />
      )}
      {currentIntegration && availableIntegrations.length > 0 && (
        <TargetSelect
          control={control as any as Control<TargetInput>}
          currentIntegration={currentIntegration}
        />
      )}
      {formInputs.targets.scopes.length > 0 && <FormInputsList />}
      <Controller
        name="runImmediately"
        control={control}
        render={renderRunImmediately}
      />
      {!runImmediately && (
        <Alert
          type="warning"
          message="This assessment will not be automatically run. You can enable runs later or run this assessment manually."
          style={{ marginBottom: "20px" }}
        />
      )}
      <div>
        <Button
          type="primary"
          htmlType="submit"
          loading={submitting}
          disabled={
            availableIntegrations.length === installedIntegrations.length
          }
        >
          Create Environment
        </Button>
      </div>
    </form>
  );
};

const StyledFrequencyInputGroup = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 12px;
  input {
    margin-bottom: 0;
  }
`;

export const FrequencyAmount: React.FC<{
  onChange: (...event: any[]) => void;
}> = ({ onChange, ...props }) => {
  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange(+e.target.value),
    [onChange]
  );
  return (
    <Input
      type="number"
      placeholder="Amount"
      {...props}
      onChange={handleChange}
    />
  );
};

export const FrequencyUnit: React.FC<{
  onChange: (...event: any[]) => void;
}> = ({ onChange, ...props }) => {
  return (
    <Select<AssessmentScheduleUnits> {...props} onChange={onChange}>
      {Object.keys(frequencyUnitOptions).map((unit) => (
        <Select.Option value={unit} key={unit}>
          {frequencyUnitOptions[unit as AssessmentScheduleUnits]}
        </Select.Option>
      ))}
    </Select>
  );
};

export const FrequencyInputGroup: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <>
    <Typography.Paragraph style={{ marginBottom: "6px" }}>
      How often should this assessment run?
    </Typography.Paragraph>
    <StyledFrequencyInputGroup>{children}</StyledFrequencyInputGroup>
  </>
);
