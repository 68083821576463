import { useMemo } from "react";
import { Risk } from "shared/types/catalog";
import { GreyTag, RedTag } from "styles/StyledTags";

type AggregatedRisk = Pick<Risk, "id" | "name" | "score">;

const isCritical = (risk: AggregatedRisk) => risk.score === "CRITICAL";

export const riskSorter = (left: AggregatedRisk[], right: AggregatedRisk[]) => {
  const critRank =
    left.filter(isCritical).length - right.filter(isCritical).length;
  if (critRank !== 0) return critRank;
  return left.length - right.length;
};

/** Renders a list of (potentially remediated) risks
 *
 * When all risks are not remediated by the currently
 * selected / hovered recommendations, renders an expandable list.
 * When collapsed, the list shows:
 * - A tag showing the number of critical risks
 * - Text showing the number of non-critical risks
 * When expanded, the list shows:
 * - A {@link RiskLink} for each risk
 *
 * When risks are remediated, the collapsed list will show the
 * total number of risks as struck through, along side the number of
 * unremediated risks. The expanded list will render a {@link RiskLink}
 * with the respective `remediated` prop set for each risk.
 */
export const RiskAggregate: React.FC<{
  risks: AggregatedRisk[];
}> = ({ risks }) => {
  const counts = useMemo(
    () => ({
      allCriticalCount: risks.filter(isCritical).length,
      allOtherCount: risks.filter((r) => !isCritical(r)).length,
    }),
    [risks]
  );

  return !risks || risks.length === 0 ? (
    <div>(No&nbsp;known)</div>
  ) : (
    <div style={{ display: "grid", gap: "0.1em" }}>
      {counts.allCriticalCount > 0 ? (
        <RedTag
          style={{ width: "100%" }}
          content={`${counts.allCriticalCount} critical`}
        />
      ) : (
        <GreyTag content={`${counts.allCriticalCount} critical`} />
      )}
      <div style={{ fontSize: "smaller" }}>
        and&nbsp;
        {counts.allOtherCount}&nbsp;more
      </div>
    </div>
  );
};
