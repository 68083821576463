import { Descriptions, Typography } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import pluralize from "pluralize";
import { useContext } from "react";
import { ALL_SCOPE_SENTINEL } from "shared/types/assessment";
import { CredentialNode } from "shared/types/assessment/data/credential";

import { ConsumersList } from "../../cells/ConsumersList";
import { CredentialDisplay } from "../../cells/Credential";
import { IdentityCell, IdentityLink } from "../../cells/Identity";
import { LastAuthenticated } from "../../cells/LastAuthenticated";
import { LastRotated } from "../../cells/LastRotated";
import {
  RiskGroupedPermissionList,
  RiskList,
} from "../../cells/PermissionAggregate";
import { NodeDescriptions } from "../NodeDescriptions";

const { Item } = Descriptions;

export const CredentialNodeDisplay: React.FC<{
  node: CredentialNode;
}> = ({ node }) => {
  const { provider, scopeKey } = useContext(ScopeContext);
  const [identity] = node.aggregates.identity;
  const grantCount = node.aggregates.grants.length;
  return (
    <NodeDescriptions>
      <Item label="Credential">
        <CredentialDisplay credential={node.data} id={node.key} />
      </Item>
      <Item label="Principal">
        <IdentityLink data={identity} type="short" show="grant" />{" "}
        <IdentityCell identity={identity} />
      </Item>
      <Item label="Last Used">
        <LastAuthenticated
          identity={identity}
          last={node.data.lastAuthnTime}
          lookback={node.data.maxAuthnLookbackDays}
        />
      </Item>
      <Item label="Used By">
        <ConsumersList
          consumers={node.aggregates.consumers}
          lastAuthnTime={node.data.lastAuthnTime}
        />
      </Item>
      <Item label="Last Rotated">
        <LastRotated credential={node.data} />
      </Item>
      <Item label="Grants">
        <Typography.Paragraph>
          This identity can exercise {grantCount}{" "}
          {pluralize("grant", grantCount)}
        </Typography.Paragraph>
        <IdentityLink data={identity} show="identity" />
      </Item>
      {provider && (
        <Item label="Risks">
          {scopeKey === ALL_SCOPE_SENTINEL ? (
            <RiskList risks={node.aggregates.risks} />
          ) : (
            <RiskGroupedPermissionList
              permissions={node.aggregates.permissions}
              provider={provider}
              showControl
            />
          )}
        </Item>
      )}
    </NodeDescriptions>
  );
};
