import { Menu } from "antd";
import { EnvironmentDocsContext } from "components/Environment/contexts/EnvironmentDocsContext";
import { useContext, useMemo } from "react";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { AppPaths } from "shared/routes/constants";

import { SettingsPaths } from "../components/Settings/paths";

const item = (path: string, label: string) => ({
  key: path,
  label: <NavLink to={path}>{label}</NavLink>,
});

export const Settings: React.FC<object> = () => {
  const { pathname } = useLocation();

  const selected = useMemo(
    () => pathname.split("/").at(-1) ?? "settings",
    [pathname]
  );

  const { hasEnvironments } = useContext(EnvironmentDocsContext);

  if (!hasEnvironments) {
    return <Navigate to={`../${AppPaths.Dashboard}`} />;
  }

  const navItems = [
    item(SettingsPaths.EnvironmentStatus, "Status"),
    item(SettingsPaths.EnvironmentConfiguration, "Configuration"),
    item(SettingsPaths.Monitors, "Monitors"),
  ];

  return (
    <>
      <Menu
        mode="horizontal"
        items={navItems}
        selectedKeys={[selected]}
        style={{ marginBottom: "1em" }}
      />
      <Outlet />
    </>
  );
};
