import { EnvironmentContext } from "components/Environment/contexts/EnvironmentContext";
import { limit, orderBy, where } from "firebase/firestore";
import { IAM_ASSESSMENTS_COLLECTION } from "firestore/constants";
import { Dictionary } from "lodash";
import { createContext, useContext, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AccessLogsInfo, IamAssessment } from "shared/types/assessment";
import {
  AssessmentJob,
  TerminalAssessmentStatuses,
} from "shared/types/assessment/job";
import { isa } from "shared/types/is";

import {
  FirestoreDoc,
  useFirestoreCollection,
  useFirestoreDoc,
} from "../../../providers/FirestoreProvider";
import { useAuthFetch } from "../../Login/hook";

type SelectedEnvironment = {
  accessLogs: Dictionary<AccessLogsInfo>;
  assessment: {
    doc: FirestoreDoc<IamAssessment> | undefined;
    hasSchedule: boolean;
    isScheduled: boolean;
    loading: boolean;
  };
  current: {
    doc: FirestoreDoc<AssessmentJob> | undefined;
    loading: boolean;
    isCompleted: boolean;
    isInProgress: boolean;
  };
  last: {
    doc: FirestoreDoc<AssessmentJob> | undefined;
    loading: boolean;
  };
  runAssessmentNow: () => void;
};

const defaultSelectedAssessmentValue: SelectedEnvironment = {
  accessLogs: {},
  assessment: {
    doc: undefined,
    loading: true,
    hasSchedule: false,
    isScheduled: false,
  },
  current: {
    doc: undefined,
    loading: true,
    isCompleted: false,
    isInProgress: false,
  },
  last: { doc: undefined, loading: true },
  runAssessmentNow: () => {},
};

export const SelectedEnvironmentContext = createContext<SelectedEnvironment>(
  defaultSelectedAssessmentValue
);

export const SelectedEnvironmentProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const fallback = (
    <SelectedEnvironmentContext.Provider value={defaultSelectedAssessmentValue}>
      {children}
    </SelectedEnvironmentContext.Provider>
  );

  const { hasEnvironments } = useContext(EnvironmentContext);
  return hasEnvironments ? (
    <ErrorBoundary fallback={fallback}>
      <Provider>{children}</Provider>
    </ErrorBoundary>
  ) : (
    fallback
  );
};

const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const environments = useContext(EnvironmentContext);
  const authFetch = useAuthFetch();

  const assessmentId = environments.selected?.assessmentId;
  const assessmentPath = assessmentId
    ? `${IAM_ASSESSMENTS_COLLECTION}/${assessmentId}`
    : undefined;
  const assessmentDoc = useFirestoreDoc<IamAssessment>(assessmentPath, {
    live: true,
    tenantAware: true,
  });

  const { docs: currentDocs } = useFirestoreCollection<AssessmentJob>(
    "job-state",
    {
      live: true,
      queryConstraints: [
        where("assessmentId", "==", assessmentId ?? ""),
        orderBy("lastUpdatedTimestamp", "desc"),
        limit(1),
      ],
      tenantAware: true,
    }
  );

  const { docs: lastCompletedDocs } = useFirestoreCollection<AssessmentJob>(
    "job-state",
    {
      live: true,
      queryConstraints: [
        where("assessmentId", "==", assessmentId ?? ""),
        where("status", "==", "COMPLETED"),
        orderBy("lastUpdatedTimestamp", "desc"),
        limit(1),
      ],
      tenantAware: true,
    }
  );

  // Turn off liveness here to save Firestore load. Note that this can lead to out-of-date or stale access-log warnings.
  // However, these are unlikely to change while the user is viewing the page.
  const { docs: accessLogsDocs } = useFirestoreCollection<AccessLogsInfo>(
    assessmentPath ? `${assessmentPath}/access-logs` : undefined,
    { live: false, tenantAware: true }
  );
  const accessLogs = useMemo(
    () =>
      Object.fromEntries(
        (accessLogsDocs ?? []).map((doc) => [doc.id, doc.data])
      ),
    [accessLogsDocs]
  );

  const value: SelectedEnvironment = useMemo(
    () => ({
      accessLogs,
      assessment: {
        doc: assessmentDoc.doc,
        loading: assessmentDoc.loading,
        hasSchedule: assessmentDoc.doc?.data?.frequency !== undefined,
        isScheduled:
          !assessmentDoc.doc?.data?.frequency?.disabled &&
          // TODO: fix with migration
          assessmentDoc.doc?.data?.frequency?.anchorDate !== undefined,
      },
      current: {
        doc: currentDocs?.[0],
        loading: currentDocs === undefined,
        isCompleted:
          currentDocs?.length === 0 ||
          Boolean(
            currentDocs?.[0] &&
              isa(TerminalAssessmentStatuses, currentDocs[0].data.status)
          ),
        isInProgress: Boolean(
          currentDocs?.[0] &&
            !isa(TerminalAssessmentStatuses, currentDocs[0].data.status)
        ),
      },
      last: {
        doc: lastCompletedDocs?.[0],
        loading: lastCompletedDocs === undefined,
      },
      runAssessmentNow: () => {
        authFetch(`assessment/${assessmentId}/run`, {
          method: "POST",
        });
      },
    }),
    [
      accessLogs,
      assessmentDoc.doc,
      assessmentDoc.loading,
      currentDocs,
      lastCompletedDocs,
      assessmentId,
      authFetch,
    ]
  );

  return (
    <SelectedEnvironmentContext.Provider value={value}>
      {children}
    </SelectedEnvironmentContext.Provider>
  );
};
