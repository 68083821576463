import { LinkOutlined } from "@ant-design/icons";
import { Card, Skeleton, Space, Typography } from "antd";
import { FindingsContext } from "components/Assessment/contexts/FindingsContext";
import { useInstalledIntegrations } from "components/Assessment/hooks/useInstalledIntegrations";
import { CreateEnvironment } from "components/Assessment/pages/CreateEnvironment";
import { EnvironmentSelector } from "components/Environment/components/EnvironmentSelector";
import { EnvironmentContext } from "components/Environment/contexts/EnvironmentContext";
import { useRequests } from "components/Jit/Requests/hooks/useRequests";
import { VerticalDiv } from "components/divs";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useScreen } from "hooks/useScreen";
import { useCallback, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { RequestStatuses } from "shared/types/request-status";
import { colors } from "styles/variables";

import { FindingsBySeverity } from "./components/FindingsBySeverity";
import { ItemChart } from "./components/Inventory/ItemChart";
import { JitPanels } from "./components/Jit/JitPanel";
import { GrayTitle } from "./components/common/GrayTitle";
import { ZeroState } from "./components/common/ZeroState";

const ZERO_STATE_LOCAL_STORAGE_KEY = "dashboard-zero-state";
type StoredZeroState = {
  hideJit: boolean | undefined;
  hideEnvironment: boolean | undefined;
};
export const Dashboard: React.FC<object> = ({}) => {
  const { loading: isFindingsLoading } = useContext(FindingsContext);
  const { hasEnvironments } = useContext(EnvironmentContext);

  // Need to query the full requests collection to check if the user has any requests
  const { loading: isRequestsLoading, hasRequests } =
    useRequests(RequestStatuses);

  const { xl } = useScreen();

  const [storedZeroState, setStoredZeroState] =
    useLocalStorage<StoredZeroState>(ZERO_STATE_LOCAL_STORAGE_KEY, {
      hideJit: undefined,
      hideEnvironment: undefined,
    });

  const hideJit = useCallback(() => {
    setStoredZeroState({ ...storedZeroState, hideJit: true });
  }, [setStoredZeroState, storedZeroState]);

  const hideEnvironment = useCallback(() => {
    setStoredZeroState({ ...storedZeroState, hideEnvironment: true });
  }, [setStoredZeroState, storedZeroState]);
  const inventoryPanel = (
    <VerticalDiv style={{ width: xl ? "40%" : "100%" }}>
      <Space size={20}>
        <GrayTitle title={"Inventory"} />
      </Space>
      <ItemChart hasEnvironments={hasEnvironments} />
    </VerticalDiv>
  );

  const posturePanels = (
    <>
      <div style={{ display: "flex", width: "100%", gap: "1em" }}>
        <VerticalDiv style={{ width: xl ? "60%" : "100%" }}>
          <Space size={20}>
            <GrayTitle title="Posture" />
          </Space>
          <FindingsBySeverity
            hasEnvironments={hasEnvironments}
            isLoading={isFindingsLoading}
          />
        </VerticalDiv>
        {xl && inventoryPanel}
      </div>
      {!xl && inventoryPanel}
    </>
  );

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { installed } = useInstalledIntegrations();

  const allowHidingZeroState = useMemo(
    () => hasRequests || hasEnvironments,
    [hasRequests, hasEnvironments]
  );
  const doNothing = useCallback(() => {}, []);
  if (isFindingsLoading || xl === undefined || isRequestsLoading) {
    return (
      <Space direction="vertical" size={"middle"} style={{ width: "100%" }}>
        <Card bordered={false} bodyStyle={{ background: colors.neutral["10"] }}>
          <div style={{ display: "flex", width: "100%", gap: "1em" }}>
            <Skeleton
              active
              style={{ padding: "96px" }}
              paragraph={{ rows: 4 }}
            />
            <Skeleton
              active
              style={{ padding: "96px" }}
              paragraph={{ rows: 4 }}
            />
          </div>
        </Card>
        <Card bordered={false} bodyStyle={{ background: colors.neutral["10"] }}>
          <div style={{ display: "flex", width: "100%", gap: "1em" }}>
            <Skeleton
              active
              style={{ padding: "96px" }}
              paragraph={{ rows: 4 }}
            />
            <Skeleton
              active
              style={{ padding: "96px" }}
              paragraph={{ rows: 4 }}
            />
          </div>
        </Card>
      </Space>
    );
  }
  const innerJitPanel: JSX.Element = (
    <JitPanels
      // passing this in to avoid the additional render from calling useScreen
      breakpoint={xl}
      hasRequests={hasRequests}
    />
  );
  const jitPanels = hasRequests ? (
    <Card bordered={false} bodyStyle={{ background: colors.neutral["10"] }}>
      {innerJitPanel}
    </Card>
  ) : storedZeroState?.hideJit ? null : (
    <Card bordered={false} bodyStyle={{ background: colors.neutral["10"] }}>
      <ZeroState
        allowHide={allowHidingZeroState}
        onHide={hideJit}
        cta={
          <div>
            <div>
              <Typography.Text style={{ fontSize: "large" }}>
                Set up Just-in-Time access.
              </Typography.Text>
            </div>
            <div>
              <div style={{ marginTop: "1em" }}>
                <Link
                  to={`https://docs.p0.dev/getting-started/quick-start#configure-an-access-approver`}
                  target="_blank"
                >
                  Docs <LinkOutlined />
                </Link>
              </div>
            </div>
          </div>
        }
        background={innerJitPanel}
      />
    </Card>
  );

  if (!hasEnvironments) {
    return (
      <Space direction="vertical" size={"middle"} style={{ width: "100%" }}>
        {jitPanels}
        {!storedZeroState?.hideEnvironment && (
          <Card
            bordered={false}
            style={{ position: "relative" }}
            bodyStyle={{ background: colors.neutral["10"] }}
          >
            <ZeroState
              allowHide={allowHidingZeroState}
              onHide={hideEnvironment}
              cta={
                <div>
                  <div>
                    <Typography.Text style={{ fontSize: "large" }}>
                      Create an environment to get started.
                    </Typography.Text>
                  </div>
                  <div style={{ marginTop: "1em" }}>
                    <CreateEnvironment
                      buttonCopy="Create"
                      onClick={doNothing}
                      setModalOpen={setCreateModalOpen}
                      modalOpen={createModalOpen}
                      installed={installed}
                    />
                  </div>
                </div>
              }
              background={posturePanels}
            />
          </Card>
        )}
      </Space>
    );
  }

  return (
    <Space direction="vertical" size={"middle"} style={{ width: "100%" }}>
      <Card bordered={false} bodyStyle={{ background: colors.neutral["10"] }}>
        <EnvironmentSelector />

        {posturePanels}
      </Card>
      {jitPanels}
    </Space>
  );
};
