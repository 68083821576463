import { CalendarOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import {
  AssessmentSummary,
  JobSummary,
} from "components/Assessment/components/JobSummary";
import { Tenant } from "components/Login";
import { limit, orderBy, where } from "firebase/firestore";
import {
  FirestoreDoc,
  useFirestoreCollection,
} from "providers/FirestoreProvider";
import React, { createContext, useContext, useMemo } from "react";
import { IamAssessment } from "shared/types/assessment";
import { AssessmentJob } from "shared/types/assessment/job";

export type Environment = {
  // TODO: This is a hack to patch transitioning from standalone assessments to
  // a single inventory / posture collection per environment. We'll map the environment
  // to an assessment, then migrate off of assessments entirely.
  assessmentId: string;
  // Environment ID
  // To start, this is the same as the assessment ID. Ultimately we will migrate to a
  // separate collection for environments
  id: string;
  /** Human-readable name for this environment */
  label: string;
  /** Status of the environment */
  status: JSX.Element;
  detailedStatus: JSX.Element;
  /** Color theme for this environment, used for visually disambiguating environments */
  theme: string | undefined;
};

type EnvironmentDocsContext = {
  all: Map<string, Environment>;
  hasEnvironments: boolean;
  isLoading: boolean;
};

export const EnvironmentDocsContext = createContext<EnvironmentDocsContext>({
  all: new Map(),
  hasEnvironments: false,
  isLoading: false,
});

EnvironmentDocsContext.displayName = "EnvironmentDocsContext";

export const EnvironmentDocsProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  // TODO: migrate to separate environment collection
  const { docs: environmentDocs, loading: isLoading } =
    useFirestoreCollection<IamAssessment>("iam-assessments", {
      live: true,
      tenantAware: true,
    });

  const { all, hasEnvironments } = useMemo(() => {
    const environments = new Map<string, Environment>(
      environmentDocs?.map((d) => [
        d.id,
        {
          assessmentId: d.id,
          label: d.data.name,
          id: d.id,
          status: <AssessmentStatus id={d.id} />,
          detailedStatus: (
            <AssessmentStatus id={d.id} assessmentDoc={d} hideSteps={true} />
          ),
          theme: undefined,
        },
      ]) ?? []
    );
    return { all: environments, hasEnvironments: !!environmentDocs?.length };
  }, [environmentDocs]);

  return (
    <EnvironmentDocsContext.Provider
      value={{ all, hasEnvironments, isLoading }}
    >
      {children}
    </EnvironmentDocsContext.Provider>
  );
};

const AssessmentStatus: React.FC<{
  id: string;
  assessmentDoc?: FirestoreDoc<IamAssessment>;
  hideSteps?: boolean;
}> = ({ assessmentDoc, id, hideSteps }) => {
  const tenantId = useContext(Tenant);
  const { docs: jobDoc } = useFirestoreCollection<AssessmentJob>(
    `o/${tenantId}/job-state`,
    {
      live: true,
      queryConstraints: [
        where("assessmentId", "==", id),
        orderBy("lastUpdatedTimestamp", "desc"),
        limit(1),
      ],
    }
  );

  return assessmentDoc ? (
    <>
      {!jobDoc?.[0] && (
        <Typography.Text>Hasn&apos;t been run yet</Typography.Text>
      )}
      <AssessmentSummary
        showRun={false}
        currentDoc={jobDoc?.[0]}
        assessmentDoc={assessmentDoc}
        hideSteps={hideSteps}
      />
    </>
  ) : jobDoc?.[0] ? (
    <JobSummary
      id={jobDoc[0].id}
      job={jobDoc[0].data}
      expanded={false}
      hideSteps={hideSteps}
    />
  ) : jobDoc ? (
    <CalendarOutlined />
  ) : null;
};
