import { useUser } from "components/Login/hook";
import { useCallback } from "react";
import { NavigateOptions, To, useNavigate } from "react-router";

import { addEnvironmentToPath } from "../environment";
import { useSelectedEnvironment } from "./useSelectedEnvironment";

export const useNavigateWithEnv = () => {
  const navigate = useNavigate();

  const [selectedEnvironment] = useSelectedEnvironment();

  const { orgSlug } = useUser();
  const navigateWithEnv = useCallback(
    (path: To, options?: NavigateOptions, withTenant?: boolean) => {
      navigate(
        addEnvironmentToPath(
          path,
          selectedEnvironment,
          withTenant ? orgSlug : undefined
        ),
        {
          ...options,
        }
      );
    },
    [selectedEnvironment, navigate, orgSlug]
  );
  return navigateWithEnv;
};
