import styled from "styled-components";

/** Displays content spaced horizontally across page
 *
 * Useful for left- and right-aligned content
 */
export const SpaceBetweenDiv = styled.div`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  justify-content: space-between;
`;

/** Creates a div whose width is the size of the largest child */
export const ContentSizeDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

/** Right-aligns content */
export const RightAlignedDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

/** Like antd <Space>, except content fills width */
export const VerticalSpacedDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7em;
`;

export const ConstantWidthDiv = styled.div<{ width: string }>`
  width: ${(props) => props.width};
`;

export const ClipDiv = styled.div<{ maxWidth?: string }>`
  max-width: ${(props) => props.maxWidth ?? "13.5em"};
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/** Displays items horizontally aligned by baseline.
 *
 * Like <SpaceBetweenDiv> but with a fixed gap
 */
export const BaselineDiv = styled.div`
  align-items: baseline;
  display: flex;
  gap: 0.5em;
`;

/** Aligns items vertically */
export const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
