import { Button, Result, Typography } from "antd";
import { useInstalledIntegrations } from "components/Assessment/hooks/useInstalledIntegrations";
import { CreateEnvironment } from "components/Assessment/pages/CreateEnvironment";
import React, { useCallback, useState } from "react";

export const ZeroStatePage: React.FC<{
  description?: string;
  title?: string;
  action?: React.ReactNode;
  docLink?: string;
  icon?: React.ReactNode;
}> = ({ description, docLink, icon, title }) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const closeModal = useCallback(
    () => setCreateModalOpen(false),
    [setCreateModalOpen]
  );

  const { installed } = useInstalledIntegrations();

  return (
    <Result
      title={title}
      icon={icon}
      subTitle={<Typography.Paragraph>{description}</Typography.Paragraph>}
      extra={
        <>
          {
            <CreateEnvironment
              onClick={closeModal}
              setModalOpen={setCreateModalOpen}
              modalOpen={createModalOpen}
              installed={installed}
              buttonCopy="Set up environment"
              buttonProps={{
                type: "primary",
                style: { padding: "0 12px 0 12px" },
              }}
            />
          }
          {docLink && (
            <Button target="=_blank" href={docLink}>
              Read the docs
            </Button>
          )}
        </>
      }
    />
  );
};
