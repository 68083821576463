import { Tag, TagProps } from "antd";
import { mapValues } from "lodash";
import { colors } from "styles/variables";

/**
 * The Tags in this file are wrappers around the AntD tags with styling and colors
 * @param content The text to display inside the tags
 */

interface CustomTagProps extends TagProps {
  content: string;
}

const coloredTags = mapValues(colors.tagColors, (color) => {
  const ColoredTag = ({ content, ...tagProps }: CustomTagProps) => (
    <Tag {...tagProps} color={color.bg} style={{ color: color.text }}>
      {content}
    </Tag>
  );
  return ColoredTag;
});

export const RedTag = coloredTags.red;
export const BlueTag = coloredTags.blue;
export const YellowTag = coloredTags.yellow;
export const OrangeTag = coloredTags.orange;
export const NeutralTag = coloredTags.lightGrey;
export const GreyTag = coloredTags.grey;
