import { useContext, useEffect } from "react";
import { useParams } from "react-router";

import { ResultDetail } from "../components/ResultDetail";
import { ScopeContext } from "../contexts/ScopeContext";
import { useControls } from "../hooks/useControls";

const WHITESPACE = /\s/;

export const QueryResult: React.FC = () => {
  const { nodeType, nodeKey, scopeKey } = useParams();
  const { controls } = useControls();
  const { setScopeKey } = useContext(ScopeContext);

  useEffect(() => {
    if (scopeKey) setScopeKey(scopeKey);
  }, [scopeKey, setScopeKey]);

  return nodeKey && nodeType ? (
    <ResultDetail
      terms={controls.where.split(WHITESPACE)}
      node={{
        key: nodeKey,
        type: nodeType,
      }}
      mode={"query result"}
    />
  ) : null;
};
