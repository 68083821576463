export enum AppPaths {
  Administration = "administration",
  Config = "config",
  Dashboard = "dashboard",
  IamAssessment = "iam-assessments",
  Integrations = "integrations",
  IntegrationsSandbox = "sandbox/integrations",
  Inventory = "inventory",
  Jit = "jit",
  P0Admin = "p0-admin",
  Posture = "posture",
  RestState = "rest-state",
  Settings = "settings",
}
