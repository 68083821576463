import { Tenant } from "components/Login";
import { initializeApp } from "firebase/app";
import {
  browserPopupRedirectResolver,
  browserSessionPersistence,
  getAuth as getOriginalAuth,
} from "firebase/auth";
import { initializeAuth } from "firebase/auth";
import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryConstraint,
  QuerySnapshot,
  Unsubscribe,
  collection,
  doc as firestoreDoc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";

import { useDeepMemo } from "../hooks/useDeepMemo";
import { Cancellation } from "../utils/cancel";

// Your web app's Firebase configuration
// Test configuration
const testFirebaseConfig = {
  apiKey: "AIzaSyC9A5VXSwDDS-Vp4WH_UIanEqJvv_7XdlQ",
  authDomain: "p0-gcp-project.firebaseapp.com",
  projectId: "p0-gcp-project",
  storageBucket: "p0-gcp-project.appspot.com",
  messagingSenderId: "398809717501",
  appId: "1:398809717501:web:6dd1cab893b2faeb06fc94",
};
// Staging configuration
const stageFirebaseConfig = {
  apiKey: "AIzaSyClQZQ3NY-4-Dpq7Gv5t_U7bQt-dnW08Io",
  authDomain: "p0-stage.firebaseapp.com",
  projectId: "p0-stage",
  storageBucket: "p0-stage.appspot.com",
  messagingSenderId: "615473322806",
  appId: "1:615473322806:web:5391ff8dfc82dbab26f197",
};
// Prod configuration
const prodFirebaseConfig = {
  apiKey: "AIzaSyCaL-Ik_l_5tdmgNUNZ4Nv6NuR4o5_PPfs",
  authDomain: "p0-prod.firebaseapp.com",
  projectId: "p0-prod",
  storageBucket: "p0-prod.appspot.com",
  messagingSenderId: "228132571547",
  appId: "1:228132571547:web:4da03aeb78add86fe6b93e",
};
// Prod CNA configuration
const prodCnaCentralFirebaseConfig = {
  apiKey: "AIzaSyCVW5z8KuEG8bR4CMOW_LoI3obcHkka9bQ",
  authDomain: "p0-prod-cna-central.firebaseapp.com",
  projectId: "p0-prod-cna-central",
  storageBucket: "p0-prod-cna-central.appspot.com",
  messagingSenderId: "1011388418556",
  appId: "1:1011388418556:web:e80cd06e2b2af8e84b4a79",
};
// Prod Splunk Nonprod configuration
const prodSplunkNonprodFirebaseConfig = {
  apiKey: "AIzaSyDZhODPRiQr3aaNQZ6JAFbJdf5RZ4dKuOI",
  authDomain: "p0-prod-splunk-nonprod.firebaseapp.com",
  projectId: "p0-prod-splunk-nonprod",
  storageBucket: "p0-prod-splunk-nonprod.appspot.com",
  messagingSenderId: "382676882012",
  appId: "1:382676882012:web:4c8f6d28fd3234f38e8c99",
};

// Prod Splunk Live configuration
const prodSplunkLiveFirebaseConfig = {
  apiKey: "AIzaSyBOmhi_nY1ueUObndI9PnqvJGmiM4eev3k",
  authDomain: "p0-prod-splunk-live.firebaseapp.com",
  projectId: "p0-prod-splunk-live",
  storageBucket: "p0-prod-splunk-live.appspot.com",
  messagingSenderId: "452051909837",
  appId: "1:452051909837:web:82493404f3b1e66218fd84",
};

const firebaseConfig =
  window.location.hostname === "p0.app"
    ? prodFirebaseConfig
    : window.location.hostname === "cna.p0.app"
    ? prodCnaCentralFirebaseConfig
    : window.location.hostname === "splunk-nonprod.p0.app"
    ? prodSplunkNonprodFirebaseConfig
    : window.location.hostname === "splunk.p0.app"
    ? prodSplunkLiveFirebaseConfig
    : window.location.hostname === "stage.p0.app"
    ? stageFirebaseConfig
    : testFirebaseConfig;

// Initialize Firebase - following https://github.com/angular/angularfire/issues/3181#issuecomment-1148187463 to avoid Error "INTERNAL ASSERTION FAILED: Expected a class definition"
// These secrete keys are not sensitive, so it's the recommended way to use them. See https://firebase.google.com/docs/web/setup and https://stackoverflow.com/questions/37482366/is-it-safe-to-expose-firebase-apikey-to-the-public
// nosemgrep: javascript.firebase.firebase-hardcoded-secret.firebase-hardcoded-secret
const app = () => initializeApp(firebaseConfig);
export const DB = getFirestore(app());
export const getAuth = () => {
  if (typeof document !== "undefined") {
    return initializeAuth(app(), {
      persistence: browserSessionPersistence,
      popupRedirectResolver: browserPopupRedirectResolver,
    });
  }
  return getOriginalAuth(app());
};

export const getEnvProjectId = () => {
  return firebaseConfig.projectId;
};

interface FirestoreOptions {
  /** If true, requests live updates from Firestore */
  live?: boolean;
  /** Query constraints passed directly to Firestore */
  queryConstraints?: QueryConstraint[];
  /** If true, prepends tenant ID to the path */
  tenantAware?: boolean;
}

export type FirestoreDoc<DocType> = {
  id: string;
  ref: DocumentReference<DocType>;
  data: DocType;
};

/**Retrieves a document collection from Firebase
 *
 * If `options.live`, will automatically update the hook output when
 * documents change in Firebase.
 *
 * Example usage:
 *
 *   const docs = useFirestoreCollection<MyDataType>(path);
 *   return (docs !== undefined ? <RenderDocs docs={docs} /> : <ZeroState />);
 *
 * @param path The collection path
 * @param options A FirestoreOptions object
 */
export const useFirestoreCollection = <T,>(
  path: string | undefined,
  options: FirestoreOptions = {}
) => {
  const tenantId = useContext(Tenant);
  const [values, setValues] = useState<FirestoreDoc<T>[]>();
  const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
  const queryConstraints = useDeepMemo(options.queryConstraints ?? []);
  const [loading, setLoading] = useState(true);

  const collectionPath = useMemo(
    () =>
      options.tenantAware
        ? tenantId && path
          ? `o/${tenantId}/${path}`
          : undefined
        : path,
    [options.tenantAware, tenantId, path]
  );

  useEffect(() => {
    if (!collectionPath) {
      setLoading(false);
      return;
    }
    setUnsubscribe(undefined);
    setValues(undefined);
    setLoading(true);
    const cancellation = new Cancellation();
    const q = query(collection(DB, collectionPath), ...queryConstraints);
    const cb = cancellation.guard((snapshot: QuerySnapshot<DocumentData>) => {
      const newValues: FirestoreDoc<T>[] = [];
      snapshot.forEach((result) => {
        newValues.push({
          id: result.id,
          data: result.data() as T,
          ref: result.ref as DocumentReference<T>,
        });
      });
      setValues(newValues);
      setLoading(false);
    });
    if (options.live) {
      const unsub = onSnapshot(q, cb);
      // Note that setXxx with a function calls that function with previous state
      // See SetStateAction type
      setUnsubscribe(() => () => {
        unsub();
      });
      return () => {
        cancellation.cancel();
        unsub();
      };
    } else {
      getDocs(q).then(cb);
      return cancellation.cancel;
    }
  }, [collectionPath, options.live, queryConstraints]);

  // Unsubscribe previous listeners whenever path or query constraints change
  useEffect(() => {
    unsubscribe?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionPath, queryConstraints]);

  return { docs: values, loading };
};

export type KnownFirestoreDoc<T> = {
  doc: FirestoreDoc<T>;
  loading: boolean;
  ref: DocumentReference<DocumentData>;
};

export type UnknownFirestoreDoc<T> = {
  doc: FirestoreDoc<T> | undefined;
  loading: boolean;
  ref: DocumentReference<DocumentData> | undefined;
};

/** Retrieves a single document from Firebase
 *
 * If `options.live`, will automatically update the hook output when
 * the document changes in Firebase.
 *
 * Example usage:
 *
 *   const doc = useFirestoreDoc<MyDataType>(path);
 *   return (doc !== undefined ? <RenderDoc doc={doc} /> : <ZeroState />);
 *
 * @param path The document path
 * @param options A FirestoreOptions object
 */
export function useFirestoreDoc<T>(
  path: string,
  options?: FirestoreOptions
): KnownFirestoreDoc<T>;
export function useFirestoreDoc<T>(
  path: string | undefined,
  options?: FirestoreOptions
): UnknownFirestoreDoc<T>;
export function useFirestoreDoc<T>(
  path: string | undefined,
  options: FirestoreOptions = {}
) {
  const tenantId = useContext(Tenant);
  const [doc, setDoc] = useState<FirestoreDoc<T>>();
  const [loading, setLoading] = useState(true);

  const docPath = useMemo(
    () =>
      options.tenantAware && tenantId && tenantId !== "" && path
        ? `o/${tenantId}/${path}`
        : path,
    [options.tenantAware, tenantId, path]
  );

  const ref = useMemo(
    () => (docPath ? firestoreDoc(DB, docPath) : undefined),
    [docPath]
  );

  useEffect(() => {
    if (!ref) {
      setDoc(undefined);
      setLoading(false);
      return;
    }
    const cancellation = new Cancellation();
    const cb = cancellation.guard((snapshot: DocumentSnapshot) => {
      setLoading(false);
      const data = snapshot.data();
      if (data) {
        setDoc({
          id: snapshot.id,
          data: data as T,
          ref: ref as DocumentReference<T>,
        });
      } else {
        setDoc(undefined);
      }
    });
    setLoading(true);
    if (options.live) {
      const unsubscribe = onSnapshot(ref, cb);
      return () => {
        cancellation.cancel();
        unsubscribe();
      };
    } else {
      getDoc(ref).then(cb);
      return cancellation.cancel;
    }
  }, [options.live, ref, setDoc]);

  return { doc, loading, ref };
}

/**
 * Retrieves a document Collection from firebase
 */
export const getFirestoreCollection = async <T,>(path: string) => {
  const q = query(collection(DB, path));
  const snapshot = await getDocs(q);
  const values: FirestoreDoc<T>[] = [];
  snapshot.forEach((result) => {
    values.push({
      id: result.id,
      data: result.data() as T,
      ref: result.ref as DocumentReference<T>,
    });
  });
  return values;
};
