import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  Modal,
  ModalProps,
  Select,
  Tooltip,
  Typography,
  message,
} from "antd";
import { EnvironmentContext } from "components/Environment/contexts/EnvironmentContext";
import { Tenant } from "components/Login";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { uniq } from "lodash";
import { DB } from "providers/FirestoreProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { priorityLabels } from "shared/assessment/constants";
import { toAssessmentPath } from "shared/assessment/helper";
import { AppPaths } from "shared/routes/constants";
import { AssessmentScopeIntegration } from "shared/types/assessment";
import { TargetNodeType } from "shared/types/assessment/data";
import {
  SavedMonitor,
  monitorPriorities,
} from "shared/types/assessment/monitor";
import { integrationLabels } from "shared/types/workflow/constants";

import { MonitorWithMetadata } from "../contexts/FindingsContext";
import { SelectedEnvironmentContext } from "../contexts/SelectedEnvironmentContext";
import { useNavigateWithEnv } from "../hooks/useNavigateWithEnv";
import { StyledMonitorContainer } from "../styles";
import { targetLogo } from "./TargetLogo";

interface NewMonitorModalProps extends ModalProps {
  closeModal: () => void;
  searchTerm: string;
  show: TargetNodeType;
}

export const NewMonitorModal: React.FC<NewMonitorModalProps> = ({
  closeModal,
  searchTerm,
  show,
  ...props
}) => (
  <Modal footer={false} onCancel={closeModal} {...props}>
    <Typography.Title level={4}>Create New Monitor</Typography.Title>
    <EditMonitorForm
      onSubmitForm={closeModal}
      initialSearchTerm={searchTerm}
      show={show}
    />
  </Modal>
);

export const EditMonitorForm: React.FC<{
  onSubmitForm: () => void;
  initialSearchTerm?: string;
  monitor?: MonitorWithMetadata;
  show: TargetNodeType;
}> = ({ onSubmitForm, initialSearchTerm, monitor, show }) => {
  const navigate = useNavigateWithEnv();
  const tenantId = useContext(Tenant);
  const { assessment } = useContext(SelectedEnvironmentContext);
  const { selected } = useContext(EnvironmentContext);
  const assessmentId = selected?.assessmentId;
  const [form] = Form.useForm<SavedMonitor>();
  const [editSearchTerm, setEditSearchTerm] = useState(false);
  const [saving, setSaving] = useState(false);

  const availableIntegrations = assessment.doc
    ? uniq(assessment.doc.data.targets.map((scope) => scope.integration))
    : [];

  const getTargetLogo = useCallback(
    (integration: AssessmentScopeIntegration) => targetLogo(integration),
    []
  );

  useEffect(() => {
    if (monitor !== undefined) {
      form.setFieldsValue({
        label: monitor.label,
        description: monitor.description ?? "",
        priority: monitor.priority,
        searchTerm: monitor.search.map((s) => s.term).join(" "),
        scopes: monitor.scopes,
      });
    }
  }, [monitor, form]);

  const onEditSearchTerm = useCallback(() => {
    setEditSearchTerm(!editSearchTerm);
    form.setFieldsValue({ searchTerm: initialSearchTerm });
  }, [form, editSearchTerm, initialSearchTerm]);

  const onSubmit = useCallback(
    async (data: SavedMonitor) => {
      setSaving(true);

      if (data.searchTerm === undefined) {
        data.searchTerm = initialSearchTerm ?? "";
      }

      const monitorCollection = collection(
        DB,
        `${toAssessmentPath(tenantId, assessmentId ?? "")}/monitors`
      );
      if (monitor) {
        const update: SavedMonitor = {
          ...data,
          show,
        };
        if (data.searchTerm !== initialSearchTerm) {
          update.hasBeenEvaluated = false;
        }
        await updateDoc(doc(monitorCollection, monitor.monitorId), update);
        message.success("Monitor updated");
      } else {
        const newMonitor = await addDoc(monitorCollection, {
          ...data,
          show,
          archived: false,
          hasBeenEvaluated: false,
        });
        message.success("Monitor created");
        navigate(`${AppPaths.Posture}/monitors/${newMonitor.id}`, {}, true);
      }
      setSaving(false);
      onSubmitForm();
      form.resetFields();
    },
    [
      assessmentId,
      onSubmitForm,
      form,
      tenantId,
      monitor,
      initialSearchTerm,
      show,
      navigate,
    ]
  );

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onSubmit}
      form={form}
    >
      {initialSearchTerm && !editSearchTerm ? (
        <StyledMonitorContainer>
          <Alert type="success" message={initialSearchTerm} />
          <Button type="text" onClick={onEditSearchTerm}>
            Edit
          </Button>
        </StyledMonitorContainer>
      ) : (
        <Form.Item
          label="Search Term"
          name="searchTerm"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      )}
      <Divider />
      <Form.Item label="Label" name="label" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description" initialValue="">
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="Priority"
        name="priority"
        rules={[{ required: true }]}
        initialValue="MEDIUM"
      >
        <Select>
          {monitorPriorities.map((priority) => (
            <Select.Option key={priority} value={priority}>
              {priorityLabels[priority]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title={"The integrations to which this monitor applies"}>
            Scope
          </Tooltip>
        }
        name="scopes"
        rules={[{ required: true }]}
      >
        <Select mode="multiple">
          {availableIntegrations.map((scope) => (
            <Select.Option key={scope} value={scope}>
              <>
                {getTargetLogo(scope)} {integrationLabels[scope]}
              </>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          {monitor ? "Submit" : "Create Monitor"}
        </Button>
      </Form.Item>
    </Form>
  );
};
