import { AssessmentSettingsSection } from "components/Assessment/styles";

import { AssessmentSummary } from "../../JobSummary";

export const EnvironmentStatusPage: React.FC = () => (
  <AssessmentSettingsSection>
    <AssessmentSummary showRun />
  </AssessmentSettingsSection>
);

export default EnvironmentStatusPage;
