import { Typography } from "antd";
import { CommandDisplay } from "components/Integrations/CommandDisplay";
import { useContext } from "react";
import { AnyNode } from "shared/types/assessment/data";
import { FindingState } from "shared/types/assessment/finding";

import { toScope } from "../../../shared/types/assessment";
import { MonitorWithFindings } from "../contexts/FindingsContext";
import { ScopeContext } from "../contexts/ScopeContext";

export const FindingsFixOrRevertCommands: React.FC<{
  nodes: AnyNode[];
  monitor: MonitorWithFindings;
  findingState: FindingState;
}> = ({ monitor, nodes, findingState }) => {
  const { integration, scopeKey } = useContext(ScopeContext);
  const fix =
    findingState === "open"
      ? monitor?.fix?.[integration]
      : monitor?.revert?.[integration];

  if (!fix || !integration) return null;

  return (
    <div>
      <Typography.Title level={3}>{monitor.label}</Typography.Title>
      <Typography.Paragraph>{fix.description}</Typography.Paragraph>
      <CommandDisplay
        commands={nodes
          .map(
            (n) =>
              fix.generate(n, { scope: toScope(scopeKey) })?.trim() ??
              `# Finding with key ${n.key} does not have a fix available`
          )
          .join("\n")}
        maxRows={25}
      />
    </div>
  );
};
