import { FirestoreDoc } from "providers/FirestoreProvider";
import { PermissionRequest } from "shared/types/permission";

import { GrantToRequestorRecord } from "../types";

/**
 * TODO: explicitly type mock data and include only the fields actually used by the Dashboard graphs
 */
export const mockRequestorMap: Record<string, GrantToRequestorRecord> = {
  "gcloud_test-gcp-project_firebase.admin": {
    countsByRequestor: { "golden.marmot@p0.dev": 2 },
    permission: {
      type: "gcloud",
      generated: {},
      allowedApprovals: [
        {
          containingRules: [
            {
              resource: { type: "any" },
              approval: [{ options: { allowOneParty: true }, type: "p0" }],
              requestor: { type: "any" },
            },
          ],
          approval: { options: { allowOneParty: true }, type: "p0" },
        },
      ],
      isAwaitingExpiry: false,
      approvalDetails: {
        approvalSource: "slack",
        id: "U07FQB2JM7A",
        name: "golden.marmot",
        approvedTimestamp: 1737672346078,
        email: "golden.marmot@p0.dev",
      },
      status: "EXPIRED_NOTIFIED",
      requestedTimestamp: 1737672339358,
      routingRulesSnapshot: [
        {
          requestor: { type: "any" },
          approval: [{ type: "p0", options: { allowOneParty: true } }],
          resource: { type: "any" },
        },
      ],
      lastUpdatedTimestamp: 1737676801715,
      notifications: {
        slack: {
          approvalByEmail: "golden.marmot@p0.dev",
          approverNotifications: [],
          approvalMessageId: "1737672340.298229",
          requestorMessageId: "1737672349.717489",
          requestorChannelId: "D07FDNGKX4N",
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1737672340298229",
          approvalByUserId: "U07FQB2JM7A",
          approvedTimestamp: 1737672346078,
        },
      },
      principal: "golden.marmot@p0.dev",
      revokedTimestamp: 1737676801020,
      requestedDuration: "1h",
      canEscalate: false,
      permission: {
        type: "role",
        roleId: "roles/firebase.admin",
        "principal-type": "user",
        projectId: "test-gcp-project",
        nIncludedPermissions: 572,
      },
      requestor: "golden.marmot@p0.dev",
      expiryTimestamp: 1737675946235,
      grantTimestamp: 1737672349102,
    },
  },
  "gcloud_test-gcp-project_read on p0-terraform-state-bucket": {
    countsByRequestor: { "golden.marmot@p0.dev": 1 },
    permission: {
      type: "gcloud",
      notifications: {
        slack: {
          requestorChannelId: "D07FDNGKX4N",
          approvalByEmail: "golden.marmot@p0.dev",
          approvalByUserId: "U07FQB2JM7A",
          approverNotifications: [],
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1736207444968769",
          approvedTimestamp: 1736207448782,
          approvalMessageId: "1736207444.968769",
          requestorMessageId: "1736207450.894049",
        },
      },
      canEscalate: false,
      allowedApprovals: [
        {
          containingRules: [
            {
              resource: { type: "any" },
              requestor: { type: "any" },
              approval: [{ options: { allowOneParty: true }, type: "p0" }],
            },
          ],
          approval: { type: "p0", options: { allowOneParty: true } },
        },
      ],
      status: "DONE_NOTIFIED",
      grantTimestamp: 1736207450383,
      requestedDuration: "720h",
      requestedTimestamp: 1736207444222,
      permission: {
        projectId: "test-gcp-project",
        type: "resource",
        permissionList: [
          {
            access: {
              permissions: [
                "storage.objects.get",
                "storage.objects.list",
                "storage.multipartUploads.list",
                "storage.multipartUploads.listParts",
                "storage.buckets.get",
                "storage.buckets.listEffectiveTags",
                "storage.buckets.listTagBindings",
              ],
              accessType: "permission",
              accessName: "read",
            },
            policyTargetResource: {
              type: "bucket",
              name: "p0-terraform-state-bucket",
            },
            requestedResource: {
              name: "p0-terraform-state-bucket",
              fullResourceName:
                "//storage.googleapis.com/p0-terraform-state-bucket",
              type: "bucket",
            },
            type: "resource",
          },
        ],
        "principal-type": "user",
      },
      requestor: "golden.marmot@p0.dev",
      generated: {
        roles: [
          {
            permissionId: "67d7f31a5a3efdb0f9cede6484107944d48ecb8a",
            role: "projects/test-gcp-project/roles/p0BucketRead",
          },
        ],
      },
      principal: "golden.marmot@p0.dev",
      approvalDetails: {
        id: "U07FQB2JM7A",
        name: "golden.marmot",
        approvalSource: "slack",
        approvedTimestamp: 1736207448782,
        email: "golden.marmot@p0.dev",
      },
      routingRulesSnapshot: [
        {
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
          resource: { type: "any" },
          requestor: { type: "any" },
        },
      ],
      expiryTimestamp: 1738799448906,
      isAwaitingExpiry: true,
      lastUpdatedTimestamp: 1736207450959,
    },
  },
  "gcloud_test-gcp-project_projects/test-gcp-project/roles/p0BucketRead on p0-terraform-state-bucket":
    {
      countsByRequestor: { "golden.marmot@p0.dev": 1 },
      permission: {
        type: "gcloud",
        routingRulesSnapshot: [
          {
            resource: { type: "any" },
            approval: [{ type: "p0", options: { allowOneParty: true } }],
            requestor: { type: "any" },
          },
        ],
        notifications: {
          slack: {
            requestorChannelId: "D07FDNGKX4N",
            approvalByEmail: "golden.marmot@p0.dev",
            approvalByUserId: "U07FQB2JM7A",
            approvalConversationUrl:
              "https://p0security.slack.com/archives/C07FH6VJXJ9/p1736207444943169",
            approvalMessageId: "1736207444.943169",
            approvedTimestamp: 1736207446795,
            requestorMessageId: "1736207448.618009",
            approverNotifications: [],
          },
        },
        isAwaitingExpiry: true,
        allowedApprovals: [
          {
            containingRules: [
              {
                resource: { type: "any" },
                requestor: { type: "any" },
                approval: [{ options: { allowOneParty: true }, type: "p0" }],
              },
            ],
            approval: { type: "p0", options: { allowOneParty: true } },
          },
        ],
        requestedTimestamp: 1736207444222,
        principal: "golden.marmot@p0.dev",
        generated: {
          roles: [
            {
              role: "projects/test-gcp-project/roles/p0BucketRead",
              permissionId: "5c8566f53e2dd936c036bc6d0af1fbef7799dfb6",
            },
          ],
        },
        lastUpdatedTimestamp: 1736207448686,
        canEscalate: false,
        requestor: "golden.marmot@p0.dev",
        expiryTimestamp: 1738799446918,
        status: "DONE_NOTIFIED",
        permission: {
          permissionList: [
            {
              policyTargetResource: {
                type: "bucket",
                name: "p0-terraform-state-bucket",
              },
              type: "resource",
              requestedResource: {
                fullResourceName:
                  "//storage.googleapis.com/p0-terraform-state-bucket",
                type: "bucket",
                name: "p0-terraform-state-bucket",
              },
              access: {
                nIncludedPermissions: 7,
                accessName: "projects/test-gcp-project/roles/p0BucketRead",
                accessType: "role",
              },
            },
          ],
          projectId: "test-gcp-project",
          type: "resource",
          "principal-type": "user",
        },
        grantTimestamp: 1736207448152,
        requestedDuration: "720h",
        approvalDetails: {
          approvedTimestamp: 1736207446795,
          name: "golden.marmot",
          approvalSource: "slack",
          id: "U07FQB2JM7A",
          email: "golden.marmot@p0.dev",
        },
      },
    },
  "gcloud_test-gcp-project_alloydb.admin": {
    countsByRequestor: { "golden.marmot@p0.dev": 1 },
    permission: {
      reason: "test",
      isAwaitingExpiry: true,
      canEscalate: false,
      type: "gcloud",
      generated: {},
      requestedDuration: "720h",
      principal: "golden.marmot@p0.dev",
      routingRulesSnapshot: [
        {
          requestor: { type: "any" },
          resource: { type: "any" },
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
        },
      ],
      notifications: {
        slack: {
          approvedTimestamp: 1736207403400,
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1736207401971919",
          requestorChannelId: "D07FDNGKX4N",
          approvalByUserId: "U07FQB2JM7A",
          approvalMessageId: "1736207401.971919",
          approvalByEmail: "golden.marmot@p0.dev",
          requestorMessageId: "1736207406.540249",
          approverNotifications: [],
        },
      },
      expiryTimestamp: 1738799403521,
      requestedTimestamp: 1736207401166,
      lastUpdatedTimestamp: 1736207406604,
      permission: {
        roleId: "roles/alloydb.admin",
        nIncludedPermissions: 70,
        projectId: "test-gcp-project",
        type: "role",
        "principal-type": "user",
      },
      requestor: "golden.marmot@p0.dev",
      grantTimestamp: 1736207406067,
      allowedApprovals: [
        {
          approval: { options: { allowOneParty: true }, type: "p0" },
          containingRules: [
            {
              resource: { type: "any" },
              requestor: { type: "any" },
              approval: [{ type: "p0", options: { allowOneParty: true } }],
            },
          ],
        },
      ],
      approvalDetails: {
        id: "U07FQB2JM7A",
        approvedTimestamp: 1736207403400,
        name: "golden.marmot",
        approvalSource: "slack",
        email: "golden.marmot@p0.dev",
      },
      status: "DONE_NOTIFIED",
    },
  },
  "ssh_test-gcp-project_dev-instance-golden-marmot": {
    countsByRequestor: { "golden.marmot@p0.dev": 1 },
    permission: {
      grantTimestamp: 1736207286575,
      reason: "Dev",
      allowedApprovals: [
        {
          approval: { options: { allowOneParty: true }, type: "p0" },
          containingRules: [
            {
              approval: [{ options: { allowOneParty: true }, type: "p0" }],
              requestor: { type: "any" },
              resource: { type: "any" },
            },
          ],
        },
      ],
      expiryTimestamp: 1738799282660,
      status: "DONE_NOTIFIED",
      lastUpdatedTimestamp: 1736207287340,
      notifications: {
        slack: {
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1736207263423149",
          approvalMessageId: "1736207263.423149",
          requestorMessageId: "1736207287.273809",
          requestorChannelId: "D07FDNGKX4N",
          approverNotifications: [],
        },
      },
      approvalDetails: {
        name: "golden.marmot@p0.dev",
        email: "golden.marmot@p0.dev",
        id: "golden.marmot@p0.dev",
        approvedTimestamp: 1736207282534,
        approvalSource: "webapp",
      },
      routingRulesSnapshot: [
        {
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
          resource: { type: "any" },
          requestor: { type: "any" },
        },
      ],
      isAwaitingExpiry: true,
      access: "session",
      canEscalate: false,
      principal: "golden.marmot@p0.dev",
      type: "ssh",
      generated: {
        roles: [
          {
            permissionId: "4a866f02f117842ea9646d5ca2c303176a62e89a",
            role: "roles/compute.osLogin",
          },
          {
            permissionId: "ca2fda9e9e8d3ce54d9d8b81b58f1fc3bf6d95e3",
            role: "roles/iap.tunnelResourceAccessor",
          },
          {
            permissionId: "d200126f7f6282d8599597c0462a7445a9312680",
            role: "roles/iam.serviceAccountUser",
          },
        ],
      },
      permission: {
        zone: "us-west1-c",
        destination: "dev-instance-golden-marmot",
        sudo: false,
        parent: "test-gcp-project",
        resource: {
          permissionList: [
            {
              policyTargetResource: {
                zoneName: "us-west1-c",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-golden-marmot",
                name: "dev-instance-golden-marmot",
                type: "instance",
              },
              requestedResource: {
                type: "instance",
                name: "dev-instance-golden-marmot",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-golden-marmot",
                zoneName: "us-west1-c",
              },
              type: "resource",
              access: {
                accessType: "role",
                accessName: "roles/compute.osLogin",
                nIncludedPermissions: 5,
                hidden: true,
              },
            },
            {
              requestedResource: {
                type: "tunnelInstance",
                zoneName: "us-west1-c",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-golden-marmot",
                name: "dev-instance-golden-marmot",
              },
              access: {
                accessType: "role",
                condition: "destination.port == 22",
                nIncludedPermissions: 2,
                accessName: "roles/iap.tunnelResourceAccessor",
              },
              policyTargetResource: {
                type: "tunnelInstance",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-golden-marmot",
                name: "dev-instance-golden-marmot",
                zoneName: "us-west1-c",
              },
              type: "resource",
            },
            {
              requestedResource: {
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                type: "serviceaccount",
              },
              access: {
                nIncludedPermissions: 5,
                accessName: "roles/iam.serviceAccountUser",
                accessType: "role",
              },
              type: "resource",
              policyTargetResource: {
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                type: "serviceaccount",
              },
            },
          ],
          instanceName: "dev-instance-golden-marmot",
          zone: "us-west1-c",
          projectId: "test-gcp-project",
          fullName:
            "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-golden-marmot",
        },
        publicKey:
          "ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQCoG+RKirtzSQzOTMZppG0hf5AXp8cJR7RVFX/f1L+ypUokBagamk3lxWhYbotvWHsuWxfh3Qaw8MAFi2fI8gd5wMfzyz5zTa5vy8eYDaytr/QYLIRfpZLXZTGmYOhRdHdhBfV3Ku4uHuE5nubyeZwAOJpt7cWRjYtOSeekisgot6nf/CIy1H4TxgJ4D5i1XEorAZxX34RYWiF5Oo3YkKZISxRzf6hUQTp7nfGTfKR+Mnk9Qdi5gWymVL0cOCezPheoHZcKNOgyuIcct2etD4B0pKR6lDs4yKMPCvNK6KEGdTn13idHdASbSC5rXiYKFflP7MTSSU4gyM8Sf87/liMR ",
        provider: "gcloud",
      },
      requestor: "golden.marmot@p0.dev",
      requestedTimestamp: 1736207262680,
    },
  },
} as any;

export const mockRequests: FirestoreDoc<PermissionRequest>[] = [
  {
    id: "A4noZBRMpmMu6QsKO72X",
    data: {
      requestedTimestamp: 1737672343471,
      requestedDuration: "1h",
      lastUpdatedTimestamp: 1737672344422,
      canEscalate: false,
      requestor: "golden.marmot@p0.dev",
      routingRulesSnapshot: [
        {
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
          resource: { type: "any" },
          requestor: { type: "any" },
        },
      ],
      permission: {
        roleId: "roles/firebase.admin",
        nIncludedPermissions: 572,
        type: "role",
        "principal-type": "user",
        projectId: "test-gcp-project",
      },
      notifications: {
        slack: {
          approverNotifications: [],
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1737672344237449",
          approvalMessageId: "1737672344.237449",
        },
      },
      principal: "golden.marmot@p0.dev",
      status: "PENDING_APPROVAL",
      type: "gcloud",
    },
  },
  {
    id: "UkvzUWo5TWhyptbfwqD9",
    data: {
      type: "gcloud",
      generated: {},
      allowedApprovals: [
        {
          containingRules: [
            {
              resource: { type: "any" },
              approval: [{ options: { allowOneParty: true }, type: "p0" }],
              requestor: { type: "any" },
            },
          ],
          approval: { options: { allowOneParty: true }, type: "p0" },
        },
      ],
      isAwaitingExpiry: false,
      approvalDetails: {
        approvalSource: "slack",
        id: "U07FQB2JM7A",
        name: "golden.marmot",
        approvedTimestamp: 1737672346078,
        email: "golden.marmot@p0.dev",
      },
      status: "EXPIRED_NOTIFIED",
      requestedTimestamp: 1737672339358,
      routingRulesSnapshot: [
        {
          requestor: { type: "any" },
          approval: [{ type: "p0", options: { allowOneParty: true } }],
          resource: { type: "any" },
        },
      ],
      lastUpdatedTimestamp: 1737676801715,
      notifications: {
        slack: {
          approvalByEmail: "golden.marmot@p0.dev",
          approverNotifications: [],
          approvalMessageId: "1737672340.298229",
          requestorMessageId: "1737672349.717489",
          requestorChannelId: "D07FDNGKX4N",
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1737672340298229",
          approvalByUserId: "U07FQB2JM7A",
          approvedTimestamp: 1737672346078,
        },
      },
      principal: "golden.marmot@p0.dev",
      revokedTimestamp: 1737676801020,
      requestedDuration: "1h",
      canEscalate: false,
      permission: {
        type: "role",
        roleId: "roles/firebase.admin",
        "principal-type": "user",
        projectId: "test-gcp-project",
        nIncludedPermissions: 572,
      },
      requestor: "golden.marmot@p0.dev",
      expiryTimestamp: 1737675946235,
      grantTimestamp: 1737672349102,
    },
  },
  {
    id: "eZUTWD1Gawup0SfxFYLV",
    data: {
      type: "gcloud",
      notifications: {
        slack: {
          requestorChannelId: "D07FDNGKX4N",
          approvalByEmail: "golden.marmot@p0.dev",
          approvalByUserId: "U07FQB2JM7A",
          approverNotifications: [],
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1736207444968769",
          approvedTimestamp: 1736207448782,
          approvalMessageId: "1736207444.968769",
          requestorMessageId: "1736207450.894049",
        },
      },
      canEscalate: false,
      allowedApprovals: [
        {
          containingRules: [
            {
              resource: { type: "any" },
              requestor: { type: "any" },
              approval: [{ options: { allowOneParty: true }, type: "p0" }],
            },
          ],
          approval: { type: "p0", options: { allowOneParty: true } },
        },
      ],
      status: "DONE_NOTIFIED",
      grantTimestamp: 1736207450383,
      requestedDuration: "720h",
      requestedTimestamp: 1736207444222,
      permission: {
        projectId: "test-gcp-project",
        type: "resource",
        permissionList: [
          {
            access: {
              permissions: [
                "storage.objects.get",
                "storage.objects.list",
                "storage.multipartUploads.list",
                "storage.multipartUploads.listParts",
                "storage.buckets.get",
                "storage.buckets.listEffectiveTags",
                "storage.buckets.listTagBindings",
              ],
              accessType: "permission",
              accessName: "read",
            },
            policyTargetResource: {
              type: "bucket",
              name: "p0-terraform-state-bucket",
            },
            requestedResource: {
              name: "p0-terraform-state-bucket",
              fullResourceName:
                "//storage.googleapis.com/p0-terraform-state-bucket",
              type: "bucket",
            },
            type: "resource",
          },
        ],
        "principal-type": "user",
      },
      requestor: "golden.marmot@p0.dev",
      generated: {
        roles: [
          {
            permissionId: "67d7f31a5a3efdb0f9cede6484107944d48ecb8a",
            role: "projects/test-gcp-project/roles/p0BucketRead",
          },
        ],
      },
      principal: "golden.marmot@p0.dev",
      approvalDetails: {
        id: "U07FQB2JM7A",
        name: "golden.marmot",
        approvalSource: "slack",
        approvedTimestamp: 1736207448782,
        email: "golden.marmot@p0.dev",
      },
      routingRulesSnapshot: [
        {
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
          resource: { type: "any" },
          requestor: { type: "any" },
        },
      ],
      expiryTimestamp: 1738799448906,
      isAwaitingExpiry: true,
      lastUpdatedTimestamp: 1736207450959,
    },
  },
  {
    id: "cUY9N22feCeJ4Jfg4UpA",
    data: {
      type: "gcloud",
      routingRulesSnapshot: [
        {
          resource: { type: "any" },
          approval: [{ type: "p0", options: { allowOneParty: true } }],
          requestor: { type: "any" },
        },
      ],
      notifications: {
        slack: {
          requestorChannelId: "D07FDNGKX4N",
          approvalByEmail: "golden.marmot@p0.dev",
          approvalByUserId: "U07FQB2JM7A",
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1736207444943169",
          approvalMessageId: "1736207444.943169",
          approvedTimestamp: 1736207446795,
          requestorMessageId: "1736207448.618009",
          approverNotifications: [],
        },
      },
      isAwaitingExpiry: true,
      allowedApprovals: [
        {
          containingRules: [
            {
              resource: { type: "any" },
              requestor: { type: "any" },
              approval: [{ options: { allowOneParty: true }, type: "p0" }],
            },
          ],
          approval: { type: "p0", options: { allowOneParty: true } },
        },
      ],
      requestedTimestamp: 1736207444222,
      principal: "golden.marmot@p0.dev",
      generated: {
        roles: [
          {
            role: "projects/test-gcp-project/roles/p0BucketRead",
            permissionId: "5c8566f53e2dd936c036bc6d0af1fbef7799dfb6",
          },
        ],
      },
      lastUpdatedTimestamp: 1736207448686,
      canEscalate: false,
      requestor: "golden.marmot@p0.dev",
      expiryTimestamp: 1738799446918,
      status: "DONE_NOTIFIED",
      permission: {
        permissionList: [
          {
            policyTargetResource: {
              type: "bucket",
              name: "p0-terraform-state-bucket",
            },
            type: "resource",
            requestedResource: {
              fullResourceName:
                "//storage.googleapis.com/p0-terraform-state-bucket",
              type: "bucket",
              name: "p0-terraform-state-bucket",
            },
            access: {
              nIncludedPermissions: 7,
              accessName: "projects/test-gcp-project/roles/p0BucketRead",
              accessType: "role",
            },
          },
        ],
        projectId: "test-gcp-project",
        type: "resource",
        "principal-type": "user",
      },
      grantTimestamp: 1736207448152,
      requestedDuration: "720h",
      approvalDetails: {
        approvedTimestamp: 1736207446795,
        name: "golden.marmot",
        approvalSource: "slack",
        id: "U07FQB2JM7A",
        email: "golden.marmot@p0.dev",
      },
    },
  },
  {
    id: "E28XrDKvDsi6aBAIYhVP",
    data: {
      reason: "test",
      isAwaitingExpiry: true,
      canEscalate: false,
      type: "gcloud",
      generated: {},
      requestedDuration: "720h",
      principal: "golden.marmot@p0.dev",
      routingRulesSnapshot: [
        {
          requestor: { type: "any" },
          resource: { type: "any" },
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
        },
      ],
      notifications: {
        slack: {
          approvedTimestamp: 1736207403400,
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1736207401971919",
          requestorChannelId: "D07FDNGKX4N",
          approvalByUserId: "U07FQB2JM7A",
          approvalMessageId: "1736207401.971919",
          approvalByEmail: "golden.marmot@p0.dev",
          requestorMessageId: "1736207406.540249",
          approverNotifications: [],
        },
      },
      expiryTimestamp: 1738799403521,
      requestedTimestamp: 1736207401166,
      lastUpdatedTimestamp: 1736207406604,
      permission: {
        roleId: "roles/alloydb.admin",
        nIncludedPermissions: 70,
        projectId: "test-gcp-project",
        type: "role",
        "principal-type": "user",
      },
      requestor: "golden.marmot@p0.dev",
      grantTimestamp: 1736207406067,
      allowedApprovals: [
        {
          approval: { options: { allowOneParty: true }, type: "p0" },
          containingRules: [
            {
              resource: { type: "any" },
              requestor: { type: "any" },
              approval: [{ type: "p0", options: { allowOneParty: true } }],
            },
          ],
        },
      ],
      approvalDetails: {
        id: "U07FQB2JM7A",
        approvedTimestamp: 1736207403400,
        name: "golden.marmot",
        approvalSource: "slack",
        email: "golden.marmot@p0.dev",
      },
      status: "DONE_NOTIFIED",
    },
  },
  {
    id: "gAZwtEuATfB66QHkiTOH",
    data: {
      grantTimestamp: 1736207286575,
      reason: "Dev",
      allowedApprovals: [
        {
          approval: { options: { allowOneParty: true }, type: "p0" },
          containingRules: [
            {
              approval: [{ options: { allowOneParty: true }, type: "p0" }],
              requestor: { type: "any" },
              resource: { type: "any" },
            },
          ],
        },
      ],
      expiryTimestamp: 1738799282660,
      status: "DONE_NOTIFIED",
      lastUpdatedTimestamp: 1736207287340,
      notifications: {
        slack: {
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1736207263423149",
          approvalMessageId: "1736207263.423149",
          requestorMessageId: "1736207287.273809",
          requestorChannelId: "D07FDNGKX4N",
          approverNotifications: [],
        },
      },
      approvalDetails: {
        name: "golden.marmot@p0.dev",
        email: "golden.marmot@p0.dev",
        id: "golden.marmot@p0.dev",
        approvedTimestamp: 1736207282534,
        approvalSource: "webapp",
      },
      routingRulesSnapshot: [
        {
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
          resource: { type: "any" },
          requestor: { type: "any" },
        },
      ],
      isAwaitingExpiry: true,
      access: "session",
      canEscalate: false,
      principal: "golden.marmot@p0.dev",
      type: "ssh",
      generated: {
        roles: [
          {
            permissionId: "4a866f02f117842ea9646d5ca2c303176a62e89a",
            role: "roles/compute.osLogin",
          },
          {
            permissionId: "ca2fda9e9e8d3ce54d9d8b81b58f1fc3bf6d95e3",
            role: "roles/iap.tunnelResourceAccessor",
          },
          {
            permissionId: "d200126f7f6282d8599597c0462a7445a9312680",
            role: "roles/iam.serviceAccountUser",
          },
        ],
      },
      permission: {
        zone: "us-west1-c",
        destination: "dev-instance-golden-marmot",
        sudo: false,
        parent: "test-gcp-project",
        resource: {
          permissionList: [
            {
              policyTargetResource: {
                zoneName: "us-west1-c",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-golden-marmot",
                name: "dev-instance-golden-marmot",
                type: "instance",
              },
              requestedResource: {
                type: "instance",
                name: "dev-instance-golden-marmot",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-golden-marmot",
                zoneName: "us-west1-c",
              },
              type: "resource",
              access: {
                accessType: "role",
                accessName: "roles/compute.osLogin",
                nIncludedPermissions: 5,
                hidden: true,
              },
            },
            {
              requestedResource: {
                type: "tunnelInstance",
                zoneName: "us-west1-c",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-golden-marmot",
                name: "dev-instance-golden-marmot",
              },
              access: {
                accessType: "role",
                condition: "destination.port == 22",
                nIncludedPermissions: 2,
                accessName: "roles/iap.tunnelResourceAccessor",
              },
              policyTargetResource: {
                type: "tunnelInstance",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-golden-marmot",
                name: "dev-instance-golden-marmot",
                zoneName: "us-west1-c",
              },
              type: "resource",
            },
            {
              requestedResource: {
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                type: "serviceaccount",
              },
              access: {
                nIncludedPermissions: 5,
                accessName: "roles/iam.serviceAccountUser",
                accessType: "role",
              },
              type: "resource",
              policyTargetResource: {
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                type: "serviceaccount",
              },
            },
          ],
          instanceName: "dev-instance-golden-marmot",
          zone: "us-west1-c",
          projectId: "test-gcp-project",
          fullName:
            "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-golden-marmot",
        },
        publicKey:
          "ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQCoG+RKirtzSQzOTMZppG0hf5AXp8cJR7RVFX/f1L+ypUokBagamk3lxWhYbotvWHsuWxfh3Qaw8MAFi2fI8gd5wMfzyz5zTa5vy8eYDaytr/QYLIRfpZLXZTGmYOhRdHdhBfV3Ku4uHuE5nubyeZwAOJpt7cWRjYtOSeekisgot6nf/CIy1H4TxgJ4D5i1XEorAZxX34RYWiF5Oo3YkKZISxRzf6hUQTp7nfGTfKR+Mnk9Qdi5gWymVL0cOCezPheoHZcKNOgyuIcct2etD4B0pKR6lDs4yKMPCvNK6KEGdTn13idHdASbSC5rXiYKFflP7MTSSU4gyM8Sf87/liMR ",
        provider: "gcloud",
      },
      requestor: "golden.marmot@p0.dev",
      requestedTimestamp: 1736207262680,
    },
  },
  {
    id: "nCZ7R6B9Bz63PEyKRiMK",
    data: {
      canEscalate: false,
      allowedApprovals: [
        {
          containingRules: [
            {
              approval: [{ type: "p0", options: { allowOneParty: true } }],
              resource: { type: "any" },
              requestor: { type: "any" },
            },
          ],
          approval: { options: { allowOneParty: true }, type: "p0" },
        },
      ],
      requestedTimestamp: 1734046799943,
      routingRulesSnapshot: [
        {
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
          requestor: { type: "any" },
          resource: { type: "any" },
        },
      ],
      access: "session",
      requestor: "golden.marmot@p0.dev",
      isAwaitingExpiry: true,
      notifications: {
        slack: {
          requestorMessageId: "1736207303.937829",
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1734047048776679",
          approvalMessageId: "1734047048.776679",
          requestorChannelId: "D07FDNGKX4N",
          approverNotifications: [],
        },
      },
      principal: "golden.marmot@p0.dev",
      grantTimestamp: 1736207303343,
      approvalDetails: {
        approvalSource: "webapp",
        name: "golden.marmot@p0.dev",
        id: "golden.marmot@p0.dev",
        approvedTimestamp: 1736207290881,
        email: "golden.marmot@p0.dev",
      },
      permission: {
        group: "Miguel",
        sudo: false,
        parent: "391052057035",
        region: "us-west-2",
        destination: "tiny-node",
        resource: {
          service: "ssm",
          policies: ["SessionManagerConnectAccess"],
          resourceTag: { value: "Miguel", key: "Developer" },
          accountAlias: "p0-dev",
          name: "i-0d1ef2c8e22e7b73b",
          arn: "arn:aws:ssm:us-west-2:391052057035:managed-instance/i-0d1ef2c8e22e7b73b",
          instanceName: "tiny-node",
          userName: "golden.marmot@p0.dev",
          accountId: "391052057035",
          instanceId: "i-0d1ef2c8e22e7b73b",
          account: "391052057035",
        },
        publicKey: "132",
        provider: "aws",
      },
      generated: {
        publicKey: "132",
        linuxUserName: "golden-marmot",
        resource: {
          policyArn:
            "arn:aws:iam::391052057035:policy/P0GoldenMarmotTalentedDog",
          policyDocument:
            '{\n  "Version": "2012-10-17",\n  "Statement": [\n    {\n      "Sid": "EnableSSMSession",\n      "Effect": "Allow",\n      "Action": [\n        "ssm:StartSession"\n      ],\n      "Resource": [\n        "arn:aws:ec2:us-west-2:391052057035:instance/i-0d1ef2c8e22e7b73b",\n        "arn:aws:ssm:us-west-2::document/AWS-StartSSHSession"\n      ],\n      "Condition": {\n        "DateLessThan": {\n          "aws:CurrentTime": "2025-02-05T23:48:10.997Z"\n        },\n        "BoolIfExists": {\n          "ssm:SessionDocumentAccessCheck": "true"\n        }\n      }\n    },\n    {\n      "Action": [\n        "ssm:TerminateSession"\n      ],\n      "Effect": "Allow",\n      "Resource": "arn:aws:ssm:*:391052057035:session/*",\n      "Condition": {\n        "StringLike": {\n          "ssm:resourceTag/aws:ssmmessages:session-id": [\n            "${aws:userid}*"\n          ]\n        },\n        "DateLessThan": {\n          "aws:CurrentTime": "2025-02-05T23:48:10.997Z"\n        }\n      }\n    },\n    {\n      "Action": [\n        "ssm:ResumeSession"\n      ],\n      "Effect": "Allow",\n      "Resource": [\n        "arn:aws:ssm:*:391052057035:session/${aws:username}-*"\n      ],\n      "Condition": {\n        "DateLessThan": {\n          "aws:CurrentTime": "2025-02-05T23:48:10.997Z"\n        }\n      }\n    }\n  ]\n}',
          arn: "_unused",
          policyName: "P0GoldenMarmotTalentedDog",
          name: "_unused",
        },
        accessFrom: "session",
      },
      lastUpdatedTimestamp: 1736207304000,
      type: "ssh",
      status: "DONE_NOTIFIED",
      expiryTimestamp: 1738799290997,
    },
  },
  {
    id: "4FN32jeZIL8xHqUzvOun",
    data: {
      requestor: "golden.marmot@p0.dev",
      notifications: {
        slack: {
          approvalMessageId: "1733865830.110739",
          approverNotifications: [],
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1733865830110739",
        },
      },
      requestedTimestamp: 1733865829350,
      principal: "golden.marmot@p0.dev",
      access: "group",
      lastUpdatedTimestamp: 1733865830275,
      routingRulesSnapshot: [
        {
          requestor: { type: "any" },
          resource: { type: "any" },
          approval: [{ type: "p0", options: { allowOneParty: true } }],
        },
      ],
      status: "PENDING_APPROVAL",
      permission: {
        alias: "p0-dev",
        parent: "391052057035",
        name: "Nathan",
        provider: "aws",
        key: "Developer",
        sudo: false,
      },
      type: "ssh",
      canEscalate: false,
    },
  },
  {
    id: "nAedoeD9X3TEQhk5w9wB",
    data: {
      canEscalate: false,
      access: "session",
      type: "ssh",
      permission: {
        destination: "dev-instance-fabian-joya",
        sudo: false,
        zone: "us-west1-c",
        publicKey:
          "ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQCoG+RKirtzSQzOTMZppG0hf5AXp8cJR7RVFX/f1L+ypUokBagamk3lxWhYbotvWHsuWxfh3Qaw8MAFi2fI8gd5wMfzyz5zTa5vy8eYDaytr/QYLIRfpZLXZTGmYOhRdHdhBfV3Ku4uHuE5nubyeZwAOJpt7cWRjYtOSeekisgot6nf/CIy1H4TxgJ4D5i1XEorAZxX34RYWiF5Oo3YkKZISxRzf6hUQTp7nfGTfKR+Mnk9Qdi5gWymVL0cOCezPheoHZcKNOgyuIcct2etD4B0pKR6lDs4yKMPCvNK6KEGdTn13idHdASbSC5rXiYKFflP7MTSSU4gyM8Sf87/liMR ",
        provider: "gcloud",
        resource: {
          permissionList: [
            {
              requestedResource: {
                type: "instance",
                name: "dev-instance-fabian-joya",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
                zoneName: "us-west1-c",
              },
              access: {
                accessType: "role",
                accessName: "roles/compute.osLogin",
                hidden: true,
                nIncludedPermissions: 5,
              },
              type: "resource",
              policyTargetResource: {
                zoneName: "us-west1-c",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
                name: "dev-instance-fabian-joya",
                type: "instance",
              },
            },
            {
              requestedResource: {
                name: "dev-instance-fabian-joya",
                zoneName: "us-west1-c",
                type: "tunnelInstance",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-fabian-joya",
              },
              policyTargetResource: {
                zoneName: "us-west1-c",
                name: "dev-instance-fabian-joya",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-fabian-joya",
                type: "tunnelInstance",
              },
              type: "resource",
              access: {
                accessName: "roles/iap.tunnelResourceAccessor",
                condition: "destination.port == 22",
                accessType: "role",
                nIncludedPermissions: 2,
              },
            },
            {
              access: {
                accessType: "role",
                accessName: "roles/iam.serviceAccountUser",
                nIncludedPermissions: 5,
              },
              requestedResource: {
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                type: "serviceaccount",
              },
              policyTargetResource: {
                type: "serviceaccount",
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
              },
              type: "resource",
            },
          ],
          instanceName: "dev-instance-fabian-joya",
          projectId: "test-gcp-project",
          fullName:
            "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
        },
        parent: "test-gcp-project",
      },
      notifications: {
        slack: {
          approvalMessageId: "1733865579.490199",
          approverNotifications: [],
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1733865579490199",
        },
      },
      lastUpdatedTimestamp: 1733865579698,
      status: "PENDING_APPROVAL",
      requestor: "golden.marmot@p0.dev",
      requestedTimestamp: 1733848157667,
      principal: "golden.marmot@p0.dev",
      routingRulesSnapshot: [
        {
          resource: { type: "any" },
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
          requestor: { type: "any" },
        },
      ],
    },
  },
  {
    id: "1yTLI96dlbkhSCLO5i5z",
    data: {
      type: "ssh",
      notifications: {
        slack: {
          approvalMessageId: "1733865579.450409",
          approverNotifications: [],
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1733865579450409",
        },
      },
      lastUpdatedTimestamp: 1733865579656,
      routingRulesSnapshot: [
        {
          resource: { type: "any" },
          requestor: { type: "any" },
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
        },
      ],
      access: "session",
      requestedTimestamp: 1733848127778,
      principal: "golden.marmot@p0.dev",
      requestor: "golden.marmot@p0.dev",
      permission: {
        sudo: false,
        publicKey:
          "ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQCoG+RKirtzSQzOTMZppG0hf5AXp8cJR7RVFX/f1L+ypUokBagamk3lxWhYbotvWHsuWxfh3Qaw8MAFi2fI8gd5wMfzyz5zTa5vy8eYDaytr/QYLIRfpZLXZTGmYOhRdHdhBfV3Ku4uHuE5nubyeZwAOJpt7cWRjYtOSeekisgot6nf/CIy1H4TxgJ4D5i1XEorAZxX34RYWiF5Oo3YkKZISxRzf6hUQTp7nfGTfKR+Mnk9Qdi5gWymVL0cOCezPheoHZcKNOgyuIcct2etD4B0pKR6lDs4yKMPCvNK6KEGdTn13idHdASbSC5rXiYKFflP7MTSSU4gyM8Sf87/liMR ",
        resource: {
          instanceName: "dev-instance-fabian-joya",
          projectId: "test-gcp-project",
          fullName:
            "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
          permissionList: [
            {
              requestedResource: {
                zoneName: "us-west1-c",
                type: "instance",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
                name: "dev-instance-fabian-joya",
              },
              access: {
                accessType: "role",
                nIncludedPermissions: 5,
                hidden: true,
                accessName: "roles/compute.osLogin",
              },
              type: "resource",
              policyTargetResource: {
                zoneName: "us-west1-c",
                name: "dev-instance-fabian-joya",
                type: "instance",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
              },
            },
            {
              policyTargetResource: {
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-fabian-joya",
                zoneName: "us-west1-c",
                name: "dev-instance-fabian-joya",
                type: "tunnelInstance",
              },
              type: "resource",
              access: {
                accessName: "roles/iap.tunnelResourceAccessor",
                condition: "destination.port == 22",
                accessType: "role",
                nIncludedPermissions: 2,
              },
              requestedResource: {
                type: "tunnelInstance",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-fabian-joya",
                name: "dev-instance-fabian-joya",
                zoneName: "us-west1-c",
              },
            },
            {
              type: "resource",
              access: {
                nIncludedPermissions: 5,
                accessName: "roles/iam.serviceAccountUser",
                accessType: "role",
              },
              policyTargetResource: {
                type: "serviceaccount",
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
              },
              requestedResource: {
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                type: "serviceaccount",
              },
            },
          ],
        },
        destination: "dev-instance-fabian-joya",
        zone: "us-west1-c",
        provider: "gcloud",
        parent: "test-gcp-project",
      },
      canEscalate: false,
      status: "PENDING_APPROVAL",
    },
  },
  {
    id: "g362PyRUSxBX6VZcGXzQ",
    data: {
      lastUpdatedTimestamp: 1736207297013,
      generated: {
        roles: [
          {
            role: "roles/compute.osLogin",
            permissionId: "bfd3f377dbac3d700e052499bfae3402d49fa08e",
          },
          {
            permissionId: "803c64cc1e1aacb517dfe5e314d119fadfad40a9",
            role: "roles/iap.tunnelResourceAccessor",
          },
          {
            role: "roles/iam.serviceAccountUser",
            permissionId: "d200126f7f6282d8599597c0462a7445a9312680",
          },
        ],
      },
      approvalDetails: {
        approvalSource: "webapp",
        approvedTimestamp: 1736207293242,
        id: "golden.marmot@p0.dev",
        name: "golden.marmot@p0.dev",
        email: "golden.marmot@p0.dev",
      },
      notifications: {
        slack: {
          requestorMessageId: "1736207296.944239",
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1733865579449249",
          approverNotifications: [],
          approvalMessageId: "1733865579.449249",
          requestorChannelId: "D07FDNGKX4N",
        },
      },
      canEscalate: false,
      status: "DONE_NOTIFIED",
      routingRulesSnapshot: [
        {
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
          resource: { type: "any" },
          requestor: { type: "any" },
        },
      ],
      expiryTimestamp: 1738799293358,
      isAwaitingExpiry: true,
      principal: "golden.marmot@p0.dev",
      allowedApprovals: [
        {
          containingRules: [
            {
              approval: [{ type: "p0", options: { allowOneParty: true } }],
              resource: { type: "any" },
              requestor: { type: "any" },
            },
          ],
          approval: { options: { allowOneParty: true }, type: "p0" },
        },
      ],
      type: "ssh",
      requestedTimestamp: 1733848008486,
      grantTimestamp: 1736207296466,
      permission: {
        provider: "gcloud",
        parent: "test-gcp-project",
        destination: "dev-instance-fabian-joya",
        publicKey:
          "ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQCoG+RKirtzSQzOTMZppG0hf5AXp8cJR7RVFX/f1L+ypUokBagamk3lxWhYbotvWHsuWxfh3Qaw8MAFi2fI8gd5wMfzyz5zTa5vy8eYDaytr/QYLIRfpZLXZTGmYOhRdHdhBfV3Ku4uHuE5nubyeZwAOJpt7cWRjYtOSeekisgot6nf/CIy1H4TxgJ4D5i1XEorAZxX34RYWiF5Oo3YkKZISxRzf6hUQTp7nfGTfKR+Mnk9Qdi5gWymVL0cOCezPheoHZcKNOgyuIcct2etD4B0pKR6lDs4yKMPCvNK6KEGdTn13idHdASbSC5rXiYKFflP7MTSSU4gyM8Sf87/liMR ",
        resource: {
          permissionList: [
            {
              policyTargetResource: {
                name: "dev-instance-fabian-joya",
                type: "instance",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
                zoneName: "us-west1-c",
              },
              access: {
                accessName: "roles/compute.osLogin",
                hidden: true,
                nIncludedPermissions: 5,
                accessType: "role",
              },
              requestedResource: {
                type: "instance",
                zoneName: "us-west1-c",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
                name: "dev-instance-fabian-joya",
              },
              type: "resource",
            },
            {
              policyTargetResource: {
                zoneName: "us-west1-c",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-fabian-joya",
                name: "dev-instance-fabian-joya",
                type: "tunnelInstance",
              },
              access: {
                accessName: "roles/iap.tunnelResourceAccessor",
                condition: "destination.port == 22",
                accessType: "role",
                nIncludedPermissions: 2,
              },
              requestedResource: {
                type: "tunnelInstance",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-fabian-joya",
                name: "dev-instance-fabian-joya",
                zoneName: "us-west1-c",
              },
              type: "resource",
            },
            {
              requestedResource: {
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                type: "serviceaccount",
              },
              policyTargetResource: {
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                type: "serviceaccount",
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
              },
              access: {
                accessType: "role",
                accessName: "roles/iam.serviceAccountUser",
                nIncludedPermissions: 5,
              },
              type: "resource",
            },
          ],
          instanceName: "dev-instance-fabian-joya",
          fullName:
            "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
          projectId: "test-gcp-project",
        },
        sudo: false,
        zone: "us-west1-c",
      },
      access: "session",
      requestor: "golden.marmot@p0.dev",
    },
  },
  {
    id: "94iHmXwOsCEWDB9i73eW",
    data: {
      principal: "golden.marmot@p0.dev",
      lastUpdatedTimestamp: 1733865579660,
      canEscalate: false,
      routingRulesSnapshot: [
        {
          resource: { type: "any" },
          approval: [{ type: "p0", options: { allowOneParty: true } }],
          requestor: { type: "any" },
        },
      ],
      access: "session",
      type: "ssh",
      requestor: "golden.marmot@p0.dev",
      permission: {
        resource: {
          projectId: "test-gcp-project",
          permissionList: [
            {
              access: {
                accessType: "role",
                nIncludedPermissions: 5,
                hidden: true,
                accessName: "roles/compute.osLogin",
              },
              policyTargetResource: {
                zoneName: "us-west1-c",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
                name: "dev-instance-fabian-joya",
                type: "instance",
              },
              type: "resource",
              requestedResource: {
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
                type: "instance",
                zoneName: "us-west1-c",
                name: "dev-instance-fabian-joya",
              },
            },
            {
              requestedResource: {
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-fabian-joya",
                type: "tunnelInstance",
                name: "dev-instance-fabian-joya",
                zoneName: "us-west1-c",
              },
              policyTargetResource: {
                zoneName: "us-west1-c",
                name: "dev-instance-fabian-joya",
                type: "tunnelInstance",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-fabian-joya",
              },
              access: {
                nIncludedPermissions: 2,
                accessType: "role",
                condition: "destination.port == 22",
                accessName: "roles/iap.tunnelResourceAccessor",
              },
              type: "resource",
            },
            {
              type: "resource",
              access: {
                nIncludedPermissions: 5,
                accessName: "roles/iam.serviceAccountUser",
                accessType: "role",
              },
              requestedResource: {
                type: "serviceaccount",
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
              },
              policyTargetResource: {
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                type: "serviceaccount",
              },
            },
          ],
          instanceName: "dev-instance-fabian-joya",
          fullName:
            "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-fabian-joya",
        },
        destination: "dev-instance-fabian-joya",
        parent: "test-gcp-project",
        sudo: false,
        zone: "us-west1-c",
        provider: "gcloud",
      },
      requestedTimestamp: 1733847818272,
      notifications: {
        slack: {
          approvalMessageId: "1733865579.464329",
          approverNotifications: [],
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1733865579464329",
        },
      },
      status: "PENDING_APPROVAL",
    },
  },
  {
    id: "w5DHnpc9XVIrUCedA85W",
    data: {
      canEscalate: false,
      lastUpdatedTimestamp: 1733865586976,
      requestedTimestamp: 1733847642605,
      access: "session",
      expiryTimestamp: 1733848093966,
      notifications: {
        slack: {
          requestorChannelId: "D07FDNGKX4N",
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1733847643764539",
          approverNotifications: [],
          requestorMessageId: "1733847798.057999",
          approvalMessageId: "1733847643.764539",
        },
      },
      type: "ssh",
      requestor: "golden.marmot@p0.dev",
      grantTimestamp: 1733847797562,
      revokedTimestamp: 1733865586600,
      routingRulesSnapshot: [
        {
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
          resource: { type: "any" },
          requestor: { type: "any" },
        },
      ],
      permission: {
        parent: "test-gcp-project",
        resource: {
          projectId: "test-gcp-project",
          fullName:
            "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-golden-marmot",
          permissionList: [
            {
              requestedResource: {
                type: "instance",
                name: "dev-instance-golden-marmot",
                zoneName: "us-west1-c",
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-golden-marmot",
              },
              access: {
                hidden: true,
                nIncludedPermissions: 5,
                accessType: "role",
                accessName: "roles/compute.osLogin",
              },
              policyTargetResource: {
                fullResourceName:
                  "//compute.googleapis.com/projects/test-gcp-project/zones/us-west1-c/instances/dev-instance-golden-marmot",
                name: "dev-instance-golden-marmot",
                zoneName: "us-west1-c",
                type: "instance",
              },
              type: "resource",
            },
            {
              policyTargetResource: {
                type: "tunnelInstance",
                name: "dev-instance-golden-marmot",
                zoneName: "us-west1-c",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-golden-marmot",
              },
              requestedResource: {
                type: "tunnelInstance",
                zoneName: "us-west1-c",
                fullResourceName:
                  "//iap.googleapis.com/projects/test-gcp-project/iap_tunnel/zones/us-west1-c/instances/dev-instance-golden-marmot",
                name: "dev-instance-golden-marmot",
              },
              access: {
                accessName: "roles/iap.tunnelResourceAccessor",
                accessType: "role",
                nIncludedPermissions: 2,
                condition: "destination.port == 22",
              },
              type: "resource",
            },
            {
              type: "resource",
              policyTargetResource: {
                type: "serviceaccount",
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
              },
              requestedResource: {
                name: "dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
                type: "serviceaccount",
                fullResourceName:
                  "//iam.googleapis.com/projects/test-gcp-project/serviceAccounts/dev-instance-service-account@test-gcp-project.iam.gserviceaccount.com",
              },
              access: {
                accessName: "roles/iam.serviceAccountUser",
                accessType: "role",
                nIncludedPermissions: 5,
              },
            },
          ],
          instanceName: "dev-instance-golden-marmot",
        },
        sudo: false,
        destination: "dev-instance-golden-marmot",
        zone: "us-west1-c",
        provider: "gcloud",
      },
      approvalDetails: {
        name: "golden.marmot@p0.dev",
        approvedTimestamp: 1733847793671,
        id: "golden.marmot@p0.dev",
        email: "golden.marmot@p0.dev",
        approvalSource: "webapp",
      },
      isAwaitingExpiry: false,
      generated: {
        roles: [
          {
            permissionId: "4a866f02f117842ea9646d5ca2c303176a62e89a",
            role: "roles/compute.osLogin",
          },
          {
            role: "roles/iap.tunnelResourceAccessor",
            permissionId: "ca2fda9e9e8d3ce54d9d8b81b58f1fc3bf6d95e3",
          },
          {
            role: "roles/iam.serviceAccountUser",
            permissionId: "d200126f7f6282d8599597c0462a7445a9312680",
          },
        ],
      },
      principal: "golden.marmot@p0.dev",
      status: "EXPIRED_NOTIFIED",
    },
  },
  {
    id: "f3wHGULn2U3vXKuYzTeY",
    data: {
      generated: {
        policyDocument:
          '{\n  "Version": "2012-10-17",\n  "Statement": [\n    {\n      "Effect": "Allow",\n      "Action": [\n        "sagemaker:Describe*",\n        "sagemaker:List*",\n        "sagemaker:BatchGetMetrics",\n        "sagemaker:GetDeviceRegistration",\n        "sagemaker:GetDeviceFleetReport",\n        "sagemaker:GetSearchSuggestions",\n        "sagemaker:BatchGetRecord",\n        "sagemaker:GetRecord",\n        "sagemaker:Search",\n        "sagemaker:QueryLineage",\n        "sagemaker:GetLineageGroupPolicy",\n        "sagemaker:BatchDescribeModelPackage",\n        "sagemaker:GetModelPackageGroupPolicy"\n      ],\n      "Resource": "*",\n      "Condition": {\n        "DateLessThan": {\n          "aws:CurrentTime": "2024-12-10T22:40:07.946Z"\n        }\n      }\n    },\n    {\n      "Effect": "Allow",\n      "Action": [\n        "application-autoscaling:DescribeScalableTargets",\n        "application-autoscaling:DescribeScalingActivities",\n        "application-autoscaling:DescribeScalingPolicies",\n        "application-autoscaling:DescribeScheduledActions",\n        "aws-marketplace:ViewSubscriptions",\n        "cloudwatch:DescribeAlarms",\n        "cognito-idp:DescribeUserPool",\n        "cognito-idp:DescribeUserPoolClient",\n        "cognito-idp:ListGroups",\n        "cognito-idp:ListIdentityProviders",\n        "cognito-idp:ListUserPoolClients",\n        "cognito-idp:ListUserPools",\n        "cognito-idp:ListUsers",\n        "cognito-idp:ListUsersInGroup",\n        "ecr:Describe*"\n      ],\n      "Resource": "*",\n      "Condition": {\n        "DateLessThan": {\n          "aws:CurrentTime": "2024-12-10T22:40:07.946Z"\n        }\n      }\n    }\n  ]\n}',
        arn: "_unused",
        policyArn:
          "arn:aws:iam::391052057035:policy/P0GoldenMarmotBeautifulCoral",
        name: "_unused",
        policyName: "P0GoldenMarmotBeautifulCoral",
      },
      access: "resource",
      status: "EXPIRED_NOTIFIED",
      expiryTimestamp: 1733870407946,
      requestedTimestamp: 1733784004886,
      canEscalate: false,
      routingRulesSnapshot: [
        {
          requestor: { type: "any" },
          approval: [{ type: "p0", options: { allowOneParty: true } }],
          resource: { type: "any" },
        },
      ],
      permission: {
        service: "sagemaker",
        arn: "arn:aws:sagemaker:us-east-2:391052057035:notebook-instance/p0-test-notebook",
        accountId: "391052057035",
        policies: ["AmazonSageMakerReadOnly"],
        userName: "golden.marmot@p0.dev",
        account: "391052057035",
        "requested-duration": "24h",
        name: "p0-test-notebook",
      },
      approvalDetails: {
        approvedTimestamp: 1733784007822,
        approvalSource: "slack",
        id: "U07FQB2JM7A",
        name: "golden.marmot",
        email: "golden.marmot@p0.dev",
      },
      requestedDuration: "24h",
      grantTimestamp: 1733784009249,
      isAwaitingExpiry: false,
      revokedTimestamp: 1733870416393,
      principal: "golden.marmot@p0.dev",
      type: "aws",
      lastUpdatedTimestamp: 1733870416812,
      requestor: "golden.marmot@p0.dev",
      notifications: {
        slack: {
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1733784005986909",
          requestorMessageId: "1733784009.776899",
          requestorChannelId: "D07FDNGKX4N",
          approverNotifications: [],
          approvalByEmail: "golden.marmot@p0.dev",
          approvalByUserId: "U07FQB2JM7A",
          approvalMessageId: "1733784005.986909",
          approvedTimestamp: 1733784007822,
        },
      },
    },
  },
  {
    id: "YdCGstsJMBIww8dopu7V",
    data: {
      requestor: "golden.marmot@p0.dev",
      isAwaitingExpiry: false,
      routingRulesSnapshot: [
        {
          approval: [{ options: { allowOneParty: true }, type: "p0" }],
          resource: { type: "any" },
          requestor: { type: "any" },
        },
      ],
      status: "EXPIRED_NOTIFIED",
      generated: { name: "_unused", arn: "_unused" },
      notifications: {
        slack: {
          approvalByUserId: "U07FQB2JM7A",
          approvalByEmail: "golden.marmot@p0.dev",
          approvalMessageId: "1733783947.374589",
          requestorMessageId: "1733783957.577049",
          approvedTimestamp: 1733783955272,
          approvalConversationUrl:
            "https://p0security.slack.com/archives/C07FH6VJXJ9/p1733783947374589",
          requestorChannelId: "D07FDNGKX4N",
          approverNotifications: [],
        },
      },
      principal: "golden.marmot@p0.dev",
      type: "aws",
      approvalDetails: {
        email: "golden.marmot@p0.dev",
        approvedTimestamp: 1733783955272,
        approvalSource: "slack",
        name: "golden.marmot",
        id: "U07FQB2JM7A",
      },
      requestedTimestamp: 1733783946367,
      revokedTimestamp: 1733787556739,
      access: "policy",
      lastUpdatedTimestamp: 1733787557223,
      permission: {
        name: "S3TestBucket3Policy",
        arn: "arn:aws:iam::391052057035:policy/S3TestBucket3Policy",
        accountId: "391052057035",
        userName: "golden.marmot@p0.dev",
        account: "391052057035",
      },
      expiryTimestamp: 1733787555387,
      canEscalate: false,
      grantTimestamp: 1733783957051,
    },
  },
] as any[];
