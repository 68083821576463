import { NotFound } from "components/App/NotFound";
import { JitPaths } from "components/Jit/paths";
import { AppPaths } from "shared/routes/constants";

export const RequestNotFound: React.FC<{ orgSlug?: string }> = ({
  orgSlug,
}) => (
  <NotFound
    title="Request Not Found"
    backButton={{
      label: "Back to all requests",
      navigateTo: `/o/${orgSlug}/${AppPaths.Jit}/${JitPaths.Activity}`,
    }}
  />
);
