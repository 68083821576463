import { InfoCircleTwoTone } from "@ant-design/icons";
import { List, Typography } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { sortBy } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { SECONDS } from "shared/time";
import { CredentialConsumer } from "shared/types/assessment/data/base";
import { assertNever } from "utils/assert";

import { IdentityCell, IdentityLink } from "./Identity";

const { Paragraph, Text } = Typography;

const ConsumerHelpToolTip: React.FC = () => (
  <>
    <Paragraph>
      Possible reasons for the unknown usage of this credential include:
    </Paragraph>
    <ul>
      <li>
        The Access logs Google Cloud integration is not installed or was
        installed too recently to record usage.
      </li>
      <li>
        The usage occurred in a different project than the one the credential is
        located in.
      </li>
      <li>
        The credential was used for authentication, but no actions were
        performed after authenticating with the credential.
      </li>
    </ul>
  </>
);
export const toConsumerText = (consumer: CredentialConsumer): string =>
  consumer.type === "ip"
    ? `${consumer.label} ${consumer.organization}`
    : consumer.type == "principal"
    ? consumer.label
    : assertNever(consumer);

export const ConsumersList: React.FC<{
  consumers: CredentialConsumer[] | undefined;
  lastAuthnTime: number;
}> = ({ consumers, lastAuthnTime }) =>
  consumers?.length ? (
    <List
      style={{
        maxHeight: "280px",
        paddingRight: "15px",
        overflowY: "auto",
      }}
    >
      {sortBy(consumers, ["usageCount"]).map((c, ix) => {
        return (
          <List.Item key={ix}>
            {c.type === "ip" ? (
              <>
                {c.userAgents?.length ? (
                  <>
                    <GraphTooltip
                      title={
                        <div>
                          User Agents: <br />{" "}
                          {c.userAgents.slice(0, 3).join(", ")}{" "}
                          {c.userAgents.length > 3
                            ? `(and ${c.userAgents.length - 3} others)`
                            : null}
                        </div>
                      }
                    >
                      <InfoCircleTwoTone />
                    </GraphTooltip>
                    &nbsp;
                  </>
                ) : null}
                <Link
                  to={`https://www.geolocation.com/en_us?ip=${c.label}`}
                  target="_blank"
                >
                  {toConsumerText(c)}
                </Link>
              </>
            ) : c.type === "principal" ? (
              <>
                <IdentityLink
                  data={{ ...c, external: false, type: c.identityType }}
                  type="short"
                  show="grant"
                />
                &nbsp;
                <IdentityCell
                  identity={{ ...c, external: false, type: c.identityType }}
                />
              </>
            ) : (
              assertNever(c)
            )}
            &nbsp; (most recently on{" "}
            {moment.unix(c.lastUsedTime / SECONDS).format("LL")})
          </List.Item>
        );
      })}
    </List>
  ) : lastAuthnTime === 0 ? (
    <Text type="secondary">(No consumers)</Text>
  ) : (
    <GraphTooltip title={<ConsumerHelpToolTip />}>
      <Text type="secondary">(Unknown)</Text>
    </GraphTooltip>
  );
