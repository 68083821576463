import { EnvironmentContext } from "components/Environment/contexts/EnvironmentContext";
import { endOfDay, startOfDay } from "date-fns";
import {
  QueryConstraint,
  collection,
  getCountFromServer,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { noop, orderBy, partition } from "lodash";
import {
  DB,
  FirestoreDoc,
  useFirestoreCollection,
} from "providers/FirestoreProvider";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router";
import { MonitorRanking } from "shared/assessment/constants";
import { toAssessmentPath } from "shared/assessment/helper";
import { ALL_SCOPE_SENTINEL, toKey } from "shared/types/assessment";
import { isa } from "shared/types/is";
import { mapWith } from "shared/util/collections";
import {
  DateParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { staticError } from "utils/console";

import {
  PresetMonitors,
  convertSavedMonitor,
} from "../../../shared/assessment/issues/presets";
import {
  Finding,
  FindingState,
} from "../../../shared/types/assessment/finding";
import {
  Monitor,
  SavedMonitor,
} from "../../../shared/types/assessment/monitor";
import { Tenant } from "../../Login";
import { ScopeContext } from "./ScopeContext";
import { SelectedEnvironmentContext } from "./SelectedEnvironmentContext";

export type MonitorWithMetadata = Monitor & {
  monitorId: string;
  isCustom?: boolean;
  archived?: boolean;
};

export type MonitorWithCount = MonitorWithMetadata & {
  count: number;
};

export type MonitorWithFindings = MonitorWithMetadata & {
  /** All findings for the current findings select */
  scopedFindings: FirestoreDoc<Finding>[];
  /** Findings for the current select, but for all possible scopes */
  countsByScope: Record<string, number>;
};

export type AssessmentFindings = {
  /** Mapping of monitor ID to corresponding finding counts */
  allMonitors: Record<string, MonitorWithCount>;
  archived: MonitorWithCount[];
  findingParams: string;
  loading: boolean;
  /** Priority-sorted list of monitors, filtered by findings select */
  prioritized: MonitorWithCount[];
  range: DateRange;
  selectedMonitor: MonitorWithFindings | undefined;
  setRange: (range: DateRange) => void;
  setState: (value: string) => void;
  setTrigger: (value: string) => void;
  state: FindingState;
  trigger: string;
};

type DateRange = [Date | undefined, Date | undefined];

const defaultFindingsContextValue: AssessmentFindings = {
  allMonitors: {},
  archived: [],
  findingParams: "",
  loading: true,
  prioritized: [],
  range: [undefined, undefined],
  selectedMonitor: undefined,
  setRange: noop,
  setState: noop,
  setTrigger: noop,
  state: "open",
  trigger: "all",
};

export const FindingsContext = createContext<AssessmentFindings>(
  defaultFindingsContextValue
);

export const findingsQuery = (config: {
  assessmentPath: string;
  monitorId: string;
  scopeKey: string;
  state: string;
}) => {
  const constraints: QueryConstraint[] = [
    where("monitorId", "==", config.monitorId),
    where("state", "==", config.state),
    ...(config.scopeKey !== ALL_SCOPE_SENTINEL
      ? [where("scopeKey", "==", config.scopeKey)]
      : []),
  ];
  return query(
    collection(DB, config.assessmentPath, "findings"),
    ...constraints
  );
};

const isInRange = (
  trigger: Date,
  start: Date | null | undefined,
  end: Date | null | undefined
) =>
  // start & end are day-aligned, so broaden range to day starts and day ends
  (!start || trigger >= startOfDay(start)) &&
  (!end || trigger <= endOfDay(end));

export const FindingsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const fallback = (
    <FindingsContext.Provider
      value={{ ...defaultFindingsContextValue, loading: false }}
    >
      {children}
    </FindingsContext.Provider>
  );

  const { hasEnvironments } = useContext(EnvironmentContext);
  return hasEnvironments ? (
    <ErrorBoundary fallback={fallback}>
      <Provider>{children}</Provider>
    </ErrorBoundary>
  ) : (
    fallback
  );
};

const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [allMonitors, setAllMonitors] = useState<
    Record<string, MonitorWithCount>
  >({});
  const [selectedMonitor, setSelectedMonitor] = useState<MonitorWithFindings>();
  const { monitorId: selectedMonitorId } = useParams();

  const { last } = useContext(SelectedEnvironmentContext);
  const { scopeKey } = useContext(ScopeContext);
  const { selected } = useContext(EnvironmentContext);
  const assessmentId = selected?.assessmentId;
  const tenantId = useContext(Tenant);

  const [trigger, setTrigger] = useQueryParam(
    "trigger",
    withDefault(StringParam, "all")
  );
  const [start, setStart] = useQueryParam("start", DateParam);
  const [end, setEnd] = useQueryParam("end", DateParam);
  const [state, setState] = useQueryParam(
    "state",
    withDefault(StringParam, "open")
  );
  const setRange = useCallback(
    ([start, end]: DateRange) => {
      setStart(start);
      setEnd(end);
    },
    [setEnd, setStart]
  );

  const findingParams = `state=${state}&trigger=${trigger}${
    scopeKey !== ALL_SCOPE_SENTINEL ? `&scope=${scopeKey}` : ""
  }`;

  const assessmentPath = assessmentId
    ? toAssessmentPath(tenantId, assessmentId)
    : undefined;
  const { docs: customMonitors } = useFirestoreCollection<SavedMonitor>(
    assessmentPath ? `${assessmentPath}/monitors` : undefined,
    { live: true }
  );

  const monitors = useMemo(
    () => ({
      ...(PresetMonitors as Record<string, Monitor>),
      ...Object.fromEntries(
        (customMonitors ?? []).map(({ data, id }) => [
          id,
          convertSavedMonitor(data),
        ])
      ),
    }),
    [customMonitors]
  );

  useGuardedEffect(
    (cancellation) => async () => {
      if (!assessmentPath) return;

      setIsLoading(true);
      setAllMonitors({});

      const beforeAllMonitors = performance.now();

      const loadFindingCounts = async (monitorId: string, scopeKey: string) =>
        (
          await getCountFromServer(
            findingsQuery({ monitorId, state, scopeKey, assessmentPath })
          )
        ).data().count;

      const byMonitorPromises = Object.entries(monitors).map(
        async ([id, monitor]) =>
          [
            id,
            {
              ...monitor,
              count: await loadFindingCounts(id, scopeKey),
              monitorId: id,
            },
          ] as const
      );
      const monitorsWithCounts: Record<string, MonitorWithCount> =
        Object.fromEntries(await Promise.all(byMonitorPromises));

      // eslint-disable-next-line no-console
      console.log(
        "Time to load monitor counts",
        (performance.now() - beforeAllMonitors).toFixed(1),
        "ms"
      );

      if (cancellation.isCancelled) return;

      if (!selectedMonitorId) {
        setAllMonitors(monitorsWithCounts);
        setIsLoading(false);
        return;
      }

      const byScopePromises = (last.doc?.data.scope ?? []).map(
        async (scope) => {
          const scopeKey = toKey(scope);
          return [
            scopeKey,
            await loadFindingCounts(selectedMonitorId, scopeKey),
          ] as const;
        }
      );
      const countsByScope: Record<string, number> = Object.fromEntries(
        await Promise.all(byScopePromises)
      );

      const thisQuery = findingsQuery({
        monitorId: selectedMonitorId,
        state,
        scopeKey,
        assessmentPath,
      });
      const selectedFindings = selectedMonitorId
        ? (await getDocs(thisQuery)).docs
        : [];

      const scopedFindings = mapWith(selectedFindings, function* (d) {
        const data = d.data();
        if (
          isInRange(new Date(data.trigger.at), start, end) &&
          (trigger !== "new" || data.trigger.jobId === last.doc?.data.jobId)
        ) {
          yield { id: d.id, data, ref: d.ref } as FirestoreDoc<Finding>;
        }
      });

      const selectedMonitor: MonitorWithFindings = {
        monitorId: selectedMonitorId,
        ...monitors[selectedMonitorId],
        countsByScope,
        scopedFindings,
      };

      if (cancellation.isCancelled) return;

      setAllMonitors(monitorsWithCounts);
      setSelectedMonitor(selectedMonitor);
      setIsLoading(false);
    },
    [
      assessmentPath,
      customMonitors,
      end,
      last.doc?.data,
      monitors,
      scopeKey,
      selectedMonitorId,
      start,
      state,
      trigger,
    ],
    staticError
  );

  const [prioritized, archived] = useMemo(() => {
    const sorted = orderBy(
      Object.values(allMonitors),
      [(i) => MonitorRanking[i.priority], "count"],
      ["asc", "desc"]
    );
    return partition(sorted, (monitor) => !monitor.archived);
  }, [allMonitors]);

  return (
    <FindingsContext.Provider
      value={{
        allMonitors,
        archived,
        findingParams,
        loading: isLoading,
        prioritized,
        range: [start ?? undefined, end ?? undefined],
        selectedMonitor,
        setRange,
        setState,
        setTrigger,
        state: isa(FindingState, state) ? state : "open",
        trigger,
      }}
    >
      {children}
    </FindingsContext.Provider>
  );
};
