import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { ENV_SEARCH_PARAM } from "../environment";

export const useSelectedEnvironment = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSelectedEnvironment = useCallback(
    (envId: any) => {
      searchParams.set(ENV_SEARCH_PARAM, envId);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return [searchParams.get(ENV_SEARCH_PARAM), setSelectedEnvironment] as const;
};
