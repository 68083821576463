import { CopyOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Input,
  InputRef,
  Modal,
  Row,
  Typography,
  message,
} from "antd";
import { sub } from "date-fns";
import { pick } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, ControllerProps, useForm } from "react-hook-form";
import { ApiUser, AuthenticatedP0User } from "shared/types";

import { Cancellation } from "../../utils/cancel";
import { getTimestampFromApi } from "../../utils/firestore";
import { useAuthFetch, useUser } from "../Login/hook";
import { ApiKey, apiKeyData } from "./ApiKey";

type FormInputs = {
  name: string;
};

type ApiKeyResponse = {
  name: string;
  created: { _seconds: number; _nanoseconds: number };
  id: string;
  user?: ApiUser | AuthenticatedP0User;
}[];

const FORM_STEPS = {
  CREATE: "CREATE",
  ERROR: "ERROR",
  SAVE: "SAVE",
};

export const ApiKeysPanel: React.FC<{ isSandbox?: boolean }> = ({
  isSandbox,
}) => {
  const [error, setError] = useState<string>();
  const authFetch = useAuthFetch(setError);
  const { user } = useUser();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [formStep, setFormStep] = useState<string>(FORM_STEPS.CREATE);
  const [localApiKey, setLocalApiKey] = useState<string>("");
  const [apiKeys, setApiKeys] = useState<apiKeyData[]>([]);

  const { control, handleSubmit } = useForm<FormInputs>();

  const nameInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (user && !isSandbox) {
      const cancellation = new Cancellation();
      (async () => {
        const response = await authFetch("api-key/keys", { method: "GET" });
        if (!response) return;
        const data: ApiKeyResponse = await response.json();
        const formatted = data.map((apiKeyData) => {
          return {
            ...apiKeyData,
            created: getTimestampFromApi(apiKeyData.created).toDate(),
          };
        });
        cancellation.guard(setApiKeys)(formatted);
      })().catch((err) => setError(String(err)));
      return cancellation.cancel;
    }
  }, [authFetch, user, isSandbox]);

  const handleCloseModal = useCallback(() => {
    // Reset the data before closing the modal.
    setFormStep(FORM_STEPS.CREATE);
    setLocalApiKey("");
    setModalOpen(false);
  }, []);

  const handleFormSubmit = useCallback(
    async (input: any) => {
      setConfirmLoading(true);
      try {
        const response = await authFetch("api-key/keys", {
          method: "POST",
          json: input,
        });
        setConfirmLoading(false);

        if (!response) return;

        const data = await response.json();
        setLocalApiKey(data.rawApiKey);
        setFormStep(FORM_STEPS.SAVE);

        const newApiKey = pick(data, ["created", "id", "name", "user"]);
        newApiKey.created = getTimestampFromApi(newApiKey.created).toDate();
        setApiKeys([...apiKeys, newApiKey]);
      } catch (error) {
        setError(String(error));
        setFormStep(FORM_STEPS.ERROR);
      }
    },
    [authFetch, apiKeys]
  );

  const handleDeleteApiKey = useCallback(
    (keyId: string) => {
      authFetch(`api-key/keys/${keyId}`, {
        method: "DELETE",
      }).then(() => {
        const newApiKeys = apiKeys.filter((apiKey) => apiKey.id !== keyId);
        setApiKeys(newApiKeys);
      });
    },
    [apiKeys, authFetch]
  );

  const openModal = useCallback(() => {
    setModalOpen(true);
    nameInputRef.current?.focus();
  }, []);

  const renderCreateApiKey = useCallback<
    ControllerProps<FormInputs, "name">["render"]
  >(
    ({ field }) => (
      <Input {...field} ref={nameInputRef} placeholder="API key name" />
    ),
    []
  );

  const copyApiKey = useCallback(
    () =>
      navigator.clipboard
        .writeText(localApiKey)
        .then(() => message.success("Successfully copied key to clipboard")),
    [localApiKey]
  );

  return (
    <>
      {error && <Alert message={error} type="error" />}
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Typography.Title level={4}>Your API Keys</Typography.Title>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={openModal}
            disabled={isSandbox}
          >
            Create a new API key
          </Button>
        </Col>
        {(isSandbox ? sandboxApiKeys : apiKeys).map((apiKey) => (
          <Col span={8} key={apiKey.id}>
            <ApiKey
              {...apiKey}
              handleDelete={handleDeleteApiKey}
              isSandbox={isSandbox}
            />
          </Col>
        ))}
      </Row>
      <Modal
        title="Add a new API Key"
        open={modalOpen}
        okText={formStep === FORM_STEPS.CREATE ? "Submit" : "Ok"}
        onCancel={handleCloseModal}
        onOk={
          formStep === FORM_STEPS.CREATE
            ? handleSubmit(handleFormSubmit)
            : handleCloseModal
        }
        confirmLoading={confirmLoading}
      >
        {formStep === FORM_STEPS.CREATE ? (
          <>
            <p>
              API keys will be able to effectively operate as org owners, so
              keep the key secret. Also, we do not store the plain-text version
              of the key, so when your key is created, please copy and store the
              key securely.
            </p>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={renderCreateApiKey}
              />
            </form>
          </>
        ) : formStep === FORM_STEPS.SAVE ? (
          <>
            <p>
              Your API key was successfully created, make sure to save the
              following key somewhere safe, as we will not be able to retrieve
              it later. Treat it like a password, as it essentially is one.
            </p>
            <p>Here is your API key:</p>
            <Alert
              message={localApiKey}
              type="success"
              showIcon
              action={
                <Button onClick={copyApiKey} type="text">
                  <CopyOutlined />
                </Button>
              }
            />
          </>
        ) : (
          <p>
            Whoops. Looks like there was an error with your request. Please
            refresh and try again.
          </p>
        )}
      </Modal>
    </>
  );
};

const sandboxApiKeys: apiKeyData[] = [
  {
    name: "Sandbox API Key",
    created: new Date(),
    id: "1234567890",
    user: {
      email: "test@test.com",
      isAnonymous: false,
      uid: "1234567890",
    },
  },
  {
    name: "Sandbox API Key 2",
    created: sub(new Date(), {
      days: 10,
      hours: 2,
      minutes: 37,
      seconds: 12,
    }),
    id: "1234567891",
    user: {
      name: "Test API",
      uid: "1234567891",
    },
  },
];
