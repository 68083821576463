import { MonitorPriorityCounts } from "../types";

export const mockMonitorsByPriority: MonitorPriorityCounts = {
  CRITICAL: {
    totalCount: 48,
    byTimestamp: {
      "1738569600000": 48,
      "1738310400000": 50,
      "1737619200000": 50,
      "1738224000000": 50,
      "1736323200000": 50,
      "1736755200000": 50,
      "1737014400000": 50,
      "1737100800000": 50,
      "1737360000000": 50,
      "1735545600000": 60,
      "1735632000000": 60,
      "1735804800000": 60,
      "1736150400000": 150,
      "1734076800000": 150,
      "1734681600000": 150,
      "1733558400000": 150,
      "1733817600000": 150,
      "1733299200000": 150,
      "1733472000000": 150,
    },
  },
  HIGH: {
    totalCount: 133,
    byTimestamp: {
      "1737619200000": 133,
      "1738224000000": 133,
      "1738310400000": 133,
      "1738569600000": 133,
      "1735632000000": 200,
      "1735804800000": 200,
      "1736150400000": 200,
      "1736323200000": 200,
      "1736755200000": 200,
      "1737014400000": 200,
      "1737100800000": 200,
      "1737360000000": 200,
      "1735545600000": 200,
      "1733299200000": 200,
      "1733472000000": 200,
      "1733558400000": 200,
      "1733817600000": 200,
      "1734076800000": 200,
      "1734681600000": 500,
    },
  },
  MEDIUM: {
    totalCount: 309,
    byTimestamp: {},
  },
  LOW: {
    totalCount: 0,
    byTimestamp: {},
  },
};
