import {
  Alert,
  Card,
  Descriptions,
  Grid,
  Spin,
  Timeline,
  Tooltip,
  Typography,
} from "antd";
import { PermissionIntegrationLogo } from "components/Integrations/IntegrationLogo";
import SimpleTableView from "components/Jit/Routing/views/SimpleTableView";
import { FormattedDateTime } from "components/common/FormattedDateTime";
import { TenantAwareLink } from "components/common/TenantAwareLink";
import { format, formatDistance } from "date-fns";
import { capitalize } from "lodash";
import {
  useFirestoreCollection,
  useFirestoreDoc,
} from "providers/FirestoreProvider";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import { convertExpiryUnitToApprovalExpiryOption } from "shared/permission-requests/util";
import { isa } from "shared/types/is";
import {
  PermissionRequest,
  StagedPermissionRequest,
} from "shared/types/permission";
import {
  ActiveRequestStatuses,
  PendingRequestStatuses,
  RequestStatus,
  StagedRequestStatuses,
  TerminalRequestStatuses,
} from "shared/types/request-status";
import styled from "styled-components";

import { generatedDescription } from "../requestRender";
import {
  COLOR_MAP,
  extractApproverName,
  getRequestEvents,
  progressMessages,
  requestDescription,
  statusDescriptions,
} from "../requestUtils";
import { ConversationLink } from "./ConversationLink";
import { RequestNotFound } from "./RequestNotFound";
import { RequestStatusTag } from "./RequestStatusTag";

const LowerCard = styled(Card)`
  margin-top: 16px;
`;

const Span = styled("span")`
  margin-left: 8px;
`;

const notificationColor = "lightgrey";
const TIMELINE_COLOR_MAP: Record<RequestStatus, string> = {
  APPROVED_NOTIFIED: notificationColor,
  APPROVED: COLOR_MAP.Approved,
  CLEANED_UP: COLOR_MAP.Expired,
  CLEANUP_SUBMITTED: COLOR_MAP.Expiring,
  CLEANUP_ERRORED: COLOR_MAP.Errored,
  DENIED_NOTIFIED: notificationColor,
  DENIED: COLOR_MAP.Denied,
  DONE_NOTIFIED: notificationColor,
  DONE: COLOR_MAP.Granted,
  ERRORED_ERRORED: COLOR_MAP.Errored,
  ERRORED_NOTIFIED: notificationColor,
  ERRORED: COLOR_MAP.Errored,
  EXPIRED_NOTIFIED: notificationColor,
  EXPIRED: COLOR_MAP.Expired,
  EXPIRY_SUBMITTED: COLOR_MAP.Expiring,
  NEW: COLOR_MAP["Request Created"],
  PENDING_APPROVAL: COLOR_MAP["Pending Approval"],
  PENDING_APPROVAL_ESCALATED: COLOR_MAP["Pending Approval"],
  REVOKE_SUBMITTED: COLOR_MAP.Relinquishing,
  REVOKED_NOTIFIED: notificationColor,
  REVOKED: COLOR_MAP.Relinquished,
  STAGED: COLOR_MAP.Staged,
  TRANSLATED: COLOR_MAP["N/A"], // unused
};

const RequestDescriptionDiv = (props: {
  request: PermissionRequest;
  status?: RequestStatus; // optionally, render a description for a past status
}) => {
  const displayStatus = props.status ?? props.request.status;
  return (
    <div>
      {displayStatus === "PENDING_APPROVAL" ? (
        <>
          Sent request for approval to{" "}
          {props.request.notifications?.slack?.approvalConversationUrl ? (
            <a
              href={props.request.notifications?.slack?.approvalConversationUrl}
            >
              approvers
            </a>
          ) : (
            "approvers"
          )}
        </>
      ) : (
        statusDescriptions[displayStatus](props.request)
      )}
    </div>
  );
};

const formatPrincipalType = {
  group: "Group",
  user: "User",
  "service-account": "Service Account",
} as const;

export const RequestLogs: React.FC<{ requestId: string; tenantId: string }> = ({
  tenantId,
  requestId,
}) => {
  const { orgSlug } = useParams();
  const { docs: partialDocs } = useFirestoreCollection<PermissionRequest>(
    `o/${tenantId}/permission-requests/${requestId}/logs`,
    { live: true }
  );
  const { doc, loading } = useFirestoreDoc<PermissionRequest>(
    `o/${tenantId}/permission-requests/${requestId}`,
    { live: true }
  );
  const request = useMemo(() => doc?.data, [doc]);

  const routingRulesSnapshot = request?.routingRulesSnapshot ?? [];

  const { partialStatusDocs, completed } = useMemo(() => {
    if (!partialDocs) return { partialStatusDocs: [], isFinished: false };
    const { events, isComplete } = getRequestEvents(
      doc?.data,
      partialDocs ?? []
    );
    return { partialStatusDocs: events, completed: isComplete };
  }, [partialDocs, doc]);

  const generatedItems =
    request && isa(StagedRequestStatuses, request.status)
      ? // we expect request to be staged based on status
        generatedDescription(request as StagedPermissionRequest)
      : undefined;

  const { md } = Grid.useBreakpoint();

  return (
    <>
      {request ? (
        <>
          <Card
            title={
              <>
                {PermissionIntegrationLogo[request.type]}
                <Span>Request Info</Span>
              </>
            }
          >
            <Descriptions
              bordered
              column={md ? 2 : 1}
              labelStyle={{
                fontWeight: 700,
                width: "15%",
              }}
              contentStyle={{ width: "35%" }}
              colon
              layout={"horizontal"}
            >
              <Descriptions.Item label="Description">
                {requestDescription(request)}
                <ConversationLink
                  url={request.notifications?.slack?.approvalConversationUrl}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Principal Type">
                {"principal-type" in request.permission
                  ? formatPrincipalType[request.permission["principal-type"]]
                  : "User"}
              </Descriptions.Item>
              <Descriptions.Item label="Principal">
                {request.principal}
              </Descriptions.Item>
              <Descriptions.Item label="Requestor">
                {request.requestor}
              </Descriptions.Item>
              <Descriptions.Item label="Approver">
                {extractApproverName(request, true)}
              </Descriptions.Item>
              <Descriptions.Item label="Requested At">
                <FormattedDateTime timestamp={request.requestedTimestamp} />
              </Descriptions.Item>
              <Descriptions.Item label="Granted At">
                {request.grantTimestamp ? (
                  <FormattedDateTime timestamp={request.grantTimestamp} />
                ) : (
                  `Not Available`
                )}
              </Descriptions.Item>
              {isa(TerminalRequestStatuses, request.status) &&
                (request.revokedTimestamp || request.expiryTimestamp) && (
                  <Descriptions.Item label={"Expired At"}>
                    {request.revokedTimestamp ? (
                      <FormattedDateTime timestamp={request.revokedTimestamp} />
                    ) : request.expiryTimestamp ? (
                      <FormattedDateTime timestamp={request.expiryTimestamp} />
                    ) : (
                      `Not Available`
                    )}
                  </Descriptions.Item>
                )}
              {isa(PendingRequestStatuses, request.status) &&
              request.requestedDuration ? (
                <Descriptions.Item label={"Requested Duration"}>
                  {
                    convertExpiryUnitToApprovalExpiryOption(
                      request.requestedDuration
                    ).value
                  }
                </Descriptions.Item>
              ) : isa(ActiveRequestStatuses, request.status) &&
                request.expiryTimestamp ? (
                <Descriptions.Item label={"Expires In"}>
                  <Tooltip
                    key="expiry"
                    title={format(request.expiryTimestamp, "PPpp")}
                  >
                    <Typography.Text>
                      {capitalize(
                        formatDistance(request.expiryTimestamp, Date.now())
                      )}
                    </Typography.Text>
                  </Tooltip>
                </Descriptions.Item>
              ) : (
                <Descriptions.Item label={"Access Duration"}>
                  {request.grantTimestamp && request.revokedTimestamp
                    ? formatDistance(
                        request.revokedTimestamp,
                        request.grantTimestamp
                      )
                    : `Not Available`}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Most Recent Action">
                <RequestDescriptionDiv request={request} />
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <RequestStatusTag status={request.status} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
          {generatedItems && (
            <Card title="Access Properties">
              <Descriptions layout="vertical" bordered>
                {generatedItems.map((item) => (
                  <Descriptions.Item key={item.label} label={item.label}>
                    {item.content}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </Card>
          )}
          <LowerCard title="History">
            <Timeline
              pending={!completed ? progressMessages[request.status] : null}
              reverse={true}
            >
              {partialStatusDocs &&
                partialStatusDocs.map((partialRequestDoc) => {
                  return (
                    <Timeline.Item
                      color={
                        TIMELINE_COLOR_MAP[partialRequestDoc.initialStatus]
                      }
                      key={partialRequestDoc.initialStatus}
                    >
                      <FormattedDateTime
                        timestamp={
                          partialRequestDoc.updates?.lastUpdatedTimestamp ?? 0
                        }
                      />
                      <RequestDescriptionDiv
                        request={request}
                        status={partialRequestDoc.initialStatus}
                      />
                    </Timeline.Item>
                  );
                })}
            </Timeline>
          </LowerCard>
          <LowerCard title="Routing rules snapshot">
            <Typography.Paragraph>
              This is a snapshot of the routing rules that existed at the time
              the request was created <em>and</em> which applied to the
              requestor or the resource. The routing rules configuration may
              have changed since this request was created; the current
              configuration can be found at{" "}
              <TenantAwareLink to="routing">Routing</TenantAwareLink>.
            </Typography.Paragraph>
            {routingRulesSnapshot.length === 0 ? (
              <Alert
                message="Routing rules snapshot not available"
                description="This request is old and did not capture a routing rules snapshot."
                type="warning"
                showIcon
              />
            ) : (
              <SimpleTableView jsonRules={routingRulesSnapshot} />
            )}
          </LowerCard>
        </>
      ) : loading ? (
        <Spin />
      ) : (
        <RequestNotFound orgSlug={orgSlug} />
      )}
    </>
  );
};
