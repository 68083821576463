import { SECONDS } from "../time";
import { Unsubscribe } from "../types";
import { Cancellation } from "../types/async";

const DEFAULT_EVENT_BLOCK_MS = 1 * SECONDS;

export const sleep = (
  millis: number
): Promise<void> & { cancel: Unsubscribe } => {
  let timeout: NodeJS.Timeout;
  const promise = new Promise<void>((resolve) => {
    timeout = setTimeout(resolve, millis);
  });
  const cancel = () => clearTimeout(timeout);
  return Object.assign(promise, { cancel });
};

/** Runs a loop, but yields the event loop if too much time has elapsed */
export const eventYieldingFor = async <T>(
  iterable: Iterable<T>,
  inner: (value: T) => Promise<void> | void,
  options?: {
    cancellation?: Cancellation;
    maxOccupancyMs?: number;
    onSleep?: (value: T, index: number) => void;
    yieldMs?: number;
  }
) => {
  let last = Date.now();
  let ix = 0;
  for (const value of iterable) {
    if (
      Date.now() - last >
      (options?.maxOccupancyMs ?? DEFAULT_EVENT_BLOCK_MS)
    ) {
      if (options?.cancellation?.isCancelled) return;
      options?.onSleep?.(value, ix);
      // Need sleep of at least 1 ms to actually yield, otherwise the timer
      // resolves immediately
      await sleep(options?.yieldMs ?? 1);
      last = Date.now();
    }
    const result = inner(value);
    if (typeof result === "object" && "then" in result) {
      await result;
    }
    ix++;
  }
};
