import { Alert, Spin } from "antd";
import Link from "antd/lib/typography/Link";
import { useAuthFetch } from "components/Login/hook";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { KnownFirestoreDoc } from "providers/FirestoreProvider";
import React, { useState } from "react";
import {
  GithubIntegration,
  ValidateInstallResponse,
} from "shared/integrations/resources/github/types";

export const GithubInstallStatus: React.FC<{
  integration: KnownFirestoreDoc<GithubIntegration>;
}> = ({ integration }) => {
  // Use our own error state within this component so we can show a more useful error message specific to the failure
  // case here.
  const [error, setError] = useState<any>();
  const [installStatus, setInstallStatus] = useState<
    ValidateInstallResponse | undefined
  >(undefined);
  const authFetch = useAuthFetch(setError);

  useGuardedEffect(
    (cancellation) => async () => {
      const response = await authFetch("integrations/github/validate", {
        method: "POST",
      });

      if (!response) return;

      const data = await response.json();
      cancellation.guard(setInstallStatus)(data);
    },
    [authFetch],
    setError
  );

  return !!error ? (
    <Alert
      message="Failed to get installation data from GitHub. This usually means that the P0 GitHub app has been
      removed from your organization. The integration will not work correctly. Please try uninstalling and
      re-installing."
      showIcon
      type="error"
    />
  ) : !!installStatus ? (
    integration.doc.data.state === "configure" ? (
      <Alert
        message="Installation in progress"
        description={
          <>
            P0&apos;s GitHub app is installed to organization{" "}
            <Link href={installStatus.orgUrl} target="_blank">
              {installStatus.orgName}
            </Link>
            , but the integration is not yet configured. Please configure the
            integration below to complete installation.
          </>
        }
        type="warning"
        showIcon
      />
    ) : (
      <Alert
        message={
          <>
            Integration is installed to GitHub Organization{" "}
            <Link href={installStatus.orgUrl} target="_blank">
              {installStatus.orgName}
            </Link>{" "}
            and active
          </>
        }
        type="success"
        showIcon
      />
    )
  ) : (
    <Spin />
  );
};
