import { FindingsContext } from "components/Assessment/contexts/FindingsContext";
import { useNavigateWithEnv } from "components/Assessment/hooks/useNavigateWithEnv";
import { TooltipCode } from "components/GraphTable/GraphSearch";
import { startOfDay } from "date-fns";
import { cloneDeep, get, size } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { AppPaths } from "shared/routes/constants";
import { DAYS } from "shared/time";
import { FindingState } from "shared/types/assessment/finding";
import { MonitorPriority } from "shared/types/assessment/monitor";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";

import { useDashboardFindings } from "../hooks/useDashboardFindings";
import { MonitorPriorityCounts } from "../types";
import { mockMonitorsByPriority } from "../zeroStateData/findings-zero-state";
import { FindingGraph } from "./FindingGraph";
import { OpenFindings } from "./OpenFindings";
import { Pane } from "./Pane";

const MAX_FINDINGS_LOOKBACK = 180 * DAYS;

export const FindingsBySeverity: React.FC<{
  isLoading: boolean;
  hasEnvironments: boolean;
}> = ({ isLoading, hasEnvironments }) => {
  const [selectedSeverities, setSelectedSeverities] = useState<
    Record<MonitorPriority, boolean>
  >({
    CRITICAL: true,
    HIGH: true,
    MEDIUM: false,
    LOW: false,
  });

  const findingMinTimestamp =
    startOfDay(Date.now()).getMilliseconds() - MAX_FINDINGS_LOOKBACK;

  const { findings, loading: findingsLoading } =
    useDashboardFindings(findingMinTimestamp);
  const { allMonitors } = useContext(FindingsContext);
  const allTimestamps = useMemo(() => {
    const timestamps = (findings ?? [])
      .flatMap((finding) => finding.data?.history ?? [])
      .map((history) => startOfDay(history.at).getTime())
      .filter((t) => t >= findingMinTimestamp); // Filter out any timestamps older than the lookback

    return [...new Set(timestamps)].sort((a, b) => a - b);
  }, [findings, findingMinTimestamp]);

  const monitorsByPriority = useMemo(() => {
    const initialStructure: MonitorPriorityCounts = {
      CRITICAL: { totalCount: 0, byTimestamp: {} },
      HIGH: { totalCount: 0, byTimestamp: {} },
      MEDIUM: { totalCount: 0, byTimestamp: {} },
      LOW: { totalCount: 0, byTimestamp: {} },
    };

    const output: MonitorPriorityCounts = (findings ?? []).reduce(
      (acc, finding) => {
        const monitorId = finding.data.monitorId;
        // Discard any findings that we cannot find a monitor for
        if (!monitorId || !(monitorId in allMonitors)) return acc;
        const priority = allMonitors[monitorId].priority;
        if (!priority) return acc;
        if (!acc[priority]) {
          acc[priority] = cloneDeep(initialStructure[priority]);
        }

        if (finding.data.state === "open") acc[priority].totalCount += 1;
        // Update count by timestamp
        const firstSeen =
          size(finding.data.history ?? []) > 1
            ? finding.data.history?.at(0)?.at
            : 0;

        // If finding is open, we use the last item in the history to see the last seen date
        // If finding is ignored, we use the ignored date
        // If finding is resolved, the most recent trigger should be 'fix' (otherwise it would be open)
        // so we use the fixed time
        // If we don't know the last seen date, we will exclude this finding
        const lastSeen =
          finding.data.state === "open"
            ? finding.data.history.at(-1)?.at
            : finding.data.state === "ignored"
            ? finding.data.ignore?.at
            : finding.data.state === "resolved" &&
              finding.data.trigger?.type === "fix"
            ? finding.data.trigger?.at
            : undefined;

        if (!lastSeen) {
          return acc;
        }

        const firstSeenDay = startOfDay(firstSeen || lastSeen).getTime();
        const lastSeenDay = startOfDay(lastSeen).getTime();

        for (const dayTimestamp of allTimestamps) {
          // Since dayTimestamp is sorted ascending, we can break early
          if (dayTimestamp > lastSeenDay) {
            break;
          }
          if (dayTimestamp >= firstSeenDay && dayTimestamp <= lastSeenDay) {
            acc[priority].byTimestamp[dayTimestamp] =
              get(acc[priority].byTimestamp, dayTimestamp, 0) + 1;
          }
        }

        return acc;
      },
      cloneDeep(initialStructure)
    );

    return output;
  }, [allTimestamps, findings, allMonitors]);

  const monitorsEmpty = useMemo(
    () =>
      Object.values(monitorsByPriority).every(
        (priority) => priority.totalCount === 0
      ),
    [monitorsByPriority]
  );
  const [scopeKey, _] = useQueryParam(
    "state",
    withDefault(createEnumParam([...FindingState]), "open")
  );

  const navigate = useNavigateWithEnv();
  const handleViewFindings = useCallback(() => {
    navigate(`../${AppPaths.Posture}?state=${scopeKey}`);
  }, [navigate, scopeKey]);

  const handleSeverityChange = useCallback((v: MonitorPriority) => {
    setSelectedSeverities((prev) => ({
      ...prev,
      [v]: !prev[v],
    }));
  }, []);

  const loading = useMemo(
    () => isLoading || (hasEnvironments && monitorsEmpty) || findingsLoading,
    [isLoading, hasEnvironments, monitorsEmpty, findingsLoading]
  );

  return (
    <Pane
      title="Open Findings"
      showMore={{ text: "View in Posture", onClick: handleViewFindings }}
      tooltipPayload={
        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
          <li>
            <TooltipCode>Urgent</TooltipCode>&nbsp;&minus;&nbsp;Urgent findings
            indicate the presence of a security vulnerability and require
            action.
          </li>
          <li>
            <TooltipCode>High</TooltipCode>&nbsp;&minus;&nbsp;High findings
            indicate that security best practices are being violated. A
            vulnerability may or may not be present.
          </li>
          <li>
            <TooltipCode>Medium</TooltipCode>&nbsp;&minus;&nbsp;Medium findings
            are informational and can be used to further understand your
            security posture.
          </li>
          <li>
            <TooltipCode>Low</TooltipCode>&nbsp;&minus;&nbsp;Low findings
            present no security risk and can be used to inventory your system.
          </li>
        </ul>
      }
    >
      <div style={{ display: "flex", width: "100%" }}>
        <OpenFindings
          monitorsByPriority={
            hasEnvironments ? monitorsByPriority : mockMonitorsByPriority
          }
          isLoading={loading}
          handleChange={handleSeverityChange}
          selectedSeverities={selectedSeverities}
        />
        <FindingGraph
          monitors={
            hasEnvironments ? monitorsByPriority : mockMonitorsByPriority
          }
          selectedSeverities={selectedSeverities}
          isLoading={loading}
        />
      </div>
    </Pane>
  );
};
