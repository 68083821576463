import { size } from "lodash";
import { useFirestoreDoc } from "providers/FirestoreProvider";
import { useMemo } from "react";
import { installedItems } from "shared/install/installed";
import { AwsIntegration } from "shared/integrations/resources/aws/types";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";
import { AssessmentScopeIntegration } from "shared/types/assessment";

export const useInstalledIntegrations = () => {
  const { doc: gcloudDoc, loading: gcloudLoading } =
    useFirestoreDoc<GcloudIntegration>(`integrations/gcloud`, {
      live: true,
      tenantAware: true,
    });

  const { doc: awsDoc, loading: awsLoading } = useFirestoreDoc<AwsIntegration>(
    `integrations/aws`,
    {
      live: true,
      tenantAware: true,
    }
  );

  const installed: Record<AssessmentScopeIntegration, boolean> = useMemo(
    () => ({
      aws: !!size(installedItems("iam-assessment", awsDoc?.data ?? {})),
      gcloud: !!(
        size(installedItems("iam-assessment", gcloudDoc?.data ?? {})) ||
        size(installedItems("org-iam-assessment", gcloudDoc?.data ?? {}))
      ),
      k8s: false, //TODO: Update to make this reflect accurately
      workspace: false,
    }),
    [awsDoc, gcloudDoc]
  );

  const loading = awsLoading || gcloudLoading;

  return { loading, installed };
};
