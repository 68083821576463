import { SaveFilled } from "@ant-design/icons";
import { Button, Form, Input, Typography, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useAuthFetch } from "components/Login/hook";
import { KnownFirestoreDoc } from "providers/FirestoreProvider";
import { useCallback, useState } from "react";
import {
  ConfigureRequest,
  GithubIntegration,
} from "shared/integrations/resources/github/types";

const DEFAULT_ATTRIBUTE_NAME = "githubUserName";

type FormValues = { attributeName: string };

export const GithubConfigurer: React.FC<{
  authFetch: ReturnType<typeof useAuthFetch>;
  integration: KnownFirestoreDoc<GithubIntegration>;
}> = ({ authFetch, integration }) => {
  const integrationState = integration.doc.data.state;
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [oktaAttributeForm] = useForm<FormValues>();

  const save = useCallback(
    async ({ attributeName }: FormValues) => {
      setIsFetching(true);

      const request: ConfigureRequest = {
        githubUsernameInOktaAttribute: attributeName,
      };

      await authFetch("integrations/github/configuration", {
        method: "PUT",
        json: request,
      });

      setIsFetching(false);
      await message.success("Updated integration configuration");
    },
    [authFetch, setIsFetching]
  );

  const showError = useCallback(async () => {
    await message.error("Please complete the required fields.");
  }, []);

  const getInitialValue = useCallback(() => {
    if (integration?.doc?.data?.state === "installed")
      return integration.doc.data.githubUsernameInOktaAttribute;

    return DEFAULT_ATTRIBUTE_NAME;
  }, [integration]);

  return (
    <div style={{ marginTop: 20 }}>
      <Typography.Title level={3}>
        {integrationState === "configure"
          ? "Finish installation"
          : "Configuration"}
      </Typography.Title>
      <Form form={oktaAttributeForm} onFinish={save} onFinishFailed={showError}>
        <Typography.Text>
          Enter the name of the user profile attribute in Okta that contains
          users&apos; GitHub username. This will be used to map P0 users to
          GitHub. The attribute must exist on the user profile schema.
        </Typography.Text>
        <Form.Item
          label="Attribute name"
          name="attributeName"
          required
          rules={[
            { required: true, message: "Attribute name must be specified" },
          ]}
          initialValue={getInitialValue()}
        >
          <Input />
        </Form.Item>
        <Button
          icon={<SaveFilled />}
          type="primary"
          disabled={isFetching}
          htmlType="submit"
        >
          {integrationState === "configure" ? "Finish install" : "Save"}
        </Button>
      </Form>
    </div>
  );
};
