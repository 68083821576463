import { Select, Typography } from "antd";
import { useCallback, useContext, useEffect } from "react";
import { AssessmentScopeIntegration } from "shared/types/assessment";
import { integrationLabels } from "shared/types/workflow/constants";

import { EnvironmentCreationContext } from "../contexts/EnvironmentCreationContext";
import { targetLogo } from "./TargetLogo";

type NewAssessmentIntegrationSelectProps = {
  availableIntegrations: AssessmentScopeIntegration[];
};

const NewAssessmentIntegrationSelect = ({
  availableIntegrations,
}: NewAssessmentIntegrationSelectProps) => {
  const { currentIntegration, setCurrentIntegration } = useContext(
    EnvironmentCreationContext
  );
  const getTargetLogo = useCallback(
    (integration: AssessmentScopeIntegration) => targetLogo(integration),
    []
  );
  const selectProps = {
    placeholder: "Select an integration",
    onChange: (selectedValues: AssessmentScopeIntegration) => {
      setCurrentIntegration(selectedValues);
    },
    options: availableIntegrations.map((scope) => {
      return {
        value: scope,
        label: (
          <>
            {getTargetLogo(scope)} {integrationLabels[scope]}
          </>
        ),
      };
    }),
    value: currentIntegration,
  };

  useEffect(() => {
    if (availableIntegrations.length === 0) {
      setCurrentIntegration(undefined);
    }
    if (
      currentIntegration &&
      !availableIntegrations.includes(currentIntegration)
    ) {
      setCurrentIntegration(availableIntegrations[0]);
    }
  }, [availableIntegrations, currentIntegration, setCurrentIntegration]);

  return (
    <>
      <Typography.Paragraph style={{ marginBottom: "6px" }}>
        What should P0 assess?
      </Typography.Paragraph>
      <Select style={{ width: "100%", marginBottom: "6px" }} {...selectProps} />
    </>
  );
};

export default NewAssessmentIntegrationSelect;
