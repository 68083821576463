import { CSSProperties } from "react";

/** Optionally masks child input
 *
 * Child input is covered with a translucent div, preventing triggers.
 */
export const Mask: React.FC<
  React.PropsWithChildren<
    React.HTMLAttributes<HTMLDivElement> & {
      maskStyle?: CSSProperties;
      show?: boolean;
    }
  >
> = ({ children, show, maskStyle, style, ...containerProps }) => (
  <div {...containerProps} style={{ ...style, position: "relative" }}>
    {children}
    <div
      style={{
        backgroundColor: show ? "rgba(0,0,0, 10%)" : "rgba(0, 0, 0, 0%)",
        // Place after backgroundColor to allow override
        ...maskStyle,
        height: "100%",
        left: "0",
        position: "absolute",
        top: "0",
        visibility: show ? undefined : "hidden",
        width: "100%",
      }}
    />
  </div>
);
