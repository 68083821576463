import { Skeleton } from "antd";
import { priorityColor } from "components/Assessment/components/monitor/MonitorSeverity";
import { VerticalDiv } from "components/divs";
import { useCallback } from "react";
import { priorityLabels } from "shared/assessment/constants";
import { MonitorPriority } from "shared/types/assessment/monitor";
import { widetype } from "shared/util/collections";
import { colors } from "styles/variables";

import { MonitorPriorityCounts } from "../types";
import { FilterStatistic } from "./common/FilterStatistic";

export const OpenFindings: React.FC<{
  monitorsByPriority: MonitorPriorityCounts;
  isLoading: boolean;
  handleChange: (value: MonitorPriority) => void;
  selectedSeverities: Record<MonitorPriority, boolean>;
}> = ({ isLoading, handleChange, selectedSeverities, monitorsByPriority }) => (
  <VerticalDiv
    style={{
      gap: "8px",
      width: "30%",
      maxWidth: "20em",
      marginTop: "1em",
      marginLeft: "1em",
      justifyContent: "center",
    }}
  >
    {widetype.entries(priorityColor).map(([priority]) => {
      return isLoading ? (
        <Skeleton.Input active style={{ marginTop: ".5em" }} key={priority} />
      ) : (
        <Finding
          key={priority}
          priority={priority}
          count={monitorsByPriority[priority].totalCount}
          handleClick={handleChange}
          selected={selectedSeverities[priority]}
        />
      );
    })}
  </VerticalDiv>
);

const Finding: React.FC<{
  priority: MonitorPriority;
  handleClick: (value: MonitorPriority) => void;
  selected: boolean;
  count: number;
}> = ({ priority, handleClick, selected, count }) => {
  const { color } = priorityColor[priority];
  const onClick = useCallback(() => {
    handleClick(priority);
  }, [handleClick, priority]);
  return (
    <FilterStatistic
      category={priorityLabels[priority]}
      color={color}
      count={count}
      selected={selected}
      textColor={colors.neutral["00"]}
      onClick={onClick}
      showEye={true}
    />
  );
};
