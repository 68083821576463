// This component is used to add the environment query parameter to all links in the CAGA work.
import { Navigate, NavigateProps } from "react-router-dom";

import { addEnvironmentToPath } from "../environment";
import { useSelectedEnvironment } from "../hooks/useSelectedEnvironment";

//To use properly, leave all the environment query parameter handling to this component.
export const NavigateWithEnvironment = ({ to, ...props }: NavigateProps) => {
  const [selectedEnvironment] = useSelectedEnvironment();

  const newTo = addEnvironmentToPath(to, selectedEnvironment);
  return <Navigate replace to={newTo} {...props} />;
};
