import { Modal, Typography } from "antd";

import { MonitorWithMetadata } from "../contexts/FindingsContext";
import { EditMonitorForm } from "./MonitorAdmin";

export const EditMonitorModal: React.FC<{
  editModalOpen: boolean;
  closeEditModal: () => void;
  modalMonitor: MonitorWithMetadata | undefined;
}> = ({ editModalOpen, closeEditModal, modalMonitor }) => {
  return (
    <Modal open={editModalOpen} footer={false} onCancel={closeEditModal}>
      <Typography.Title level={4}>Edit Monitor</Typography.Title>
      <EditMonitorForm
        monitor={modalMonitor}
        show={modalMonitor?.show ?? "grant"}
        onSubmitForm={closeEditModal}
      />
    </Modal>
  );
};
