import { priorityLabels } from "shared/assessment/constants";
import { MonitorPriority } from "shared/types/assessment/monitor";
import { MonitorBase } from "shared/types/assessment/monitor";
import { GreyTag, OrangeTag, RedTag, YellowTag } from "styles/StyledTags";
import { colors } from "styles/variables";

export const severityChartLabels = ["Urgent", "High", "Medium", "Low"] as const;

export type SeverityLabel = (typeof severityChartLabels)[number];

export const priorityColor: Record<
  MonitorPriority,
  {
    color: string;
    text: string;
    tag: React.FC<{ content: string }>;
  }
> = {
  CRITICAL: {
    color: colors.themeColors.red,
    text: "#5c0011",
    tag: RedTag,
  },
  HIGH: {
    color: colors.themeColors.lightOrange,
    text: "#614700",
    tag: OrangeTag,
  },
  MEDIUM: {
    color: colors.themeColors.yellow,
    text: "#ffffff",
    tag: YellowTag,
  },
  LOW: {
    color: colors.themeColors.darkBlue,
    text: "#595959",
    tag: GreyTag,
  },
};

export const MonitorSeverity: React.FC<{ monitor: MonitorBase }> = ({
  monitor,
}) => {
  const TagComponent = priorityColor[monitor.priority].tag;
  return <TagComponent content={priorityLabels[monitor.priority]} />;
};
