import { Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import { Node } from "shared/graph/types";

import { LinkWithEnvironment } from "../LinkWithEnvironment";

const { Text } = Typography;

export const DetailsColumn = (
  fromData: (
    data: Node<any, any>
  ) => { disabled?: boolean; key: string; to: string } | undefined
): ColumnType<Node<any, any>> => ({
  align: "center" as const,
  title: "",
  render: (_: any, data: Node<any, any>) => {
    const props = fromData(data);
    return (
      props &&
      (props.disabled ? (
        <Text type="secondary">view</Text>
      ) : (
        <LinkWithEnvironment {...props}>view</LinkWithEnvironment>
      ))
    );
  },
});
