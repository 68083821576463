import { CatalogContext } from "components/Catalog/context";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { capitalize } from "lodash";
import { ReactNode, useContext } from "react";
import { grantNodeToPermissionSet } from "shared/assessment/render";
import { Node, isNode } from "shared/graph/types";
import { ProviderOrAll } from "shared/types/assessment";
import {
  AssessmentNodes,
  GrantNode,
  IdentityNode,
  NodeFor,
} from "shared/types/assessment/data";

import { PermissionLink } from "../../components/cells/PermissionLink";
import { toConsumerText } from "../cells/ConsumersList";
import { CredentialDisplay } from "../cells/Credential";
import { IdentityCell } from "../cells/Identity";
import { CellInner } from "../cells/Inner";
import { Lateral } from "../cells/Lateral";
import {
  RiskGroupedPermissionList,
  toPermissionAggregate,
} from "../cells/PermissionAggregate";
import { Resource } from "../cells/Resource";
import { RiskLink } from "../cells/RiskLink";

const HydratedRiskLink: React.FC<{ id: string }> = ({ id }) => {
  const { risks } = useContext(CatalogContext);
  return id in risks ? <RiskLink risk={risks[id]} /> : <>{id}</>;
};

export const NodeTitler =
  (provider: ProviderOrAll) =>
  (node: Node<AssessmentNodes, keyof AssessmentNodes>) =>
    capitalize(NodeLabel(node, provider));

export const NodeLabel = (
  node: Node<AssessmentNodes, keyof AssessmentNodes>,
  provider: ProviderOrAll
) =>
  node.type === "grant"
    ? "grant"
    : node.type === "permissionSet" && provider === "gcp"
    ? "role"
    : node.type === "usage"
    ? "permissions"
    : node.type;

export const NodeText = (
  current: NodeFor<keyof AssessmentNodes> | undefined,
  provider: ProviderOrAll,
  options?: { detailed?: boolean; hideThis?: boolean; noHover?: boolean }
): { [K in keyof AssessmentNodes]?: (node: NodeFor<K>) => ReactNode } => ({
  consumer: (n) => toConsumerText(n.data),
  credential: (n) => <CredentialDisplay credential={n.data} id={n.key} />,
  grant: (n) => {
    const main =
      !options?.hideThis && current?.type === "grant" && current?.key === n.key
        ? "This grant"
        : grantNodeToPermissionSet(n);

    const content = (
      <>
        <div>{main}</div>
        <CellInner>
          on <Resource resource={n} index={0} />
        </CellInner>
      </>
    );
    const detail = (
      <CellInner>
        {content} to{" "}
        <IdentityCell
          identity={{
            ...n.data,
            label: n.data.principal,
            type: n.data.principalType,
          }}
        />
      </CellInner>
    );

    return options?.detailed ? (
      detail
    ) : options?.noHover ? (
      content
    ) : (
      <GraphTooltip width="400px" title={detail}>
        {content}
      </GraphTooltip>
    );
  },
  lateral: (n) => <Lateral node={n} />,
  usage: (n) => {
    const permissions =
      (current as GrantNode | IdentityNode)?.aggregates?.permissions ??
      toPermissionAggregate(n);
    // If `count` on data, then this is all-targets view, and the permission
    // aggregate will always be empty
    const hoverElement = !n.data.count && (
      <RiskGroupedPermissionList
        permissions={permissions}
        initialSelected={n.data.type}
        provider={provider}
      />
    );
    const labelElement = (
      <>
        {n.data.type === "unknown"
          ? "Potentially used"
          : capitalize(n.data.type)}{" "}
        permissions (
        {n.data.count ?? n.children.filter(isNode("permission")).length})
      </>
    );
    return hoverElement ? (
      <GraphTooltip width="500px" title={hoverElement}>
        {labelElement}
      </GraphTooltip>
    ) : (
      labelElement
    );
  },
  permission: (n) =>
    provider === "all" ? (
      n.key
    ) : (
      <PermissionLink key={n.key} permission={n.key} provider={provider} />
    ),
  identity: (n) => (
    <IdentityCell identity={n.data} noHover={options?.noHover} />
  ),
  resource: (n) => <Resource resource={n} shortPath />,
  risk: (n) => <HydratedRiskLink key={n.key} id={n.key} />,
});
