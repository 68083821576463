import { Button, Modal } from "antd";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { AnyNode } from "shared/types/assessment/data";

import { MonitorActionProps } from "../pages/MonitorResults";
import { FindingsFixOrRevertCommands } from "./FindingsFixOrRevertCommands";

/** Displays commands used to fix findings / revert fixes */
export const FindingsFixOrRevertModal: React.FC<MonitorActionProps> = ({
  monitor,
  state,
  allNodes,
  selectedNodes,
}) => {
  const [modalContent, setModalContent] = useState<ReactNode>();
  const showFix = useCallback(
    (nodes?: AnyNode[]) => () => {
      if (!nodes) return;
      setModalContent(
        <FindingsFixOrRevertCommands
          monitor={monitor}
          nodes={nodes}
          findingState={state}
        />
      );
    },
    [state, monitor]
  );

  const onFix = useMemo(
    () => showFix(selectedNodes?.length ? selectedNodes : allNodes),
    [allNodes, selectedNodes, showFix]
  );
  const onCancelFix = useCallback(() => setModalContent(undefined), []);

  const label = state === "open" ? "🔧\u00a0Fix" : "↩️\u00a0Revert";

  return (
    <>
      <Button disabled={!allNodes?.length} onClick={onFix}>
        {label}
      </Button>
      <Modal
        maskClosable={false}
        open={!!modalContent}
        onCancel={onCancelFix}
        width={"60em"} // Similar to a terminal window
        footer={false}
      >
        {modalContent}
      </Modal>
    </>
  );
};
