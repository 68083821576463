import { SelectedEnvironmentContext } from "components/Assessment/contexts/SelectedEnvironmentContext";
import { AuthFetch, useUser } from "components/Login/hook";
import { updateDoc } from "firebase/firestore";
import { FirestoreDoc } from "providers/FirestoreProvider";
import { useCallback, useContext, useMemo } from "react";
import { Finding } from "shared/types/assessment/finding";

type IgnoreCallbacks = {
  /** Marks one or more findings as "ignored" */
  ignoreFindings: (
    findings: FirestoreDoc<Finding>[],
    justification?: string
  ) => Promise<void>;

  /** Marks one or more findings as "open" or "resolved"
   *
   * End state is chosen based on whether the finding would be open in
   * the most recent run
   */
  unignoreFindings: (findings: FirestoreDoc<Finding>[]) => Promise<void>;

  /** Assigns one or more findings to users */
  assignFindings: (
    findings: FirestoreDoc<Finding>[]
  ) => Promise<Response | undefined>;

  /** Begins managing one or more findings */
  manageFindings: (
    findings: FirestoreDoc<Finding>[],
    secret: string,
    owner: string
  ) => Promise<Response | undefined>;

  /** Unassigns one or more findings from users */
  unassignFindings: (
    findings: FirestoreDoc<Finding>[]
  ) => Promise<Response | undefined>;

  /** Updates notes for one or more findings without changing state */
  updateFindingsNotes: (
    findings: FirestoreDoc<Finding>[],
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => Promise<void>;
};

/** Provides callbacks for mutating finding state & justification
 */
export function useFindingsStateUpdates(): Omit<
  IgnoreCallbacks,
  "assignFindings" | "manageFindings" | "unassignFindings"
>;
export function useFindingsStateUpdates(authFetch: AuthFetch): IgnoreCallbacks;
export function useFindingsStateUpdates(authFetch?: AuthFetch): Omit<
  IgnoreCallbacks,
  "assignFindings" | "manageFindings" | "unassignFindings"
> & {
  assignFindings: IgnoreCallbacks["assignFindings"] | undefined;
  manageFindings: IgnoreCallbacks["manageFindings"] | undefined;
  unassignFindings: IgnoreCallbacks["unassignFindings"] | undefined;
} {
  const { last } = useContext(SelectedEnvironmentContext);
  const { user } = useUser();

  const ignoreFindings = useCallback<IgnoreCallbacks["ignoreFindings"]>(
    async (findings, justification) => {
      const promises = findings.map(
        async (f) =>
          await updateDoc(f.ref, {
            state: "ignored",
            "ignore.at": Date.now(),
            "ignore.by": user?.email ?? "(unknown)",
            ...(justification ? { "ignore.justification": justification } : {}),
          })
      );
      await Promise.all(promises);
    },
    [user]
  );

  const unignoreFindings = useCallback<IgnoreCallbacks["unignoreFindings"]>(
    async (findings) => {
      const promises = findings.map(async (f) => {
        const { history } = f.data;
        await updateDoc(f.ref, {
          state: history.find((h) => h.jobId === last.doc?.data.jobId)
            ? "open"
            : "resolved",
        });
      });
      await Promise.all(promises);
    },
    [last.doc]
  );

  const updateFindingsNotes = useCallback<
    IgnoreCallbacks["updateFindingsNotes"]
  >(async (findings, event) => {
    const promises = findings.map(
      async (f) =>
        await updateDoc(f.ref, {
          "ignore.justification": event.target.value,
        })
    );
    await Promise.all(promises);
  }, []);

  const getAssignmentRequest = useMemo(
    () => (findings: FirestoreDoc<Finding>[]) => {
      const job = last.doc?.data;
      if (!job) {
        console.warn("No assessment job loaded");
        return;
      }
      if (findings.length === 0) return;
      const { monitorId } = findings[0].data;

      return {
        job,
        request: { findingIds: findings.map((f) => f.id), monitorId },
      };
    },
    [last]
  );

  const manageFindings = useMemo(
    () =>
      authFetch
        ? async (
            findings: FirestoreDoc<Finding>[],
            secret: string,
            owner: string
          ) => {
            const job = last.doc?.data;

            if (findings.length === 0 || !job) return;
            const request = {
              findingId: findings[0].id,
              secret,
              owner,
            };
            return await authFetch(
              `assessment/${job.assessmentId}/management/scopes/${findings[0].data.scopeKey}/key-secrets/from-finding`,
              {
                method: "POST",
                json: request,
              }
            );
          }
        : undefined,
    [authFetch, last]
  );

  const assignFindings = useMemo(
    () =>
      authFetch
        ? async (findings: FirestoreDoc<Finding>[]) => {
            const request = getAssignmentRequest(findings);
            if (!request) return;
            return await authFetch(
              `assessment/${request.job.assessmentId}/assignment/start`,
              {
                method: "POST",
                json: request?.request,
              }
            );
          }
        : undefined,
    [getAssignmentRequest, authFetch]
  );

  const unassignFindings = useMemo(
    () =>
      authFetch
        ? async (findings: FirestoreDoc<Finding>[]) => {
            const request = getAssignmentRequest(findings);
            if (!request) return;
            return await authFetch(
              `assessment/${request.job.assessmentId}/assignment/remove`,
              {
                method: "POST",
                json: request?.request,
              }
            );
          }
        : undefined,
    [getAssignmentRequest, authFetch]
  );

  return {
    manageFindings,
    assignFindings,
    ignoreFindings,
    unassignFindings,
    unignoreFindings,
    updateFindingsNotes,
  };
}
