import { Button, Card, Checkbox, Col, Form, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { usePostHog } from "posthog-js/react";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { AppPaths } from "shared/routes/constants";

import { useAuthFetch, useUser } from "../Login/hook";
import { Splash } from "../Splash";

export const cloudStackOptions = [
  { value: "aws", label: "Amazon Web Services" },
  { value: "gcp", label: "Google Cloud Platform" },
  { value: "snowflake", label: "Snowflake" },
];

type FormValues = {
  cloudStack: CheckboxValueType[];
};

export const Welcome: React.FC = () => {
  const authFetch = useAuthFetch();
  const navigate = useNavigate();
  const { orgSlug } = useParams();
  const { tenant, user } = useUser();
  const posthog = usePostHog();

  const [formValues, setFormValues] = useState<FormValues>({
    cloudStack: [],
  });
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

  const handleChange = useCallback(
    (values: CheckboxValueType[], type: "cloudStack") => {
      const newFormValues = { ...formValues };
      newFormValues[type] = values;

      setFormValues(newFormValues);
    },
    [formValues]
  );

  const handleSubmit = useCallback(
    async (data: any) => {
      setFormSubmitting(true);
      await authFetch(`account`, {
        method: "put",
        headers: { "Content-Type": "application/json" },
        json: {
          cloudStack: { ...data },
          onboarding: { onboardedToAudit: true, onboardedToWorkflows: true },
        },
      });
      posthog?.capture("onboarding_cloud_stack_selected", {
        email: user?.email,
        org: orgSlug,
        cloudStack: { ...data },
      });
      setFormSubmitting(false);
      navigate(`/o/${orgSlug}/welcome/onboarding`);
    },
    [authFetch, navigate, orgSlug, posthog, user?.email]
  );

  const switchCloudStack = useCallback(
    (values: CheckboxValueType[]) => handleChange(values, "cloudStack"),
    [handleChange]
  );

  return (
    <Splash overrideColumn>
      <Col xs={22} md={16} lg={14} xl={12}>
        <Card bordered={false}>
          <Typography.Paragraph type="secondary">
            {tenant.state === "found" && tenant.orgName && `${tenant.orgName}`}
          </Typography.Paragraph>
          <Typography.Title level={4}>
            Welcome to P0
            {user && `, ${user.displayName}`}!
          </Typography.Title>
          <Typography.Paragraph>
            To get started, ensure that you have admin privileges for Slack and
            at least one IAM resource (AWS, Google Cloud, or Snowflake). If you
            don&apos;t have these privileges, make sure you have someone who
            does available to assist you.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Let&apos;s start your onboarding! Select your cloud stack:
          </Typography.Paragraph>
          <Form
            onFinish={handleSubmit}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            style={{ marginBottom: "20px" }}
          >
            <Form.Item
              label="Cloud Stack"
              name="cloudStack"
              rules={[{ required: true }]}
            >
              <Checkbox.Group
                options={cloudStackOptions}
                onChange={switchCloudStack}
              />
            </Form.Item>
            <br />
            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
              <Button htmlType="submit" loading={formSubmitting} type="primary">
                Submit
              </Button>
            </Form.Item>
          </Form>
          <Typography.Paragraph>
            <strong>NOTE:</strong> If you don’t have a cloud resource you can
            preview P0 in the&nbsp;
            <Link
              to={`${window.origin}/o/p0-sandbox/${AppPaths.Dashboard}?skipLogin=true`}
              target="_blank"
              rel="noreferrer"
            >
              Sandbox
            </Link>
            &nbsp;prior to adding your resource. For more details, please refer
            to our{" "}
            <Link to="https://docs.p0.dev/" target="_blank" rel="noreferrer">
              documentation
            </Link>
            .
          </Typography.Paragraph>
        </Card>
      </Col>
    </Splash>
  );
};
