import { Alert, Spin } from "antd";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useState } from "react";
import { Navigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";

/**
 * This component supports the OAuth-like "setup URL" redirect that GitHub supports for GitHub apps after one has been
 * installed. Similar to OAuth, we have to provide a single static URL that we then have to work out which tenant-based
 * URL to send the user to for completing the installation process. But, the way it works is different enough from the
 * standard OAuth 2 workflow that it makes sense for this to be a separate component (and route).
 */
export const GithubSetupRedirect: React.FC<object> = () => {
  const [error, setError] = useState<any>(undefined);
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined);

  const [params] = useSearchParams();
  const stateUuid = params.get("state");

  // The value persisted in session storage is simply the URL to redirect to after the installation process is complete.
  // We are never given errors, as the redirect back to our app from GitHub implies that the installation was
  // successful. The only error we have to worry about is if there is no `state` parameter in the URL.

  useGuardedEffect(
    (cancellation) => async () => {
      if (!stateUuid) {
        throw new Error("Malformed input URL: Missing state parameter");
      }

      const sessionStorageData = sessionStorage.getItem(stateUuid);

      if (!sessionStorageData) {
        throw new Error("Install state missing from session storage");
      }

      cancellation.guard(setRedirectUrl)(
        `${sessionStorageData}?${params.toString()}`
      );
    },
    [stateUuid, params],
    setError
  );

  return !!redirectUrl ? (
    <Navigate to={redirectUrl} />
  ) : !!error ? (
    <Alert
      message={
        <>
          {error?.message ?? error}. Please{" "}
          <Link to="/">return to the dashboard</Link> to try again. Note that
          you must initiate the installation process from the P0 Security
          dashboard. This app cannot be installed directly from the GitHub app
          marketplace.
        </>
      }
      type="error"
      showIcon
    />
  ) : (
    <Spin />
  );
};
