import { Typography } from "antd";
import { EllipsisConfig } from "antd/lib/typography/Base";
import styled, { CSSProperties } from "styled-components";
import { colors } from "styles/variables";

const SECTION_LEVEL = 3;

const StyledGrayTitle = styled(Typography.Title)<{
  ellipsis: EllipsisConfig;
}>`
  &.ant-typography {
    color: ${colors.neutral["50"]};
  }
`;

export const GrayTitle: React.FC<{
  title: JSX.Element | string;
  style?: CSSProperties;
}> = ({ title, style }) => {
  return (
    <StyledGrayTitle
      ellipsis={{ rows: 1 }}
      level={SECTION_LEVEL}
      style={{ ...(style || {}) }}
    >
      {title}
    </StyledGrayTitle>
  );
};
