import { Spin } from "antd";
import { ErrorDisplay } from "components/Error";
import { useAuthFetch } from "components/Login/hook";
import { UnknownFirestoreDoc } from "providers/FirestoreProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { AnyNode } from "shared/types/assessment/data";
import { Finding } from "shared/types/assessment/finding";

import { ResultDetail } from "../components/ResultDetail";
import { FindingStateEditor } from "../components/finding/FindingStateEditor";
import { FindingsContext } from "../contexts/FindingsContext";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedEnvironmentContext } from "../contexts/SelectedEnvironmentContext";

export const FindingDetail: React.FC<{
  finding: UnknownFirestoreDoc<Finding>;
  node?: AnyNode;
}> = ({ finding, node }) => {
  const [error, setError] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);

  const authFetch = useAuthFetch(setError, setIsFetching);
  const { allMonitors } = useContext(FindingsContext);
  const { last } = useContext(SelectedEnvironmentContext);
  const { graph, step, scopeKey, setScopeKey } = useContext(ScopeContext);

  useEffect(() => {
    if (finding.doc && scopeKey && scopeKey !== finding.doc?.data.scopeKey) {
      setScopeKey(finding.doc.data.scopeKey, "replaceIn");
    }
  }, [finding.doc, scopeKey, setScopeKey]);

  const monitor = useMemo(
    () =>
      finding.doc?.data.monitorId
        ? allMonitors[finding.doc.data.monitorId]
        : undefined,
    [finding.doc, allMonitors]
  );

  const terms = useMemo(
    () => monitor?.search.map((s) => s.term) ?? [],
    [monitor]
  );

  return !finding.loading &&
    !last.loading &&
    step === "done" &&
    finding.doc &&
    graph &&
    monitor ? (
    <ResultDetail
      header={
        <div>
          {error && (
            <ErrorDisplay title="Could not update finding" error={error} />
          )}
          {isFetching ? (
            <Spin />
          ) : (
            <FindingStateEditor
              node={node}
              finding={finding.doc}
              authFetch={authFetch}
            />
          )}
        </div>
      }
      data-testid="finding-detail"
      mode={"finding"}
      node={finding.doc.data.node}
      title={
        <>
          Finding {finding.doc.data.humanId} &ndash; {monitor?.label}
        </>
      }
      terms={terms}
    />
  ) : null;
};
