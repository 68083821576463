import { Menu } from "antd";
import { JIT_ROUTING_ROLES } from "components/App/roles";
import { UserAuthz } from "components/Login";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext, useMemo } from "react";
import { Outlet, useLocation } from "react-router";
import { NavLink } from "react-router-dom";

import { JitPaths } from "./paths";

const item = (path: string, label: string) => ({
  key: path,
  label: <NavLink to={path}>{label}</NavLink>,
});

export const Jit: React.FC<object> = () => {
  const userAuthz = useContext(UserAuthz);

  const { pathname } = useLocation();
  const flags = useFlags();

  const selected = useMemo(
    () => pathname.split("/").at(-1) ?? "requests",
    [pathname]
  );

  const navItems = [item(JitPaths.Activity, "Activity")];

  if (flags.showEvidenceStore) {
    navItems.push(item(JitPaths.PriorApprovals, "Prior Approvals"));
  }

  navItems.push(item(JitPaths.History, "History"));

  if (JIT_ROUTING_ROLES.find((r) => userAuthz.has(r))) {
    navItems.push(item(JitPaths.Routing, "Routing"));
  }

  return (
    <>
      <Menu
        mode="horizontal"
        items={navItems}
        selectedKeys={[selected]}
        style={{ marginBottom: "1em" }}
      />
      <Outlet />
    </>
  );
};
