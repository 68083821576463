import { ApiFilled } from "@ant-design/icons";
import { Alert, Button, Spin, message } from "antd";
import { Tenant } from "components/Login";
import { useAuthFetch, useHasRole } from "components/Login/hook";
import * as firestoreProvider from "providers/FirestoreProvider";
import { KnownFirestoreDoc } from "providers/FirestoreProvider";
import React, { useCallback, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { installedIds } from "shared/install/installed";
import { OktaIntegration } from "shared/integrations/directories/okta/types";
import {
  GithubIntegration,
  GithubIntegrationGlobalConfig,
} from "shared/integrations/resources/github/types";

import { ErrorDisplay } from "../../Error";
import { TenantAwareLink } from "../../common/TenantAwareLink";
import { IntegrationCard } from "../IntegrationCard";
import { Logo } from "../Logo";
import { OwnerAlert } from "../OwnerAlert";
import { GithubConfigurer } from "./GithubConfigurer";
import { GithubInstallStatus } from "./GithubInstallStatus";
import { GithubInstaller } from "./GithubInstaller";

// See usage restrictions at https://github.com/logos
export const GithubIconUrl =
  "https://github.githubassets.com/assets/GitHub-Mark-ea2971cee799.png";

export const GithubIcon = (
  <Logo logo={GithubIconUrl} title="Github" size={20} />
);

/**
 * This component implements support for installing and configuring the GitHub Organization integration. Note that the
 * installation process is not OAuth2, but GitHub's own custom app installation flow. For more information, see
 * https://www.notion.so/1635dc46d351804dacc7c234ffcb3ab9
 */
export const Github: React.FC<object> = () => {
  const [params] = useSearchParams();
  const [error, setError] = useState<any>();
  const authFetch = useAuthFetch(setError);
  const tenantId = useContext(Tenant);

  const githubIntegrationGlobalConfig =
    firestoreProvider.useFirestoreDoc<GithubIntegrationGlobalConfig>(
      "/integrations/github"
    );

  const githubIntegrationData =
    firestoreProvider.useFirestoreDoc<GithubIntegration>(
      `o/${tenantId}/integrations/github`,
      { live: true }
    );

  const oktaIntegrationData =
    firestoreProvider.useFirestoreDoc<OktaIntegration>(
      `o/${tenantId}/integrations/okta`,
      { live: true }
    );

  const canInstall = useHasRole("owner");

  const doUninstall = useCallback(async () => {
    await authFetch("integrations/github", { method: "DELETE" });
    message.success("Uninstalled GitHub Organization integration");
  }, [authFetch]);

  return (
    <>
      <IntegrationCard
        integration="GitHub Organization"
        logo={GithubIcon}
        onRemove={doUninstall}
        canRemove={canInstall && !!githubIntegrationData?.doc?.data}
      >
        {!!error && <ErrorDisplay error={error} />}
        {githubIntegrationGlobalConfig.loading ||
        githubIntegrationData.loading ||
        oktaIntegrationData.loading ? (
          <Spin />
        ) : !oktaDirectoryInstalled(oktaIntegrationData) ? (
          <Alert
            type="warning"
            showIcon
            message={
              <>
                The Okta Directory Listing integration is currently required to
                install the GitHub Organization integration. Please{" "}
                <TenantAwareLink to="integrations/okta/listing">
                  install it
                </TenantAwareLink>{" "}
                first.
              </>
            }
          />
        ) : !githubIntegrationGlobalConfig?.doc?.data ? (
          <Alert
            message="GitHub Organization integration not available. Please contact P0 support."
            type="error"
            showIcon
          />
        ) : params.get("setup_action") === "install" ? (
          // This needs to be above all the non-error cases to ensure that the GithubInstaller component can clear
          // URL parameters
          <GithubInstaller onError={setError} />
        ) : !!githubIntegrationData?.doc?.data ? (
          <>
            <GithubInstallStatus integration={githubIntegrationData} />
            <GithubConfigurer
              integration={githubIntegrationData}
              authFetch={authFetch}
            />
          </>
        ) : (
          <InstallButton
            installUrl={githubIntegrationGlobalConfig.doc.data.installUrl}
            tenantId={tenantId}
            canInstall={canInstall}
          />
        )}
      </IntegrationCard>
    </>
  );
};

const oktaDirectoryInstalled = (
  integration: KnownFirestoreDoc<OktaIntegration> | undefined
): boolean => {
  if (!integration?.doc?.data) return false;

  return installedIds("listing", integration.doc.data).length > 0;
};

const InstallButton: React.FC<{
  installUrl: string;
  tenantId: string;
  canInstall: boolean;
}> = ({ installUrl, canInstall }) => {
  const redirectWithState = useCallback(() => {
    const uuid = crypto.randomUUID();

    sessionStorage.setItem(uuid, window.location.pathname);

    // useNavigate() doesn't work here as this is intended to redirect the user to an external URL
    window.location.href = installUrl + `?state=${uuid}`;
  }, [installUrl]);

  return canInstall ? (
    <Button icon={<ApiFilled />} onClick={redirectWithState} type="primary">
      Install integration
    </Button>
  ) : (
    <OwnerAlert />
  );
};
