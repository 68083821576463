import { Navigate, Outlet, Route } from "react-router-dom";
import { AppPaths } from "shared/routes/constants";

import { Page } from "../App/Page";
import { RunDetails } from "./RunDetails";
import { SnowflakeRestState } from "./SnowflakeRestState";

export const snowflakeRestStateRoutes = () => {
  return (
    <Route
      path={AppPaths.RestState}
      element={
        <Page title="Rest-state Management">
          <Outlet />
        </Page>
      }
    >
      <Route path={"snowflake"}>
        <Route index element={<Navigate to={"config"} replace />} />
        <Route path=":source" element={<SnowflakeRestState />} />
      </Route>
      <Route
        path={"run/:runId"}
        element={
          <Page title="Rest State Run">
            <RunDetails />
          </Page>
        }
      />
    </Route>
  );
};
