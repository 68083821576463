import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Typography } from "antd";
import { useUser } from "components/Login/hook";
import { RightAlignedDiv } from "components/divs";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useIdleTimer } from "react-idle-timer";

const SESSION_START_ITEM_KEY = "sessionStart";

const IDLE_MODAL_TIMEOUT_MS = 5 * 60 * 1000; // 5 minutes
const MAX_INACTIVITY_TIME_MS = 30 * 60 * 1000; // 30 minutes
const MAX_SESSION_DURATION_MS = 8 * 60 * 60 * 1000; // 8 hours
const MODAL_TRIGGER_TIME_MS = MAX_INACTIVITY_TIME_MS - IDLE_MODAL_TIMEOUT_MS;

const SessionManager: React.FC<PropsWithChildren<object>> = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { signOut } = useUser();

  useEffect(() => {
    if (!sessionStorage.getItem(SESSION_START_ITEM_KEY)) {
      sessionStorage.setItem(SESSION_START_ITEM_KEY, Date.now().toString());
    }
  }, []);
  useEffect(() => {
    if (isModalVisible) {
      const logoutTimer = setTimeout(signOut, IDLE_MODAL_TIMEOUT_MS);
      return () => clearTimeout(logoutTimer);
    }
  }, [isModalVisible, signOut]);

  const handleOnIdle = () => setIsModalVisible(true);
  const handleActivity = () => {
    const sessionStartTime = Number(
      sessionStorage.getItem(SESSION_START_ITEM_KEY)
    );
    const elapsedTime = Date.now() - sessionStartTime;

    if (elapsedTime > MAX_SESSION_DURATION_MS) {
      signOut();
    }
  };
  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  useIdleTimer({
    timeout: MODAL_TRIGGER_TIME_MS,
    onIdle: handleOnIdle,
    onAction: handleActivity,
    debounce: 500,
  });

  return (
    <>
      {children}
      <Modal
        title="Session Expiry Warning"
        open={isModalVisible}
        onCancel={closeModal}
        footer={false}
      >
        <Typography
          style={{
            display: "flex",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
          <ExclamationCircleOutlined
            style={{ fontSize: 24, color: "orange", marginRight: 10 }}
          />
          Your session is about to expire.
        </Typography>
        <RightAlignedDiv>
          <Space>
            <Button key="extend" type="primary" onClick={closeModal}>
              Stay Logged In
            </Button>
            <Button key="logout" onClick={signOut}>
              Log Out
            </Button>
          </Space>
        </RightAlignedDiv>
      </Modal>
    </>
  );
};

export default SessionManager;
