import { Button } from "antd";
import * as React from "react";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { AppPaths } from "shared/routes/constants";

import { useOrgInfoStore } from "../../store/org-info/useOrgInfoStore";
import { useAuthFetch } from "../Login/hook";

const SkipOnboardingButton: React.FC = () => {
  const authFetch = useAuthFetch();
  const navigate = useNavigate();
  const { orgSlug } = useParams();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { resetOrgInfo } = useOrgInfoStore();

  const handleSkipOnboarding = useCallback(async () => {
    setFormSubmitting(true);
    await authFetch(`account/onboarding/skip`, {
      method: "post",
    });
    resetOrgInfo();
    navigate(`/o/${orgSlug}/${AppPaths.Dashboard}`);
  }, [authFetch, navigate, orgSlug, resetOrgInfo]);

  return (
    <Button
      onClick={handleSkipOnboarding}
      type="link"
      loading={formSubmitting}
      style={{ padding: 0 }}
    >
      Skip the guided onboarding
    </Button>
  );
};

export default SkipOnboardingButton;
