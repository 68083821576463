import { PlusCircleOutlined } from "@ant-design/icons";
import { Popover, Select, SelectProps } from "antd";
import { LinkWithEnvironment } from "components/Assessment/components/LinkWithEnvironment";
import { SettingsPaths } from "components/Assessment/components/Settings/paths";
import { targetLogo } from "components/Assessment/components/TargetLogo";
import { TargetsList } from "components/Assessment/components/Targets";
import { SelectedEnvironmentContext } from "components/Assessment/contexts/SelectedEnvironmentContext";
import { useInstalledIntegrations } from "components/Assessment/hooks/useInstalledIntegrations";
import { useSelectedEnvironment } from "components/Assessment/hooks/useSelectedEnvironment";
import {
  CREATE_ENV_ROLES,
  CreateEnvironment,
} from "components/Assessment/pages/CreateEnvironment";
import { UserAuthz } from "components/Login";
import { formatDistanceToNow } from "date-fns";
import { Timestamp } from "firebase/firestore";
import { useLocalStorage } from "hooks/useLocalStorage";
import { groupBy } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { AppPaths } from "shared/routes/constants";
import {
  AssessmentScope,
  AssessmentScopeIntegration,
} from "shared/types/assessment";
import { TerminalAssessmentStatuses } from "shared/types/assessment/job";
import { isa } from "shared/types/is";
import styled from "styled-components";

import {
  ENVIRONMENT_LOCAL_STORAGE_KEY,
  EnvironmentContext,
  StoredEnvironment,
} from "../contexts/EnvironmentContext";

const EnvironmentSelect = styled(Select<string>)`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-top: 0;
    padding-botom: 0;
  }
`;

const EnvironmentLabel: React.FC<{
  label: string;
  status: JSX.Element;
}> = ({ label, status }) => (
  // Padding right is to add space between this and the status div
  <div style={{ width: "100%" }}>
    <div
      style={{
        maxWidth: "90%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        float: "left",
        paddingRight: "6px",
      }}
    >
      {label}
    </div>
    <div
      style={{
        width: "10%",
        float: "left",
      }}
    >
      {status}
    </div>
  </div>
);
export const EnvironmentSelector: React.FC<SelectProps<string>> = (props) => {
  const { all, selected, hasEnvironments } = useContext(EnvironmentContext);
  const { assessment, current } = useContext(SelectedEnvironmentContext);

  const [__, setSelectedEnvironment] = useSelectedEnvironment();

  const getTargetLogo = useCallback(
    (integration: AssessmentScopeIntegration) => targetLogo(integration),
    []
  );

  const targets = useMemo(
    () => assessment?.doc?.data.targets ?? [],
    [assessment?.doc?.data.targets]
  );
  const grouped = useMemo(
    () => Object.entries(groupBy(targets, "integration")),
    [targets]
  ) as [AssessmentScopeIntegration, AssessmentScope[]][];

  const options = useMemo(
    () =>
      Array.from(all).map(([_, s]) => ({
        value: s.id,
        labelNoPopover: <EnvironmentLabel label={s.label} status={s.status} />,
        label: (
          <Popover
            placement="rightTop"
            trigger="hover"
            showArrow={false}
            content={s.detailedStatus}
            autoAdjustOverflow={true}
            overlayStyle={{ width: "25em", paddingLeft: "8px" }}
            arrowPointAtCenter={true}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: s.theme,
              }}
            >
              <EnvironmentLabel label={s.label} status={s.status} />
            </div>
          </Popover>
        ),
      })),
    [all]
  );

  const [_, setStoredEnv] = useLocalStorage<StoredEnvironment>(
    ENVIRONMENT_LOCAL_STORAGE_KEY
  );
  const handleChange = useCallback(
    (value: string) => {
      if (all.has(value)) {
        setStoredEnv({ id: value });
        setSelectedEnvironment(value);
      }
    },
    [all, setSelectedEnvironment, setStoredEnv]
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const closeDropdown = useCallback(
    () => setDropdownOpen(false),
    [setDropdownOpen]
  );

  const changeDropdownOpen = useCallback(
    (state: boolean) => setDropdownOpen(state),
    [setDropdownOpen]
  );
  const { installed } = useInstalledIntegrations();
  const authz = useContext(UserAuthz);

  const dropdownRender = useCallback(
    (menu: React.ReactElement) => (
      <>
        {menu}
        {CREATE_ENV_ROLES.find((r) => authz.has(r)) && (
          <div style={{ float: "right" }}>
            <CreateEnvironment
              onClick={closeDropdown}
              setModalOpen={setCreateModalOpen}
              modalOpen={createModalOpen}
              installed={installed}
              buttonProps={{
                icon: <PlusCircleOutlined />,
                type: "default",
              }}
            />
          </div>
        )}
      </>
    ),
    [createModalOpen, setCreateModalOpen, installed, closeDropdown, authz]
  );

  return hasEnvironments ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        marginBottom: "20px",
      }}
    >
      <EnvironmentSelect
        {...props}
        onChange={handleChange}
        options={options}
        value={selected?.id ?? options[0]?.value}
        optionLabelProp="labelNoPopover"
        style={{
          display: "inline-block",
          width: "20em",
        }}
        onDropdownVisibleChange={changeDropdownOpen}
        open={dropdownOpen}
        dropdownStyle={{ padding: "0 0 0 0", width: "100%" }}
        dropdownRender={dropdownRender}
      />
      <LinkWithEnvironment
        to={`${AppPaths.Settings}/${SettingsPaths.EnvironmentConfiguration}`}
        style={{ minWidth: "8em" }}
        withTenant={true}
      >
        {assessment.doc?.data.lastAssessmentDate
          ? current.doc
            ? !isa(TerminalAssessmentStatuses, current.doc.data.status)
              ? "Running"
              : `Last run ${formatDistanceToNow(
                  (assessment.doc.data.lastAssessmentDate as Timestamp).toDate()
                )} ago`
            : ""
          : "Hasn't been run yet"}
      </LinkWithEnvironment>
      <Popover
        content={
          <div style={{ minWidth: "15em" }}>
            <TargetsList targets={targets} />{" "}
          </div>
        }
      >
        <div style={{ display: "flex" }}>
          {grouped.map((integration, ix) => (
            <div key={`${integration[0]}${ix}`}>
              {getTargetLogo(integration[0])}&nbsp;
            </div>
          ))}
        </div>
      </Popover>
    </div>
  ) : (
    <></>
  );
};
