import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { useContext } from "react";
import { AppPaths } from "shared/routes/constants";
import { TargetNodeType } from "shared/types/assessment/data";

import { LinkWithEnvironment } from "../LinkWithEnvironment";

export const NodeLink: React.FC<{
  node: { key: string; type: TargetNodeType };
}> = ({ node }) => {
  const { scopeKey } = useContext(ScopeContext);
  return (
    <LinkWithEnvironment
      to={`${AppPaths.Inventory}/${node.type}/${encodeURIComponent(
        node.key
      )}?show=${node.type}&where=${encodeURIComponent(
        `${node.type}="${node.key}"`
      )}&scope=${encodeURIComponent(scopeKey)}`}
      withTenant={true}
    >
      view
    </LinkWithEnvironment>
  );
};
