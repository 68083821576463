import { message } from "antd";
import { FirebaseError } from "firebase/app";
import {
  AuthCredential,
  User,
  UserCredential,
  getIdToken,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { OrgData } from "shared/types/tenant";
import { staticError } from "utils/console";

import AuthConst from "../../constants/AuthConst";
import { updateHeadersInit, useApiFetch } from "../../hooks/useApiFetch";
import { DB, getAuth } from "../../providers/FirestoreProvider";
import { Cancellation } from "../../utils/cancel";
import { idPlatform } from "./IdentityPlatform";
import { Tenant, UserAuthz, UserRole, slugToTenant } from "./util";

export type Tenant =
  | { state: "loading" }
  | { state: "not_found" }
  | (OrgData & {
      state: "found";
    });

export type OrgFromLocalStorage = {
  lastAccess: Date;
};
export type OrgFromLocalStorageMap = Record<string, OrgFromLocalStorage>;
export const ORG_SLUGS_LOCALSTORAGE_KEY = "p0-previous-tenants";

// Oof, this hack is dirty. We're getting hosed by React's duplicate
// render in development, so need to reliably store the last auth
// credential
let GLOBAL_AUTH_CREDENTIAL: AuthCredential | UserCredential | undefined =
  undefined;

export const useUser = (anonymous?: boolean) => {
  /** The current orgSlug, from the path (viz., /o/:orgSlug) */
  const { orgSlug } = useParams();
  /** The user object as returned by the IdP */
  const [user, setUser] = useState<User | undefined>();
  /** The user credential from last successful auth */
  const [authCredential, setAuthCredential] = useState<AuthCredential>();
  const [userCredential, setUserCredential] = useState<UserCredential>();
  /** The org's Firebase tenant ID
   *
   * If this page is loading, or if the org doesn't have a tenant ID, this will
   * be undefined.
   */
  const [tenant, setTenant] = useState<Tenant>({ state: "loading" });

  /**
   * The orgData object from the Firestore database
   */
  const [orgData, setOrgData] = useState<OrgData | undefined>();
  /** True iff system is currently negotiating auth with the IdP */
  const [isInAuth, setIsInAuth] = useState(true);

  const tenantId = useMemo(() => {
    return "tenantId" in tenant ? tenant.tenantId : undefined;
  }, [tenant]);

  const navigate = useNavigate();

  /** When executed, signs the user out */
  const signOut = useCallback(async () => {
    // Avoid premature signouts when page is loading
    if (user === undefined || isInAuth || tenant.state === "loading") {
      return;
    }
    try {
      await idPlatform.signOut();
      setUser(undefined);
      navigate("/");
    } catch (err) {
      // TODO: Clean up at TS4
      if (err instanceof FirebaseError) {
        alert(`Can't sign out:\n${err.code}\n${err.message}`);
      } else {
        throw err;
      }
    }
  }, [user, isInAuth, tenant.state, navigate]);

  const finishSignInWithEmail = useCallback(async (tenantId: string) => {
    const email = window?.localStorage.getItem(
      AuthConst.USER_EMAIL_LOCALSTORAGE_KEY
    );

    if (email !== null) {
      setIsInAuth(true);
      try {
        const credential = await idPlatform.finishEmailSignIn(email, tenantId);
        setUserCredential(credential);
        window?.localStorage.removeItem(AuthConst.USER_EMAIL_LOCALSTORAGE_KEY);
      } catch (_err: any) {
        throw AuthConst.ERRORS.EMAIL_SIGN_IN_ERROR;
      }
    } else {
      throw AuthConst.ERRORS.MISSING_EMAIL;
    }
  }, []);

  // This logic extracts this org's corresponding tenant ID from Google Identity Platform.
  // Note that this operation is asynchronous, so there will be a period of time where
  // the tenant ID is undefined.
  // If the org doesn't exist in the backend, the tenant ID will also be undefined.
  useEffect(() => {
    // We need to be able to cancel this asynchronous operation if the component
    // is unmounted prior to completion; so start this cancellation tracker
    let isCanceled = false;
    if (!orgSlug) return;
    slugToTenant(orgSlug)
      .then((tenantAuth) => {
        if (!isCanceled) {
          if (tenantAuth) {
            setOrgData(tenantAuth);
            setTenant({
              state: "found",
              ...tenantAuth,
            });
          } else {
            setTenant({ state: "not_found" });
          }
        }
      })
      .catch((err) => {
        if (!isCanceled) {
          alert(err);
          setTenant({ state: "not_found" });
        }
      });
    // This is the cancellation hook
    return () => {
      isCanceled = true;
    };
  }, [orgSlug]);

  /** Attempts to sign in a user with the tenant's configured SSO provider.
   *
   * Sets the "inAuth" state to true.
   */
  const signInWithProvider = useCallback(async () => {
    if (orgSlug && tenant.state === "found") {
      try {
        setIsInAuth(true);
        const credential = await idPlatform.signIn(tenant, {
          anonymous,
          orgSlug,
        });
        if (credential && "user" in credential) {
          setUserCredential(credential);
        } else {
          setAuthCredential(credential || undefined);
        }
        // YUCK: React renders twice in develop, which means that
        // the authCredential doesn't stick around after login. This
        // dirty hack saves the credential in a global so we can
        // recover it later.
        GLOBAL_AUTH_CREDENTIAL = credential || undefined;
      } catch (err) {
        setIsInAuth(false);
        // TODO: Clean up at TS4
        if (err instanceof FirebaseError) {
          alert(`Invalid login:\n${err.code}:\n${err.message}`);
        } else {
          throw err;
        }
      }
    }
  }, [anonymous, orgSlug, tenant]);

  /** Attempts to send a sign in link to a user's email.
   *
   * Sets the "inAuth" state to true.
   */
  const signInWithEmail = useCallback(
    async (email: string) => {
      if (orgSlug && tenant.state === "found") {
        try {
          setIsInAuth(true);
          // Basically we send the email link (in the idPlatform class),
          // then we set the user's email in localstorage so we can
          // retrieve it on the callback page that the user gets emaied.
          await idPlatform.signIn(tenant, { email, orgSlug });
          window.localStorage.setItem(
            AuthConst.USER_EMAIL_LOCALSTORAGE_KEY,
            email
          );
          setIsInAuth(false);
          return true;
        } catch (err) {
          setIsInAuth(false);
          // TODO: Clean up at TS4
          if (err instanceof FirebaseError) {
            alert(`Invalid login:\n${err.code}:\n${err.message}`);
          } else {
            throw err;
          }
          return false;
        }
      }
    },
    [orgSlug, tenant]
  );

  /**
   * This is triggered when login finishes; a user object indicates proper sign-in;
   * sets the auth state to "false".
   */
  useEffect(
    () =>
      idPlatform.onAuthStateChanged((u) => {
        if (u && u.tenantId) {
          setUser(u);
        } else {
          setUser(undefined);
        }
        setIsInAuth(false);
      }),
    []
  );

  // Sign out the user when the tenant is not found (viz., navigation to unknown tenant)
  useEffect(() => {
    if (tenant.state === "not_found") {
      signOut();
    }
  }, [signOut, tenant.state]);

  /**
   * Navigate the user to the correct tenant if they are signed in.
   * @TODO ENG-56: Switch to redirecting user to their tenant org.
   */
  useEffect(() => {
    if (user && user.tenantId !== tenantId && user.tenantId) {
      signOut();
    }
  }, [user, user?.tenantId, tenantId, signOut]);

  useEffect(() => {
    if (orgSlug && tenant.state === "found" && user !== undefined) {
      let parsed: OrgFromLocalStorageMap = {};
      const storedOrgs = localStorage.getItem(ORG_SLUGS_LOCALSTORAGE_KEY);
      if (storedOrgs !== null) {
        parsed = JSON.parse(storedOrgs);
      }
      parsed[orgSlug] = {
        lastAccess: new Date(),
      };
      localStorage.setItem(ORG_SLUGS_LOCALSTORAGE_KEY, JSON.stringify(parsed));
    }
  }, [orgSlug, tenant.state, user]);

  return {
    authCredential: authCredential ?? GLOBAL_AUTH_CREDENTIAL,
    isInAuth: isInAuth || tenant.state === "loading",
    finishSignInWithEmail,
    orgSlug,
    signInWithEmail,
    signInWithProvider,
    signOut,
    tenant,
    orgData,
    // Avoid stale UI render by guarding the user object with tenant state
    user: tenant.state === "found" ? user : undefined,
    userCredential,
    useSso: tenant.state === "found" && tenant.ssoProvider !== undefined,
  };
};

/** Retrieves the logged-in users P0 permissions as a set of roles
 *
 * An empty set indicates that permissions are still loading.
 */
export const useUserAuthz = (user?: User, tenant?: Tenant) => {
  const [userAuthz, setUserAuthz] = useState(new Set<UserRole>());
  const { signOut } = useUser();
  const isSandbox = tenant?.state === "found" && tenant.isSandbox;

  useGuardedEffect(
    (cancellation) => async () => {
      const roles = new Set<UserRole>();
      const tenantId = user?.tenantId;

      if (cancellation.isCancelled) return;

      if (tenantId === undefined) return await signOut();

      if (!!user && !user.email && !isSandbox) {
        message.error(
          "Can not sign in: this user has previously signed in with a different identity provider.\nPlease contact support@p0.dev to enable this user."
        );
        return await signOut();
      }

      const hasRole = async (path: string) => {
        try {
          await getDoc(doc(DB, `o/${tenantId}/${path}`));
          return true;
        } catch (err: any) {
          // Want to continue on permission-denied in case user is different role
          if (err.code !== "permission-denied") throw err;
          return false;
        }
      };

      // Any person in the domain is a viewer (for now)
      // Validate that user can access tenant
      if (await hasRole("auth/valid")) roles.add("viewer");
      if (cancellation.isCancelled) return;

      for (const role of [
        "owner",
        "manager",
        "iamOwner",
        "iamViewer",
        "restStateManager",
      ] as const) {
        // Firestore security roles handle the actual authz check;
        // a user is allowed either if
        // - their email is directly in the /bindings sub-collection
        // - their group is in the allowed.groups list in the document
        if (await hasRole(`roles/${role}/test/verify`))
          roles.add(role === "manager" ? "approver" : role);
        if (cancellation.isCancelled) return;
      }

      if (isSandbox) roles.add("iamViewer");

      if (roles.size === 0) {
        message.error("Unauthorized");
        return await signOut();
      }

      if (!cancellation.isCancelled) setUserAuthz(roles);
    },
    [isSandbox, signOut, user],
    staticError
  );
  return userAuthz;
};

/** Returns an authorized fetch context
 *
 * Use this fetch context just like a normal browser fetch context, with the following caveats:
 * - Path is relative to the backend tenant collection
 * - Any passed Authorization header will be ignored
 *
 * Example:
 * ```
 * const [error, setError] = useState<string>();
 * const authFetch = useAuthFetch(setError);
 * ...
 * const response = await authFetch("relative/path", {method: "POST", data});
 * if (response) {
 *    ...
 * }
 * ```
 *
 * @returns Fetch callback. Works like `fetch` except:
 *   - Can directly pass `json` values in RequestInit; this will automatically send the proper
 *     JSON request body and headers
 *   - Can pass `onNotOk` in RequestInit; this will register a handler to be applied when a non-ok
 *     status is returned (otherwise `onError` will be used)
 *   - Can pass a Cancellation object (to Automatically clean up fetches inside useEffect)
 *
 * @param onError Called when an error occurs. Note that 4XX / 5xx statuses are not errors. Will also
 *                be called on these statuses if onNotOk is not defined.
 * @param onFetch Called when a fetch is started or finished. Can be used to show a loading indicator.
 */
export const useAuthFetch = (
  onError?: (error: string | undefined) => void,
  onFetch?: (isFetching: boolean) => void
) => {
  const { orgSlug } = useParams();
  const auth = useMemo(() => getAuth(), []);
  const apiFetch = useApiFetch(onError, onFetch);

  const authFetch = useCallback(
    async (
      path: string,
      init: RequestInit & {
        json?: any;
        onNotOk?: (response: Response) => void;
        cancellation?: Cancellation;
      }
    ) => {
      if (auth.currentUser) {
        onError?.(undefined);
        const token = await getIdToken(auth.currentUser);
        const newHeaders: Record<string, string> = {
          Authorization: `Bearer ${token}`,
        };

        // Headers can be either a Headers object, an object, or an array of string pairs
        const headers = init.headers ?? {};
        updateHeadersInit(headers, newHeaders);

        const initWithAuth = {
          ...init,
          headers,
        };
        return await apiFetch(`o/${orgSlug}/${path}`, initWithAuth);
      } else {
        onError?.("Unauthorized");
      }
    },
    [auth.currentUser, apiFetch, orgSlug, onError]
  );

  return authFetch;
};

/** Returns true if user is one of any of the specified roles */
export const useHasRole = (...oneOf: UserRole[]) => {
  const roles = useContext(UserAuthz);
  const hasRole = useMemo(
    () => !!oneOf.find((r) => roles.has(r)),
    [oneOf, roles]
  );
  return hasRole;
};

export type AuthFetch = ReturnType<typeof useAuthFetch>;
